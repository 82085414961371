import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  // TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  formParent: {
    paddingRight: 10,
  },
}));

function CreatePatientCategory(props) {
  const classes = useStyles();
  // let dispatch = useDispatch();
  // const resourceAppointment = useSelector(
  //   (state) => state.resourceByAppointmentTypesSlice
  // );
  const {
    state = {},
    handlechange,
    parent_id,
    SelectorPatientAuto,
    setFinalValue,
    finalValue,
  } = props;
  return (
    <>
      <Paper id={`${parent_id}-PatientCatory-paper`} className={classes.root}>
        <Typography
          id={`${parent_id}-Appointment Type-title-typography`}
          className={classes.title}
          variant="h6"
        >
          PATIENT CATEGORY
        </Typography>
        <Grid id={`${parent_id}-PatientCategory-grid`} className={classes.form}>
          <Grid
            id={`${parent_id}-PatientCategory-title`}
            className={classes.formParent}
            lg={8}
            md={8}
          >
            <Typography
              id={`${parent_id}-PatientCategory-title-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Patient Category
            </Typography>
            <SelectBox
              parent_id={"PatientCategory-select"}
              // disabled={Boolean(editData)}
              // defaultValue={state?.Patientcategory}
              multi={true}
              list={SelectorPatientAuto}
              // list={[]}
              onchange={(e,v) =>
                setFinalValue({
                  ...finalValue,
                  patientcategory: v,
                })
              }
              value={finalValue?.patientcategory}
              // value={state?.Patientcategory ?? []}
              // onchange={(e, v) => handlechange("Patientcategory", [v], true, v)}
              // error={props.error?.applicable_type}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default CreatePatientCategory;
