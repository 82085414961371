import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Popover, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import { SelectBox } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { useHistory } from "react-router";
import { AlertContext } from "../../../contexts";
import { AlertProps } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
}));
function ResourceDefinitionCard(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const {
    state = {},
    handlechange,
    ResourceTypeAuto,
    ResourceRoleAuto,
    ResourceDepartmentAuto,
    ResourceNameAuto,
    deleteResource,
    savefunction,
    handleEdit,
    handleUpdate,
    setState,
    setFinalValue,
    finalValue,
    TableReadData,
  } = props;
  const classes = useStyles();
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editshow, seteditshow] = useState(false);
  const [valueshow, setvalueshow] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [resourse, setResourse] = useState({
    resourcetype: "",
    resourcerole: "",
    resourcedepartment: [],
    resourcename: [],
  });
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentEditIndex(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setResourse({
      resourcetype: "",
      resourcerole: "",
      resourcedepartment: [],
      resourcename: [],
    });
    // handlechange({
    //   ResourceName: [],
    //   ResourceDepartment: [],
    //   ResourceRole: [],
    //   resourcedefinition: [],
    // });
  };
  const handleChangelist = async (key, value) => {
    setResourse({
      ...resourse,
      [key]: value,
    });
    if (key == "resourcetype") {
      await dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC_QDM(value?._id));
    }

    if (key == "resourcerole") {
      await dispatch(
        actions.RES_RESOURCE_DEPARTMENT_LIST_DOC_QDM({
          resourcetypeId: resourse?.resourcetype?._id,
          resourceroleId: value?._id,
        })
      );
    }

    if (key == "resourcedepartment") {
      if (resourse?.resourcedepartment?.length > 0) {
        const seen = new Set();
        const filteredArr = value.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        setResourse({
          ...resourse,
          [key]: filteredArr,
        })
      }
      await dispatch(
        actions.RES_RESOURCE_NAME_LIST_DOC_QDM({
          resourcetypeId: resourse?.resourcetype?._id,
          resourceroleId: resourse?.resourcerole?._id,
          resourcedepartmentId: value[0]?._id,
        })
      );
    }
    if (key == "resourcename"){
      if (resourse?.resourcename?.length > 0) {
        const seen = new Set();
        const filteredArr = value.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        setResourse({
          ...resourse,
          [key]: filteredArr,
        })
      }
    }
  };

  const handleEditvalue = (v, i) => {
    seteditshow(true);
    setCurrentEditIndex(i);
    setResourse({
      resourcetype: v?.resourcetype,
      resourcerole: v?.resourcerole,
      resourcedepartment: v?.resourcedepartment,
      resourcename: v?.resourcename,
    });
    
    dispatch(
      actions.RES_RESOURCE_NAME_LIST_DOC_QDM({
        resourcetypeId: v?.resourcetype?._id,
        resourceroleId: v?.resourcerole?._id,
        resourcedepartmentId: v?.resourcedepartment?.[0]?._id,
      })
    );
    dispatch(
      actions.RES_RESOURCE_DEPARTMENT_LIST_DOC_QDM({
        resourcetypeId: v?.resourcetype?._id,
        resourceroleId: v?.resourcerole?._id,
      })
    );
    dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC_QDM(v?.resourcetype?._id));
  };

  const deleteResources = (index) => {
    setFinalValue((prevFinalValue) => {
      const updatedResourceDefinition = [...prevFinalValue.resourcedefinition];
      updatedResourceDefinition.splice(index, 1);
      return {
        ...prevFinalValue,
        resourcedefinition: updatedResourceDefinition,
      };
    });

    setvalueshow((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };

  const handlesubmit = () => {
    if (currentEditIndex !== null) {
      if(  resourse?.resourcedepartment?.length > 0 &&
        resourse?.resourcename?.length > 0){
          setFinalValue((prevFinalValue) => {
            const updatedResourceDefinition = [
              ...prevFinalValue.resourcedefinition,
            ];
            updatedResourceDefinition[currentEditIndex] = resourse; // Update at the same index
            return {
              ...prevFinalValue,
              resourcedefinition: updatedResourceDefinition,
            };
          });
          const updatedValues = [...valueshow];
          updatedValues[currentEditIndex] = resourse;
          setvalueshow(updatedValues);
          setResourse({
            resourcetype: "",
            resourcerole: "",
            resourcedepartment: [],
            resourcename: [],
          });
          setCurrentEditIndex(null);
          setAnchorEl(null);
          seteditshow(false);
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      if (
        resourse?.resourcetype?.label &&
        resourse?.resourcerole?.label &&
        resourse?.resourcedepartment?.length > 0 &&
        resourse?.resourcename?.length > 0
      ) {
        const updatedValues = [...valueshow, resourse];
        setvalueshow(updatedValues);
        setError(false)
        setFinalValue((prevFinalValue) => {
          return {
            ...prevFinalValue,
            resourcedefinition: [
              ...prevFinalValue.resourcedefinition,
              resourse,
            ], 
          };
        });
      } else {
        setError(true)
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      setResourse({
        resourcetype: "",
        resourcerole: "",
        resourcedepartment: [],
        resourcename: [],
      });
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    const resourceData = TableReadData[0];
    if (history?.location?.state?.IsEdit && resourceData ) {
      let resourcedefinitionarry = [];
      resourceData?.resourcedefinition?.map((val) => {
        const mappedData = {
          resourcetype: {
            _id: val?.resourcetype?._id || "",
            label: val?.resourcetype?.display,
            Value: val?.resourcetype?.display,
          },
          resourcerole: {
            _id: val?.resourcerole?._id,
            label: val?.resourcerole?.display,
            Value: val?.resourcerole?.display,
          },
          resourcedepartment:
            val.resourcedepartment.map((department) => ({
              _id: department._id,
              label: department.display,
              value: department.display,
            })) || [],
          resourcename:
            val?.resourcename.map((name) => ({
              _id: name._id,
              label: name.display,
              value: name.display,
            })) || [],
        };
        resourcedefinitionarry.push(mappedData);
      });
      setvalueshow(resourcedefinitionarry);
    }
  }, [TableReadData]);
  return (
    <>
      {/* label : "ICT professional"
       title : "PRACTROLE" 
       value :"CodingMaster/10209" */}
      {/* label : "Outpatient Department" title : "DEPARTMENT" value :
      "CodingMaster/10175" */}
      {/* <Snackbar
        open={snackAlert}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // onClose={handleCloseAlert}
      >
        <Alert severity="error">Fill all Mandatory Fields</Alert>
      </Snackbar> */}
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                RESOURCE DEFINITION
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  //   className={classes.newRes}
                  // color="primary"
                  onClick={handleClick}
                  style={{
                    marginTop: "-6px",
                    color: "#0071F2",
                    fontFamily: "poppinsemibold",
                  }}
                  button
                  disableTouchRipple
                  id={"resource_definition_card_Id"}
                >
                  + Add New Resources
                </Button>
                <div style={{ marginBottom: "8px" }}>
                  {/* <img
                  src={expandarrow}
                  alt="icon"
                  style={{
                    transform: show ? "rotate(180deg)" : "rotate(359deg)",
                  }}
                  /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {valueshow?.length > 0 &&
            valueshow?.map((v, i) => {
              return (
                <Grid
                  container
                  style={{
                    border: "1px solid #E0E0E0",
                    padding: 12,
                    // marginBottom: 8,
                    backgroundColor: "#F9F9F9",
                    position: "relative",
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={1}
                        style={{
                          backgroundColor: "#E0E0E0",
                          position: "absolute",
                          left: 0,
                          padding: "6px 12px",
                          borderTopRightRadius: 9,
                          borderBottomRightRadius: 9,
                        }}
                      >
                        {i + 1}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginLeft: "4%",
                          display: "flex",
                          padding: 10,
                        }}
                      >
                        {/* <div>{v.type.label}</div>
                        <div>
                          {v.role ? v.role.label : v.role ? v.role : " "}
                          {""}
                          <div className={classes.dot} />

                          {v.department.map((v) => v.label).join()}

                          {""}
                          <div className={classes.dot} />

                          {v.namelist.map((v) => v.label).join()}

                          {""}
                        </div> */}
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.resourcetype?.label}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.resourcerole?.label}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                          {" "}
                          {v.resourcedepartment &&
                          v.resourcedepartment.length > 0
                            ? v.resourcedepartment
                                .map((dep) => dep.label)
                                .join(", ")
                            : null}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.resourcename && v.resourcename.length > 0
                            ? v.resourcename
                                .map((name) => name.label)
                                .join(", ")
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="EditIcon"
                        className={classes.margin}
                        size="small"
                      >
                        <EditIcon
                          onClick={(e) => {
                            handleClick(e);
                            handleEditvalue(v, i);
                          }}
                          style={{ color: "#0071F2", fontSize: 18 }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="DeleteIcon"
                        className={classes.margin}
                        size="small"
                        onClick={() => deleteResources(i)}
                      >
                        <DeleteIcon
                          style={{ color: "#FF4D4A", fontSize: 18 }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Card>
      </Card>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 18, width: 700 }}>
          <Grid container direction="row">
            <Grid item lg={11} md={11} xl={11}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: 14,
                  marginBottom: 8,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                Add Resource
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <CloseIcon
                style={{
                  marginTop: "-18px",
                  cursor: "pointer",
                  marginLeft: "36px",
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={12}>
            <Grid item lg={6} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Types
                {/* <span className={classes.req_red}>*</span> */}
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                list={ResourceTypeAuto ?? []}
                // list={[]}
                value={resourse?.resourcetype ?? []}
                // onchange={(e, v) =>
                //   handlechange(
                //     "ResourceTypeList",
                //     v,
                //     "PRACTROLE",
                //     "DEVICEROLE",
                //     "LOCATIONROLE"
                //   )
                // }
                error={error &&  !resourse?.resourcetype?.label}
                onchange={(e, v) => {
                  handleChangelist("resourcetype", v);
                }}
              />
            </Grid>
            <Grid item lg={6} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Role
                {/* <span className={classes.req_red}>*</span> */}
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                list={ResourceRoleAuto}
                // list={[]}
                value={resourse?.resourcerole ?? []}
                onchange={(e, v) => {
                  handleChangelist("resourcerole", v);
                }}
                error={error &&  !resourse?.resourcerole?.label}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={12}>
            <Grid item lg={6}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Department
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                multi={true}
                //disabled={Boolean(editData)}
                list={ResourceDepartmentAuto ?? []}
                // list={[]}
                value={resourse?.resourcedepartment ?? []}
                onchange={(e, v) => {
                  handleChangelist("resourcedepartment", v);
                }}
                error={error &&  !resourse?.resourcedepartment?.length > 0}
              />
            </Grid>
            <Grid item lg={6}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Name
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                multi={true}
                //disabled={Boolean(editData)}
                list={ResourceNameAuto ?? []}
                // list={[]}
                value={resourse?.resourcename ?? []}

                onchange={(e, v) => {
                  handleChangelist("resourcename", v);
                }}
                error={error &&  !resourse?.resourcename?.length > 0}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            // color="primary"
            style={{
              marginRight: "34px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
            onClick={() => handlesubmit()}
          >
            {editshow ? "update" : "save"}
          </Button>
        </div>
        {/* <div
          style={{
            width: "850px",
            height: "40px",
          }}
        ></div> */}
      </Popover>
    </>
  );
}

export default ResourceDefinitionCard;
