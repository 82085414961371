import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { EditorState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "./style.css";

const insertCharacter = (characterToInsert, editorState) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    characterToInsert
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters"
  );

  return newEditorState;

  // return EditorState.forceSelection(
  //   newEditorState,
  //   newContent.getSelectionAfter()
  // );
};

export class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    this.setState({
      // editorState: convertFromRaw(this.props.initialValue)
      editorState: EditorState.moveSelectionToEnd(
        EditorState.createWithContent(stateFromHTML(this.props.initialValue))
      ),
    });
  }

  getButtonAction = (text, createFieldMentions = false) => {
    const contentState = this.state.editorState.getCurrentContent();
    const selection = this.state.editorState.getSelection();

    let newContentState = Modifier.insertText(contentState, selection, text);

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-characters"
    );

    this.setState({
      editorState: EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      ),
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const prevEditorState = this.props.initialValue;
    const nextEditorState = nextProps.initialValue;
    if (prevEditorState !== nextEditorState) {
      // If Empty initial value provided then update the editor state with empty content.
      if (!Boolean(nextEditorState)) {
        this.setState({
          editorState: EditorState.createWithContent(
            stateFromHTML(nextEditorState)
          ),
        });
      }
    }

    const prevAppendValue = this.props.appendValue;
    const nextAppendValue = nextProps.appendValue;

    if (prevAppendValue !== nextAppendValue) {
      if (Boolean(nextAppendValue)) {
        const newEditorState = insertCharacter(
          nextAppendValue,
          this.state.editorState
        );
        this.setState(
          {
            editorState: newEditorState,
          },
          () => {
            this.props.onChange(
              this.getHtmlFromEditorState(this.state.editorState)
            );
            this.props.resetAppendValue();
          }
        );
      }
    }

    return true;
  }
  getHtmlFromEditorState = (editorState) => {
  const contentState = editorState.getCurrentContent();

  const html = stateToHTML(contentState, {
    blockStyleFn: (block) => {
      const type = block.getType();
      if (type === 'header-two') {
        return { element: 'h2', attributes: { className: 'custom-h2' } };
      }
      if (type === 'blockquote') {
        return { element: 'blockquote', attributes: { className: 'custom-quote' } };
      }
      return null;
    },
    inlineStyleFn: (styles) => {
      const styleObj = {};
      styles.forEach((style) => {
        // Handle custom color styles like "color-rgb(97,189,109)"
        if (style.startsWith('color-rgb')) {
          const colorMatch = style.match(/color-rgb\((\d+),(\d+),(\d+)\)/);
          if (colorMatch) {
            const [_, r, g, b] = colorMatch;
            styleObj.style = { ...styleObj.style, color: `rgb(${r},${g},${b})` };
          }
        }

        // Handle other standard styles
        if (style === 'BOLD') {
          styleObj.element = 'strong';
        }
        if (style === 'ITALIC') {
          styleObj.element = 'em';
        }
        if (style === 'UNDERLINE') {
          styleObj.element = 'u';
        }
        if (style === 'STRIKETHROUGH') {
          styleObj.style = { ...styleObj.style, textDecoration: 'line-through' };
        }
      });
      return styleObj;
    },
    entityStyleFn: (entity) => {
      const type = entity.getType();
      if (type === 'LINK') {
        const { url } = entity.getData();
        return {
          element: 'a',
          attributes: { href: url, target: '_blank', rel: 'noopener noreferrer' },
        };
      }
      if (type === 'IMAGE') {
        const { src, alt } = entity.getData();
        return {
          element: 'img',
          attributes: { src, alt, style: { maxWidth: '100%' } },
        };
      }
      return null;
    },
  });

  return html;
};

  
  editorStateToHtml = (editorState) => {
    const options = {
      inlineStyleFn: (styles) => {
        const styleObj = {};
        styles.forEach((style) => {
          if (style.startsWith("COLOR_")) {
            styleObj.style = { color: style.replace("COLOR_", "#") };
          }
        });
        return styleObj;
      },
    };

    return stateToHTML(editorState.getCurrentContent(), options);
  };
  onEditorStateChange = (editorState) => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    console.log("Raw Content State:", rawContent);

    this.setState(
      {
        editorState,
      },
      () => {
        const html=this.getHtmlFromEditorState(editorState)
        console.log({html});        
        this.props.onChange(html);
      }
    );
  };

  render() {
    const uploadImageCallback = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ data: { link: e.target.result } });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };
    const { withDirectionTags, name } = this.props;
    const { editorState } = this.state;
    const customToolbarOptions = {
      options: name === "Body" ? [
        "inline", "blockType", "fontSize", "list", "textAlign", "colorPicker",
        "link", "embedded", "emoji", "image", "remove", "history"
      ] : [],
      inline: { options: ["bold", "italic", "underline", "strikethrough", "monospace", "superscript", "subscript"] },
      blockType: { inDropdown: true },
      fontSize: {},
      list: { options: ["unordered", "ordered", "indent", "outdent"] },
      textAlign: { options: ["left", "center", "right", "justify"] },
      colorPicker: {},
      link: { inDropdown: true },
      emoji: {},
      embedded: {},
      image: {
        uploadCallback: uploadImageCallback,
        alt: { present: true, mandatory: false },
        previewImage: true,
      },
      history: { inDropdown: true },
    };
    return (
      <>
        <Editor
          placeholder={this.props.placeholder}
          editorState={editorState}
          toolbar={customToolbarOptions}
          toolbarClassName={
            name === "Body" ?
              withDirectionTags
                ? "rtf-toolbar-with-direction-control"
                : "rtf-toolbar" : null
          }
          wrapperClassName={
            name === "Body" ? (withDirectionTags ? "rtf-wrapper-with-direction-body" : "rtf-wrapper-body") :
              (withDirectionTags ? "rtf-wrapper-with-direction" : "rtf-wrapper")
          }
          wrapperStyle={{
            border: this?.props?.error ? "1px solid red" : "2px solid #d3d3d3"
          }}
          // wrapperClassName={
          //     withDirectionTags ? "rtf-wrapper-with-direction" : "rtf-wrapper"
          //   }
          editorClassName="rtf-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        {/* {withDirectionTags && ( */}
        <div
          style={{
            // display: "flex",
            border: "2px solid #d3d3d3",
            borderTop: "0px",
            padding: "8px",
            borderRadius: "8px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            rowGap: "5px"
            // justifyContent: this?.props?.schemaVal?.length > 0 ? "" : "center"

          }}
        >
          {this?.props?.schemaVal?.length > 0 ? (
            this?.props?.schemaVal?.map((v, i) => (
              <Button
                key={i} // Add a unique key for each button
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#DEE5EC",
                  color: "#2A3C50",
                  fontSize: "12px",
                  border: "none",
                  marginTop: "7px",
                }}
                onClick={() => name === "Body" || name === "Subject" ? this.getButtonAction(`#${v?.value}#`) : this.getButtonAction(`#${v?.value}#,`)}
              >
                {v?.label}
              </Button>
            ))
          ) : (
            <Grid >
              <Typography style={{
                color: "#6F6F6F",
                fontSize: "13px",
                marginBottom: "6px",
                fontFamily: `pc_regular !important`,
                textAlign: "center"
              }}>
                No Data Found!
              </Typography>
            </Grid>
          )}

          {/* <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
                border: "none",
              }}
              onClick={() => this.getButtonAction("<Frequency>")}
            >
              Frequency
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Route>")}
            >
              Route
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Order Dose>")}
            >
              Order Dose
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Admin Qty>")}
            >
              Admin Qty
            </Button> */}
        </div>

      </>
    );
  }
}
