import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import {
  TopNavBar,
  HoverNavBar,
  CommonTabList,
  OverlayCompt,
  CommonTable,
  VerticalList,
} from "../../components";
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationEditView from "./NotificationEditView";
import { Div } from "qdm-component-library";
import { useEffect } from "react";
import { AlertContext, BackdropContext } from "../../contexts";
import { useContext } from "react";
import ConfirmationDialog from "../../screens/availabilityManagement/timeTableManagement/TimeTableManagementForm/confirmationDialog";
import { AlertProps, AxiosCall } from "../../utils";
import Config from "../../config";

const styles = {
  outlinedButton: {
      borderColor: "#DEDEDE",
      color: "#2A60BC",
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace:"nowrap",
    },
    outlinedDelButton: {
      borderColor: "#FF4D4A",
      color: "#FF4D4A",
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace:"nowrap",
    },
    modalHeading: {
      fontSize: 16,
      color: "#2A60BC",
      // marginBottom: 10,
    },
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 56,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    marginTop: "60px",
    marginLeft: "10px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
  root2: {
    height: "90%",
    backgroundColor: "white",
    padding: 12,
    border: "1px solid #ddd",
    borderRadius: 12,
    margin: 30,
  },
  titleText: {
    fontSize: 16,
    color: "#101010",
    marginLeft: 40,
    fontFamily: "pc_semibold",
  },
  inputText: {
    fontSize: 14,
    margin: "8px 0px 12px 0px",
    "& input": {
      padding: "10px 12px",
    },
    backgroundColor: "#00000008",
    border: "1px solid #1010100D",
  },
  addBtnGrid: {
    width: "18%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  TitleDiv: {
    display: "flex",
    alignItems: "center",
  },
  DivBtn: {
    display: "flex",
  },
  DivBtn1: {
    aliginItem: "center",
    justifyContent: "end",
  },
  DivBtn1Input: {
    width: "58%",
  },
  btnAdd: {
    padding: "5px 12px",
  },
  btnSearch: {
    fontSize: 21,
  },
  btInputDive: {
    justifyContent: "end",
  },
  form: {
    paddingTop: 15,
  },
}));

const AlertNotification = (props) => {
  const axios = require("axios");
  const classes = useStyles({ props });
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const [notificationData, setNotificationData] = useState([]);
  const [alertNotific, setalertNotific] = useState(); 
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    data: "",
  });

  const [state, setState] = useState({
    openForm: false,
    editData: null,
    perPage: 10,
    page: 0,
    search: "",
    editIndex: null,
    isView: false,
    isEdit: false
  });
  const handleForm = async (type) => {
    let { openForm } = state;
    setState({
      openForm: !openForm,
      isView: false,
      isEdit: false,
    });
    setalertNotific();
  };
  const handleEdit = (e, data, index) => {
    let notificDataObj = notificationData
    notificDataObj = notificDataObj[index]
    setalertNotific(notificDataObj)
    setState({
      ...state,
      openForm: true,
      isEdit: true,
    });
  };
  const handleView = (e, data, index) => {
    let notificDataObj = notificationData
    notificDataObj = notificDataObj[index]
    setalertNotific(notificDataObj)
    setState({
      ...state,
      openForm: true,
      editData: data,
      isView: true,
    });
  };
  const handleSearch = async (value = "") => {
    if (value?.length > 0) {
      await getAlertNotification(state.page, state.perPage, value ? value.trim() : "");
      setState({
        ...state,
        page: state.page,
        perPage: state.perPage,
        search: value ? value.trim() : "",
      });
    } else if (!value) {
      getAlertNotification(0,10,"")
    }
    setState({
      ...state,
      search: "",
    });
  };

  const handleDelete = async (e, data, index) => {
    setModalOpen({ open: true, data: data, index:index });
  };
  const handleDeleteRec = async (del) => {
    try {
      if(del?.open){
        let deleteData = notificationData[del?.index];
        let delPayload = {
          db_name: process.env.REACT_APP_METADATA_DB_NAME,
          entity: Config.notificationConfig,
          filter: `${Config.notificationConfig}._id == '${deleteData?._id}'`,
          metadata_dbname: process.env.REACT_APP_MetadataDB_Name
        }
        let Resp = await AxiosCall(delPayload,process.env.REACT_APP_SOFT_DELETE)
        if (Resp) {
            handleModalClose()
            if(Resp?.Code === 201){
            getAlertNotification(0,10,"")
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Deleted Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            } else {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Delete Failed",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            }
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "please contact admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleModalClose = () => {
    setModalOpen({
      open: false,
      data: "",
    });
  };
  const handlepageChange = async (currentPage, PerPage) => {
    setState({
      ...state,
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
      search: state?.search,
    });

    await getAlertNotification((currentPage - 1) * PerPage, PerPage, state?.search);
  };

  const getAlertNotification = async (page, perPage, search) => {
    let data = JSON.stringify({
        "db_name": process.env.REACT_APP_METADATA_DB_NAME,
        "entity": Config.notificationConfig,
        "filter": `(LIKE(Notification_Configuration.alertname,CONCAT('%${search}%'),true) || LIKE(Notification_Configuration.alertcode,CONCAT('%${search}%'),true)) && Notification_Configuration.activestatus==true`,
        "sort":"Notification_Configuration.createddate desc",
        limit : { "offset" : page, "count": perPage},
        "return_fields": "merge(adqolcNotification_Configuration,{alerts:(for alert in  adqolcNotification_Configuration.alerts return merge(alert,{alertmode:{label:document(alert.alertmode).display,value:document(alert.alertmode).display,_id:alert.alertmode}}))})"
      });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_ARANGO_API+"/api/read_documents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {  
        setNotificationData(response?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAlertNotification(state?.page, state?.perPage, state?.search);
  }, []);

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div className={classes.topNavbar}>
        <TopNavBar />
      </div>

      {
        <div className={classes.hovarNavBar}>
          <HoverNavBar />
        </div>
      }

      {/* Content */}
      <div className={classes.content}>
        <Div style={{ display: "flex", justifyContent: "space-between" }}>
          <CommonTabList
            parent_id={"admintabpanel"}
            title="Alert Notification"
            list={[]}
          />
        </Div>
        <React.Fragment>
          <Grid style={{ padding: "30px" }}>
            <VerticalList
              parent_id={"timetable"}
              addFunc={() => handleForm()}
              id={"timetable"}
              placeholder={"Search"}
              onSearchChange={handleSearch}
              hideSearchBar={false}
              hideFilter={true}
              TabList={[
                {
                  name: "Alert Notification",
                  component: (
                    <CommonTable
                      parent_id={"timetable"}
                      rightAlign={["Status", "Action"]}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleView={handleView}
                      Header={[
                        { label: "S.No", showSort: false, showFilter: false },
                        {
                          label: "Alert Code",
                          showSort: false,
                          showFilter: false,
                        },
                        {
                          label: "Alert Name",
                          showSort: false,
                          showFilter: false,
                        },
                        { label: "View", showSort: false, showFilter: false },
                        { label: "Action", showSort: false, showFilter: false },
                      ]}
                      dataList={notificationData}
                      tableData={[
                        { type: ["INCRIMENT"], name: "" },
                        {
                          type: ["TEXT"],
                          name: "alertcode",
                        },
                        { type: ["TEXT"], name: "alertname" },
                      
                        {
                          type: ["VIEWICON"],
                          name: "",
                          icon: (
                            <VisibilityIcon
                              style={{
                                color: "#0071F2",
                                fontSize: 16,
                                marginRight: "5px",
                              }}
                            />
                          ),
                          label: "View",
                        },
                        { type: ["EDIT", "DELETE"], align: "right" },
                      ]}
                      handlepageChange={(currentPage, PerPage) =>
                        handlepageChange(currentPage, PerPage)
                      }
                      TableCount={notificationData?.[0]?.totalcount}
                      incrementCount={state.page}
                      showPagination={true}
                      rowsPerPageOptions={[
                        { label: "10 Rows", value: 10 },
                        { label: "50 Rows", value: 50 },
                        { label: "100 Rows", value: 100 },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </Grid>

          <OverlayCompt
            parent_id={"timetable"}
            open={state?.openForm}
            children={
              <React.Fragment>
                {state?.openForm && (
                  <NotificationEditView
                    alertNotific={alertNotific}
                    closeForm={handleForm}
                    getAlertNotification={getAlertNotification}
                    setalertNotific={setalertNotific}
                    isView={state?.isView}
                    isEdit={state?.isEdit}
                  />
                )}
              </React.Fragment>
            }
          />
        </React.Fragment>
      </div>
      {modalOpen?.open && (
        <Dialog
        // fullScreen={fullScreen}
        open={modalOpen?.open}
        onClose={()=>handleModalClose()}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle style={styles.modalHeading} id="alert-dialog-title">{"Are you Sure Want to Delete ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this record? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding:"15px"}}>
          <Button onClick={()=>handleModalClose()} color="primary"
          style={styles.outlinedButton}
          variant="contained"
          >
            No
          </Button>
          <Button onClick={()=>handleDeleteRec(modalOpen)} color="primary"
          style={styles.outlinedDelButton}
          variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      )}
    </div>
  );
};

export default AlertNotification;
