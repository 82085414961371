import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { SelectBox } from "../../../../components";
import {
  enumerateDaysBetweenDates,
  btAllMonths,
  weekOfMonthbasedOnDay,
  extractIdAndValue,
  AxiosCall,
  utcTOLocal,
} from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import CustomInputDatePicker from "../../../../components/common/datePicker/inputDatePicker";
import { actions } from "primary_care_admin_binder";
import Config from "../../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 16px",
    margin: 15,
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    // paddingTop: 15
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  formParent: {
    paddingRight: 10,
  },
  perioSelection: {
    // background: "#F7F7F7",
    paddingLeft: 8,
    borderRadius: "8px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    width: "38px",
  },
  marginleft10: {
    marginLeft: "10px",
    fontSize: "12px",
    fontFamily: "poppin",
  },
  monthcheckbox: {
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
      fontFamily: "poppin !important",
    },
  },
  stitleData: {
    display: "grid",
    gridTemplateColumns: "160px 1fr 75px",
    padding: "20px 0px 16px",
  },
  atitleData: {
    display: "grid",
    gridTemplateColumns: "250px 1fr 84px",
  },
  divider: {
    marginTop: "9px",
    borderBottom: "1px dashed #e9e9e9",
    background: "none",
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
    cursor: "pointer",
    color: "#0071F2",
  },
  input: {
    borderRadius: "8px",
  },
  label: {
    fontSize: "12px",
  },
  labelRoot: {
    marginRight: "8px",
  },
}));

const LoadPeriod = (props) => {
  const classes = useStyles();
  const { name, options, color, stateData = {}, setState = () => null } = props;
  return (
    <div
      style={{ display: "flex", marginTop: props?.overRide ? "6px" : "20px" }}
    >
      <div style={{ display: "flex" }}>
        <p className={classes.grayfont}>{name}</p>
        <div
          style={{
            height: "25px",
            width: "3px",
            backgroundColor: color,
            marginLeft: "20px",
            marginTop: "8px",
          }}
        ></div>
      </div>
      <div className={classes.monthcheckbox}>
        <FormGroup row>
          {options?.map((val) => {
            return (
              <FormControlLabel
                className={classes.marginleft10}
                control={
                  <Checkbox
                    onChange={(e) =>
                      setState(val.value, e.target.checked, null)
                    }
                    checked={stateData?.[val.value] ?? false}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name={val.label}
                    disabled={val.disabled}
                    color="primary"
                  />
                }
                label={val.label}
              />
            );
          })}
        </FormGroup>
      </div>
    </div>
  );
};

const ResourceAvailability = (props) => {
  const {
    stateData = {},
    setState = () => null,
    editData = null,
    parent_id,
    scheduleDef = {},
    setScheduledDef = () => null,
    overRide = false,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.timetableMasterSlice);
  let stateList = useSelector((state) => state.timetableMasterSlice);
  const [open, setOpen] = React.useState(false);
  const [isfirst, setisfirst] = React.useState(true);
  const [ConsultType, setConsultType] = React.useState([]);
  const [HolidayTT, setHolidayTT] = React.useState([]);
  const [period, setPeriod] = React.useState({
    month: month,
    Week: Week,
    Days: Days,
    Holidays: Holidays,
    isfirst: false,
  });
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const disableArray = (array) => {
    let arr = array.map((val) => {
      val.disabled = false;
      return val;
    });
    return arr;
  };
  const getHolidayTimeTable = async (start,end) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
        startdate: start,
        enddate: end
      },
        queryid: Config.getHolidayTimeTableQueryID
      }

      let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
      if(Resp){
        // let result = []
        // Resp?.map((v,i)=>{
        //   let obj = {
        //     entityName:v?.entitytype?.display,
        //     timetable_title:v?.timetable_title,
        //     resourcetype:v?.resourcetype?.display,
        //     resourcerole:v?.resourcerole?.display,
        //     effectivefrom:epochToDate(v?.effectivefrom,"dd/mm/yyyy"),
        //     effectiveto:epochToDate(v?.effectiveto,"dd/mm/yyyy")
        //   }
        //   result.push(obj);
        // })
        setHolidayTT(Resp);
        // setPeriod({
        //   ...period,
        //   Holidays:Resp
        // })
        
      }
  } catch(e) {
      console.error(e)
  }
  }
  React.useEffect(() => {
    const getResAval = async() =>{
      if (scheduleDef.start_date && scheduleDef.end_date) {
        const start = Math.floor(scheduleDef.start_date / 1000);
        const end = Math.floor(scheduleDef.end_date / 1000);
        // const getResult =  await getHolidayTimeTable(start,end)
        const holidayData = HolidayTT?.map((val) => {
          return {
            value: val?._id,
            label: val?.CalDate ? utcTOLocal(val?.CalDate, "DD-MMM-YYYY") : "",
            disabled: false,
            name: val?.CalDate ? utcTOLocal(val?.CalDate, "DD-MMM-YYYY") : "",
          };
        });
        // const holidayData = await dispatch(
        //   actions.HOLIDAYS_TIME_TABLE({ start: start, end: end })
        // );
        period.Holidays = holidayData;
        let dayCount = moment(scheduleDef.end_date).diff(
          moment(scheduleDef.start_date),
          "days"
        );
        if (dayCount < 7) {
          // days
          let days = enumerateDaysBetweenDates(
            scheduleDef.start_date,
            scheduleDef.end_date
          );
          period.Days = period.Days.map((val) => {
            val.disabled = days.indexOf(val.name) === -1 ? true : false;
            return val;
          });
        } else {
          period.Days = disableArray(period.Days);
        }
        let stmonths = moment(scheduleDef.start_date).format("MM");
        let etmonths = moment(scheduleDef.end_date).format("MM");
        let stday = moment(scheduleDef.start_date).format("DD");
        let etday = moment(scheduleDef.end_date).format("DD");
        let stweak = weekOfMonthbasedOnDay(stday);
        let etweak = weekOfMonthbasedOnDay(etday);
        let dayCountonMonth = moment(scheduleDef.start_date).daysInMonth();
  
        if (
          dayCount < 29 ||
          (parseInt(stmonths) === 2 && dayCountonMonth === 28 && etweak < 5)
        ) {
          // weeks
          // let stweak = weekOfMonth(moment(stateData.start_date))
          // let etweak = weekOfMonth(moment(stateData.end_date))
          let weak5disable = false;
          if (parseInt(stmonths) === 2 && dayCountonMonth === 28 && etweak < 5) {
            weak5disable = true;
          }
          period.Week = period.Week.map((val) => {
            if (weak5disable && val.name === 5) {
              val.disabled = true;
            } else {
              if (parseInt(stmonths) === parseInt(etmonths)) {
                val.disabled =
                  val.name <= etweak && val.name >= stweak ? false : true;
              } else {
                val.disabled =
                  val.name <= etweak || val.name >= stweak ? false : true;
              }
            }
            return val;
          });
        } else {
          period.Week = disableArray(period.Week);
        }
        if (dayCount < 340) {
          // months
          let months = btAllMonths(
            moment(moment(scheduleDef.start_date).format("YYYY-MM-DD")),
            moment(moment(scheduleDef.end_date).format("YYYY-MM-DD"))
          );
          period.month = period.month.map((val) => {
            val.disabled = months.indexOf(val.name) === -1 ? true : false;
            return val;
          });
        } else {
          period.month = disableArray(period.month);
        }
        let periodJson = {};
        if (!isfirst) {
          Week.map((val) => {
            periodJson[val.value] = false;
          });
          Days.map((val) => {
            periodJson[val.value] = false;
          });
          month.map((val) => {
            periodJson[val.value] = false;
          });
          Holidays.map((val) => {
            periodJson[val.value] = false;
          });
        }
        setState({
          ...stateData,
          ...periodJson,
        });
        setScheduledDef({
          ...scheduleDef,
          schedule_available_days: dayCount + 1,
        });
        setPeriod(period);
        forceUpdate();
      }
    }
    getResAval();
  }, [scheduleDef.start_date, scheduleDef.end_date,HolidayTT]);

  React.useEffect(()=>{
    if(scheduleDef.start_date || scheduleDef.end_date){
      getHolidayTimeTable(Math.floor(scheduleDef.start_date / 1000),Math.floor(scheduleDef.end_date / 1000))
    }
  },[scheduleDef.start_date,scheduleDef.end_date])
  React.useEffect(()=>{
    const getConsultType = async() =>{
      let getVal = await extractIdAndValue("CONSULTTYPE",props?.options)
      setConsultType(getVal)
    }
    getConsultType();
  },[props?.options])
  const updateState = async (name, value, i) => {
    setisfirst(false);
    if (i > -1 && i !== null) {
      stateData.appointmentList[i][name] = value;
      setState({ ...stateData });
    } else {
      setState({ ...stateData, [name]: value });
    }
  };

  const addNewSchedule = async () => {
    props?.addNewSchedule();
    setState({
      appointmentList: [
        {
          duration_type: "",
          day_type: "",
          start_time: "",
          end_time: "",
          schedule_duration: "",
          max_appoinment: "",
          max_appoinment_allowed: "",
          max_walking: "",
          max_over_booking: "",
          max_wait_list: "",
          consultation_type: "",
        },
      ],
      holidays: [],
    });
    setOpen(false);
  };

  const handleAddNewAppointmentList = () => {
    const addState = JSON.parse(JSON.stringify(stateData));
    const initialState = {
      duration_type: "",
      day_type: "",
      start_time: "",
      end_time: "",
      schedule_duration: "",
      max_appoinment: "",
      max_appoinment_allowed: "",
      max_walking: "",
      max_over_booking: "",
      max_wait_list: "",
      consultation_type: "",
    };
    addState.appointmentList.push(initialState);
    setState({ ...addState });
  };

  // minimum Date Set
  const getMinDate = (date, type) => {
    const hour = date?.substring(0, 2);
    const minute = date?.substring(5, 7);
    return setHours(setMinutes(new Date(), minute), hour);
  };
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}
        style={{ margin: overRide ? "15px 15px 15px 0px" : 15 }}
      >
        <Grid
          id={`${parent_id}-APPOINTMENT-grid`}
          className={classes.atitleData}
        >
          <Typography
            id={`${parent_id}-APPOINTMENT-title-typography`}
            className={classes.title}
            variant="h6"
          >
            RESOURCE AVAILABILITY FOR THE PERIOD
          </Typography>
          <Divider className={classes.divider} />
          {!open && !overRide && (
            <Grid
              id={`${parent_id}-title-divider`}
              style={{ textAlign: "right" }}
            >
              <Button
                id={`${parent_id}-Add-New-typography`}
                disabled={
                  editData?.effectiveto >= moment(scheduleDef?.end_date).unix()
                }
                className={classes.addBtn}
                style={{ padding: "0px" }}
                color="primary"
                variant="h6"
                onClick={() => setOpen(true)}
              >
                + ADD NEW
              </Button>
            </Grid>
          )}
        </Grid>
        {(open || overRide) && (
          <>
            <Grid
              id={`${parent_id}-APPOINTMENT-LoadPeriod-grid`}
              container
              className={classes.form}
            >
              <Grid
                id={`${parent_id}-LoadPeriod-grid`}
                className={classes.perioSelection}
              >
                <LoadPeriod
                  id={`${parent_id}-month-LoadPeriod`}
                  stateData={stateData}
                  setState={updateState}
                  color="#0C78F2"
                  name="Month"
                  options={period.month}
                  overRide={overRide}
                />
                <LoadPeriod
                  id={`${parent_id}-Week-LoadPeriod`}
                  stateData={stateData}
                  setState={updateState}
                  color="#F5900C"
                  name="Week"
                  options={period.Week}
                  overRide={overRide}
                />
                <LoadPeriod
                  id={`${parent_id}-Days-LoadPeriod`}
                  stateData={stateData}
                  setState={updateState}
                  color="#F5CE0C"
                  name="Days"
                  options={period.Days}
                  overRide={overRide}
                />
                <LoadPeriod
                  id={`${parent_id}-Holidays-LoadPeriod`}
                  stateData={stateData}
                  setState={updateState}
                  color="#00DDF5"
                  name="Holidays"
                  options={period.Holidays}
                  overRide={overRide}
                />
              </Grid>
            </Grid>
            <Grid
              id={`${parent_id}-parent-title-grid`}
              className={classes.stitleData}
            >
              <Typography
                id={`${parent_id}-title-typography`}
                className={classes.title}
                variant="h6"
              >
                APPOINTMENT DURATION
              </Typography>
              <Divider
                id={`${parent_id}-title-divider`}
                className={classes.divider}
              />
              <Grid
                id={`${parent_id}-title-divider`}
                style={{ textAlign: "right" }}
              >
                <Typography
                  id={`${parent_id}-Add-New-typography`}
                  className={classes.addBtn}
                  color="primary"
                  variant="h6"
                  onClick={handleAddNewAppointmentList}
                >
                  + ADD NEW
                </Typography>
              </Grid>
            </Grid>
            {stateData?.appointmentList?.map((val, i) => (
              <Grid container style={{ paddingBottom: "16px" }}>
                <Grid item md="4" lg="4">
                  <Grid container>
                    <Grid item md="3" lg="3" className={classes.formParent}>
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Duration Type
                      </Typography>
                      <SelectBox
                        parent_id={"schedule-period"}
                        list={[
                          { label: "Slot", value: "Slot" },
                          { label: "Slab", value: "Slab" },
                        ]}
                        // value={stateData?.duration_type ?? null}
                        value={val.duration_type ?? null}
                        disableClearable
                        onchange={(e, value) =>
                          updateState("duration_type", value, i)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      md="5"
                      lg="5"
                      style={{ maxWidth: "40%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Day Type
                      </Typography>
                      <SelectBox
                        parent_id={"schedule-period"}
                        list={extractIdAndValue("DAYTYPE",props?.options)}
                        // value={stateData?.day_type ?? null}
                        value={val.day_type ?? null}
                        disableClearable
                        onchange={(e, value) =>
                          updateState("day_type", value, i)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      md="2"
                      lg="2"
                      style={{ maxWidth: "18%", flexBasis: "18%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Start Time
                      </Typography>
                      <CustomInputDatePicker
                        placeholderText="dd / mm / yyyy"
                        showTimeSelect
                        showTimeSelectOnly
                        hideTimeIcon={true}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        selectedDate={null}
                        handleChange={(value) =>
                          updateState(
                            "start_time",
                            moment(value).format("HH: mm"),
                            i
                          )
                        }
                        inputField={
                          <TextField
                            id={`${parent_id}-${i}-textField`}
                            fullWidth
                            variant="outlined"
                            value={val.start_time}
                            inputProps={{
                              style: {
                                padding: "12.5px 11px",
                                fontFamily: "poppin",
                                fontSize: "12px",
                                borderRadius: "8px",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md="2" lg="2" className={classes.formParent}>
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        End Time
                      </Typography>
                      <CustomInputDatePicker
                        placeholderText="dd / mm / yyyy"
                        showTimeSelect
                        showTimeSelectOnly
                        hideTimeIcon={true}
                        timeFormat="HH:mm"
                        minTime={getMinDate(val.start_time, "startDate")}
                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                        timeIntervals={15}
                        selectedDate={null}
                        handleChange={(value) =>
                          updateState(
                            "end_time",
                            moment(value).format("HH: mm"),
                            i
                          )
                        }
                        inputField={
                          <TextField
                            id={`${parent_id}-${i}-textField`}
                            fullWidth
                            variant="outlined"
                            value={val.end_time}
                            inputProps={{
                              style: {
                                padding: "12.5px 8px",
                                fontFamily: "poppin",
                                fontSize: "12px",
                                borderRadius: "8px",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md="6" lg="6">
                  <Grid container>
                    <Grid
                      item
                      md="2"
                      lg="2"
                      style={{ maxWidth: "14%", flexBasis: "20%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        SlotDuration
                      </Typography>
                      <TextField
                        id={`${parent_id}-Timetable-textField`}
                        // disabled={Boolean(editData)}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        // value={stateData?.max_over_booking}
                        value={val?.schedule_duration}
                        onChange={(e) =>
                          updateState("schedule_duration", e.target.value, i)
                        }
                        error={props.error?.schedule_duration}
                      />
                    </Grid>
                    <Grid
                      item
                      md="2"
                      lg="2"
                      style={{ maxWidth: "17%", flexBasis: "18%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Max Appt/Slab
                      </Typography>
                      <TextField
                        id={`${parent_id}-Timetable-textField`}
                        // disabled={Boolean(editData)}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        // value={stateData?.max_appoinment}
                        onChange={(e) =>
                          updateState("max_appoinment", e.target.value, i)
                        }
                        value={val?.max_appoinment}
                        error={props.error?.max_appoinment}
                      />
                    </Grid>
                    <Grid
                      item
                      md="2"
                      lg="2"
                      style={{ maxWidth: "17%", flexBasis: "20%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Max ApptAllow
                      </Typography>
                      <TextField
                        id={`${parent_id}-Timetable-textField`}
                        // disabled={Boolean(editData)}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        // value={stateData?.max_appoinment_allowed}
                        onChange={(e) =>
                          updateState(
                            "max_appoinment_allowed",
                            e.target.value,
                            i
                          )
                        }
                        value={val?.max_appoinment_allowed}
                        error={props.error?.max_appoinment_allowed}
                      />
                    </Grid>
                    <Grid
                      item
                      md="2"
                      lg="2"
                      style={{ maxWidth: "15%", flexBasis: "20%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Max Walkins
                      </Typography>
                      <TextField
                        id={`${parent_id}-Timetable-textField`}
                        // disabled={Boolean(editData)}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        // value={stateData?.max_walking}
                        value={val?.max_walking}
                        onChange={(e) =>
                          updateState("max_walking", e.target.value, i)
                        }
                        error={props.error?.max_walking}
                      />
                    </Grid>
                    <Grid
                      item
                      md="2"
                      lg="2"
                      style={{ maxWidth: "20%", flexBasis: "20%" }}
                      className={classes.formParent}
                    >
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Max Over Booking
                      </Typography>
                      <TextField
                        id={`${parent_id}-Timetable-textField`}
                        // disabled={Boolean(editData)}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        // value={stateData?.max_over_booking}
                        value={val?.max_over_booking}
                        onChange={(e) =>
                          updateState("max_over_booking", e.target.value, i)
                        }
                        error={props.error?.max_over_booking}
                      />
                    </Grid>
                    <Grid item md="2" lg="2" className={classes.formParent}>
                      <Typography
                        id={`${parent_id}-date-Generation-Mode-typography`}
                        className={classes.formtitle}
                        variant="body1"
                      >
                        Max Wait List
                      </Typography>
                      <TextField
                        id={`${parent_id}-Timetable-textField`}
                        // disabled={Boolean(editData)}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                        // value={stateData?.max_wait_list}
                        value={val?.max_wait_list}
                        onChange={(e) =>
                          updateState("max_wait_list", e.target.value, i)
                        }
                        error={props.error?.max_wait_list}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md="2" lg="2">
                  <Typography
                    id={`${parent_id}-date-Generation-Mode-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Consultation Type
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {ConsultType?.map(
                      (value, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={index}
                              checked={
                                val?.consultation_type?.label === value?.label
                              }
                              onChange={(e) =>
                                updateState("consultation_type", value, i)
                              }
                              color="primary"
                              size="small"
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={value?.label}
                        />
                      )
                    )}
                  </div>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        {open && (
          <Grid
            id={`${parent_id}-button-grid`}
            style={{ textAlign: "right", padding: "0px 0px 4px" }}
          >
            <Button
              id={`${parent_id}-Cancel-button`}
              style={{
                marginLeft: 15,
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}-Save-button`}
              style={{
                marginLeft: 15,
                backgroundColor: "#0071F2",
                textTransform: "none",
                borderRadius: "8px",
              }}
              color="primary"
              variant="contained"
              onClick={addNewSchedule}
            >
              Confirm
            </Button>
          </Grid>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default ResourceAvailability;

const month = [
  { value: "Month1", label: "Jan", disabled: false, name: "Jan" },
  { value: "Month2", label: "Feb", disabled: false, name: "Feb" },
  { value: "Month3", label: "Mar", disabled: false, name: "Mar" },
  { value: "Month4", label: "Apr", disabled: false, name: "Apr" },
  { value: "Month5", label: "May", disabled: false, name: "May" },
  { value: "Month6", label: "Jun", disabled: false, name: "Jun" },
  { value: "Month7", label: "Jul", disabled: false, name: "Jul" },
  { value: "Month8", label: "Aug", disabled: false, name: "Aug" },
  { value: "Month9", label: "Sep", disabled: false, name: "Sep" },
  { value: "Month10", label: "Oct", disabled: false, name: "Oct" },
  { value: "Month11", label: "Nov", disabled: false, name: "Nov" },
  { value: "Month12", label: "Dec", disabled: false, name: "Dec" },
];
const Week = [
  { value: "Week1", label: "Week 1", disabled: false, name: 1 },
  { value: "Week2", label: "Week 2", disabled: false, name: 2 },
  { value: "Week3", label: "Week 3", disabled: false, name: 3 },
  { value: "Week4", label: "Week 4", disabled: false, name: 4 },
  { value: "Week5", label: "Week 5", disabled: false, name: 5 },
];
const Days = [
  { value: "Day1", label: "Sun", disabled: false, name: "Sunday" },
  { value: "Day2", label: "Mon", disabled: false, name: "Monday" },
  { value: "Day3", label: "Tue", disabled: false, name: "Tuesday" },
  { value: "Day4", label: "Wed", disabled: false, name: "Wednesday" },
  { value: "Day5", label: "Thu", disabled: false, name: "Thursday" },
  { value: "Day6", label: "Fri", disabled: false, name: "Friday" },
  { value: "Day7", label: "Sat", disabled: false, name: "Saturday" },
];

const Holidays = [];
