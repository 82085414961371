import { Button, Grid } from "@material-ui/core";
import { actions } from "primary_care_admin_binder";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonTabList, TTSchedulePeriod } from "../../../../components";
import ResourceAvailability from "./resourceAvailability";
import ScheduleDefDetails from "./scheduleDefDetails";
import { v4 as uuidv4 } from "uuid";
import { useStyles } from "./styles";
import {
  constructEditAvailability,
  constructEditDataPayload,
  constructEditScheduleDef,
  saveJson,
} from "./constructJson";
import { withAllContexts } from "../../../../HOCs";
import { AlertProps, AxiosCall, getPractjwtToken } from "../../../../utils";
import AvailabilityDetailsList from "./availabilityDetailsList";
import Config from "../../../../config";

const scheduleDefKeys = [
  "scheduletitle",
  "facility_name",
  "resource_type",
  "resource_role",
  "start_date",
  "end_date",
  "schedule_generation_mode",
  "schedule_available_days",
  // "applicable_app_type",
  "entity_type",
];
const TimeTableManagementForm = (props) => {
  const classes = useStyles();
  const { closeForm, editData, parent_id } = props;
  const dispatch = useDispatch();
  const [scheduleDef, setScheduledDef] = useState({
    resources: [],
    applicable_app_type: [],
  });
  const [resourcePeriod, setResourcePeriod] = useState({
    appointmentList: [
      {
        duration_type: "",
        day_type: "",
        start_time: "",
        end_time: "",
        schedule_duration: "",
        max_appoinment: "",
        max_appoinment_allowed: "",
        max_walking: "",
        max_over_booking: "",
        max_wait_list: "",
        consultation_type: "",
      },
    ],
    holidays: [],
  });
  const [err, setErr] = React.useState({});
  const [resourceList, setResourceList] = React.useState([]);
  const stateData = useSelector((state) => state.timetableMasterSlice);
  useEffect(() => {
    if (editData) {
      // const dropDataResource = dispatch(
      //   actions.GET_RESOURCES_PRAC({
      //     orgId: editData?.organization_id?._id,
      //     pracRole: editData?.resourcerole?._id,
      //   })
      // );
      const constructEditSchedule = constructEditScheduleDef(editData);
      const constructEditList = constructEditAvailability(editData);
      setScheduledDef(JSON.parse(JSON.stringify(constructEditSchedule)));
      setResourceList(constructEditList);
    } else {
      // let entType = stateData.entity_type_list.data.filter(
      //   (val) => val.label === "Facility"
      // )[0];
      // setScheduledDef({ ...scheduleDef, entity_type: entType?.value });
      // dispatch(
      //   actions.GET_ENTITY_NAME_WITH_TYPE({ type: entType?.value })
      // );
    }
  }, []);

  const addNewSchedule = async () => {
    const newList = JSON.parse(JSON.stringify(resourceList));
    const settingResouceId = JSON.parse(JSON.stringify(resourcePeriod));
    const settingResouceKeys = Object.keys(settingResouceId);
    const holidayData = stateData.holidays_time_table.data;
    const holidayList = holidayData?.filter((val) =>
      settingResouceKeys?.includes(val?.value)
    );
    settingResouceId["holidaysList"] = holidayList?.map((val) => {
      return {
        holidayid: val?.value,
        includeholiday: true,
      };
    });
    settingResouceId["id"] = uuidv4();
    settingResouceId["isNew"] = true;
    newList.push(settingResouceId);
    setResourceList(newList);
  };

  const handleDelete = (i) => {
    const newDelete = JSON.parse(JSON.stringify(resourceList));
    newDelete.splice(i, 1);
    JSON.parse(JSON.stringify(newDelete));
    setResourceList(newDelete);
  };

  const checkError = () => {
    let error = false;
    let errorState = {};
    scheduleDefKeys.map((val) => {
      if (!scheduleDef[val]) {
        error = true;
        errorState[val] = true;
      }
    });
    setErr(errorState);
    return [error, errorState];
  };

  const successForm = async (isDraft) => {
    const saveData = saveJson(scheduleDef, resourceList, isDraft);
    const [isError, state] = checkError();
    if (isError) {
      props.alert.setSnack("mandatory");
    } else {
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: true,
        message: editData ? "Updating..." : "Saving...",
      });
      let jwt = await getPractjwtToken();
      const headers = {
        'Content-Type': 'application/json',
        jwtToken: jwt,
      };
      if (isDraft === false && resourceList?.length === 0) {
        props.alert.setSnack(
          "error",
          "Please 'save as draft' if there is no resource availability period"
        );
      } else {
        if (editData && isDraft === false && editData?.is_draft === false) {
          props.alert.setSnack("success", "Save the edit Data");
          // const constructEdit = constructEditDataPayload(
          //   scheduleDef,
          //   resourceList
          // );
          let filter = {
            filter: {
              _id: editData?._id,
            },
          };
          let payload = [
            {
              db_name: process.env.REACT_APP_DATABASE_NAME,
              entity: Config.ResourceTimeTableEntity,
              is_metadata: true,
              metadataId: process.env.REACT_APP_METADATAID,
              metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
              ...filter,
              doc: saveData,
            }
          ]
          let saveResult = await AxiosCall(payload,Config.__addTimeTable__,headers)
          // const editResult = await dispatch(
          //   actions.RESOURCE_TIME_TABLE_EDIT({
          //     id: editData?._id,
          //     data: constructEdit,
          //   })
          // );
          closeForm();
        } else if (editData && editData?.is_draft === true) {
          // const filter = editData?._id;
          let filter = {
            filter: {
              _id: editData?._id,
            },
          };
          let payload = [
            {
              db_name: process.env.REACT_APP_DATABASE_NAME,
              entity: Config.ResourceTimeTableEntity,
              is_metadata: true,
              metadataId: process.env.REACT_APP_METADATAID,
              metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
              ...filter,
              doc: saveData,
            }
          ]
          let saveResult = await AxiosCall(payload,Config.__addTimeTable__,headers)
          // const saveResult = await dispatch(
          //   actions.RESOURCE_TIME_TABLE_SAVE({
          //     saveDoc: saveData,
          //     filter: filter,
          //   })
          // );
          const saveResultData = {
            error: saveResult?.error,
            msg: saveResult?.message,
          };
          props.alert.setSnack(
            saveResultData?.error ? "error" : "success",
            saveResultData?.msg
          );
          if (saveResultData?.error === false) {
            closeForm();
          }
        } else {
          let payload = [
            {
              db_name: process.env.REACT_APP_DATABASE_NAME,
              entity: Config.ResourceTimeTableEntity,
              is_metadata: true,
              metadataId: process.env.REACT_APP_METADATAID,
              metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
              doc: saveData,
            }
          ]
          let saveResult = await AxiosCall(payload,Config.__addTimeTable__,headers)   
            const saveResultData = {
              error: saveResult?.error,
              msg: saveResult?.message,
            };
            props.alert.setSnack({
              open: true,
              severity: saveResultData?.error
                ? AlertProps.severity.error
                : AlertProps.severity.success,
              msg: saveResultData?.msg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            if (saveResultData?.error === false) {
              closeForm();
            }
          // const saveResult = await dispatch(
          //   actions.RESOURCE_TIME_TABLE_SAVE({
          //     saveDoc: saveData,
          //     filter: null,
          //     token: jwt,
          //   })
          // );
        }
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: false,
          message: "",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <CommonTabList
        parent_id={"timetableform"}
        backbtn
        backFun={closeForm}
        title={
          editData ? "Edit Schedule Definition" : "Resource Schedule Definition"
        }
        list={[]}
      />
      <Grid id={`${parent_id}-ScheduleDefDetails-grid`}>
        <ScheduleDefDetails
          applicableData={stateData?.applicable_app_type?.data}
          parent_id={"ScheduleDefDetails"}
          error={err}
          seterr={setErr}
          editData={editData}
          stateData={scheduleDef}
          setState={setScheduledDef}
          options={props?.options}
        />
      </Grid>
      <Grid id={`${parent_id}-ResourceAvailability-grid`}>
        <ResourceAvailability
          parent_id={"resourcePeriod"}
          addNewSchedule={addNewSchedule}
          setScheduledDef={setScheduledDef}
          scheduleDef={scheduleDef}
          stateData={resourcePeriod}
          editData={editData}
          setState={setResourcePeriod}
          options={props?.options}
        />
      </Grid>
      {resourceList.map((val, i) => (
        <Grid key={val?.id}>
          <AvailabilityDetailsList
            editData={editData}
            val={val}
            i={i}
            stateData={stateData}
            handleDelete={handleDelete}
          />
        </Grid>
      ))}

      <Grid
        id={`${parent_id}-button-grid`}
        style={{ textAlign: "right", padding: 20 }}
      >
        <Button
          id={`${parent_id}-Cancel-button`}
          className={classes.cancelButton}
          variant="outlined"
          onClick={() => closeForm()}
        >
          Cancel
        </Button>
        {(!editData || editData?.is_draft === true) && (
          <Button
            id={`${parent_id}-Save-button`}
            className={classes.cancelButton}
            style={{ color: "#0071F2", borderColor: "#0071F2" }}
            variant="outlined"
            onClick={() => successForm(true)}
          >
            Save as draft
          </Button>
        )}
        <Button
          id={`${parent_id}-Save-button`}
          className={classes.confirmButton}
          color="primary"
          variant="contained"
          onClick={() => successForm(false)}
        >
          Save
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default withAllContexts(TimeTableManagementForm);
