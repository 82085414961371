import React from "react";
import { Card, CircularProgress, 
 // Drawer
 } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { CommonTable } from "../../components/common"
import { SearchWithFilter } from "../../components"
//import { makeStyles } from "@material-ui/core/styles";
import { routes } from "../../router/routes";
import { useHistory } from "react-router";
import { withAllContexts } from "../../HOCs";
//import { AlertProps } from "../../utils";

//const drawerWidth = "94%";
// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

 function PractitionerTable(props) {
  const dispatch = useDispatch();
  const { parent_id } = props;
  //const classes = useStyles();
  const history = useHistory();
  const listData = useSelector((state) => state?.practitionerSlice?.practitioner_list?.data);
  const loading = useSelector((state) => state?.practitionerSlice?.practitioner_list?.loading);
  //const saveloading = useSelector((state) => state?.practitionerSlice?.practitioner_upsert?.loading);
  const [state, setState] = React.useState({
    open: false,
    edit_data: null
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    console.log(currentPage, PerPage)
    setPage((currentPage-1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.PRACTITIONER_LIST_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));
  };

  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true
    });
    history.push({
      pathname: routes.addpractitioner
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null
    });
  };

  React.useLayoutEffect(() => {
    dispatch(actions.PRACTITIONER_LIST_READ({ page: page, perPage: perPage}));
  }, []);

  // const savePractitionerRole = async (values) => {
  //   let data = {
  //     code: values.type_code,
  //     description: values.type_description,
  //     type: props.type,
  //     status: values.status,
  //   }
  //   if (state?.edit_data) {
  //     data["_key"] = state?.edit_data._key
  //     data["id"] = state?.edit_data.id
  //   }
  //   await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
  //   await dispatch(actions.PRACTITIONER_LIST_READ({ type: props.type }));
  //   await handleDrawerClose()
  // }

  const handleEdit = (e, values, index) => {

    // let data = {
    //   code: values.code_type,
    //   description: values.description,
    //   type: props.type,
    //   status: values.status,
    //   _key: values._key,
    //   id: values.id
    // }
    // setState({
    //   ...state,
    //   edit_data: data,
    //   open: true
    // })
    history.push({
      pathname: routes.addpractitioner,
      state: {
        Id: values._key
      }
    });
  }

  const statusChange = async (e, values, index) => {
    // let data = {
    //   code: values.code_type,
    //   description: values.description,
    //   type: props.type,
    //   status: e.target.checked,
    //   _key: values._key,
    //   id: values.id
    // }
    // setState({
    //   ...state,
    //   edit_data: data,
    // })
    //  props.backDrop.setBackDrop({
    //    ...props.backDrop,
    //    open: true,
    //    message: "Status Updating",
    //  });

    // await dispatch(actions.PRACTITIONER_STATUS_CHANGE(data));
    // await dispatch(actions.PRACTITIONER_LIST_READ({ page: page, perPage: perPage, type: props.type,  }));
    // setState({
    //   ...state,
    //   edit_data: null,
    // })
    //  props.backDrop.setBackDrop({
    //    ...props.backDrop,
    //    open: false,
    //    message: "",
    //  });
    //  props.alert.setSnack("update");
  }
  
    const onSearchChange = (value = "") => {
    if (value?.length > 2) {
      dispatch(actions.PRACTITIONER_LIST_READ({
        type: props.type, 
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.PRACTITIONER_LIST_READ({
        type: props.type, 
        page: page,
        perPage: perPage
      }));
    }
  };

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-button-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-header-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-${props?.header?.replaceAll?.(" ", "-")}-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-Add-New-button-div`}
                style={{ marginTop: "16px", float: "right", marginRight:'20px', display:'flex' }}>

                <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />
                
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
              </div>
            </div>
           
            {/* table */}
            <div
              id={`${parent_id}-CommonTable-div`}
              style={{ padding: 20, height: "71vh", overflow: 'auto' }}>
              {/* {
                (loading && state.edit_data === null) ?
                  <div
                    id={`${parent_id}-CircularProgress-div`}
                    style={{ display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "66vh" }}>
                    <CircularProgress
                      id={`${parent_id}-CircularProgress`}
                    />
                  </div>
                  : */}
                  <CommonTable
                    parent_id={'practitioner'}
                    Header={["S.No", "Name", "Primary Specialty", "Type", "Status", "Action"]}
                    dataList={listData}
                    tableData={[
                      { type: ["INCRIMENT"], name: "" },
                      { type: ["TEXT"], name: "name" },
                      { type: ["TEXT"], name: "primaryspeciality" },
                      { type: ["TEXT"], name: "type" },
                      { type: ["CHECKBOX"], name: "status" },
                      { type: ["EDIT"], name: "" },
                    ]}
                    handleCheckBox={statusChange}
                    handleEdit={handleEdit}
                    handlepageChange={(currentPage, PerPage) =>
                      handlepageChange(currentPage, PerPage)
                    }
                    TableCount={listData?.[0]?.total_count}
                    incrementCount={page}
                    showPagination={true}
                    rowsPerPageOptions={[
                      { label: "10 Rows", value: 10 },
                      { label: "50 Rows", value: 50 },
                      { label: "100 Rows", value: 100 },
                    ]}
                    loading = {loading}
                  />
              {/* } */}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}
export default withAllContexts(PractitionerTable);