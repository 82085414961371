import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Popover,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from "@material-ui/core/styles";
import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { SelectBox } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import reactDom from "react-dom";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
}));
function GeneralAppoitmentInstructions(props) {
  const history = useHistory();
  const { finalValue, setFinalValue, SingleReadData, TableReadData } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    instructionCode: {},
    instructionDesc: "",
    GeneralAppointmentInst: [],
  });
  const [appointment, setappointment] = useState({
    code: "",
    description: "",
    // GeneralAppointmentInst: [],
  });
  const [valueshow, setvalueshow] = useState([]);
  const [value, setValue] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [Alertstate, setAlertState] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const drop = useSelector((state) => state.resourceByAppointmentTypesSlice);
  useEffect(() => {
    getMasterData();
  }, []);
  const getMasterData = async () => {
    let a = await dispatch(actions.RES_GENERAL_INSTRUCTION_DROPDOWN_QDM());
  };
  const deleteGeneral = (index) => {
    setFinalValue((prevFinalValue) => {
      const deleteGeneralResourceDefinition = [
        ...prevFinalValue.generalappinstruction,
      ];
      deleteGeneralResourceDefinition.splice(index, 1);
      return {
        ...prevFinalValue,
        generalappinstruction: deleteGeneralResourceDefinition,
      };
    });

    setvalueshow((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setappointment({
      code: "",
      description: "",
    });
  };
  const onClick = (name, val) => {
    if (name === "instructionCode") {
      setState({
        ...state,
        [name]: val,
        ["instructionDesc"]: val?.title,
      });
    } else {
      setState({
        ...state,
        [name]: val,
      });
    }
    setValue([
      ...value,
      {
        code: val?.label,
        description: val?.title,
      },
    ]);
  };

  const handleChangelist = async (key, value, e) => {
    if (key === "code") {
      setappointment((prevState) => ({
        ...prevState,
        code: value,
        description: value?.Value || "",
      }));
    } else {
      setappointment((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleEditvalue = (v, i) => {
    setCurrentEditIndex(i);
    setappointment({
      code: v?.code,
      description: v?.description,
    });
  };

  const specialSave = () => {
    let information = {
      // code: appointment?.code?.value,
      code: appointment?.code,
      description: appointment?.description,
    };
    if (currentEditIndex !== null) {
      setFinalValue((prevFinalValue) => {
        const updatedGeneralResourceDefinition = [
          ...prevFinalValue.generalappinstruction,
        ];
        updatedGeneralResourceDefinition[currentEditIndex] = appointment; // Update at the same index
        return {
          ...prevFinalValue,
          generalappinstruction: updatedGeneralResourceDefinition,
        };
      });
      const updatedValues = [...valueshow];
      updatedValues[currentEditIndex] = appointment;
      setvalueshow(updatedValues);
      setappointment({
        code: "",
        description: "",
      });
      setAnchorEl(null);
      setCurrentEditIndex(null);
    }
    // setFinalValue((prevFinalValue) => {
    //   return {
    //     ...prevFinalValue,
    //     generalappinstruction: [
    //       ...prevFinalValue.generalappinstruction,
    //       information,
    //     ],
    //   };
    // });
    else {
      if (appointment?.code?.label || appointment?.description) {
        const updatedValues = [...valueshow, appointment]; // Add new resource to valueshow
        setvalueshow(updatedValues);
        setFinalValue((prevFinalValue) => {
          // Add the new resource to finalValue
          return {
            ...prevFinalValue,
            generalappinstruction: [
              ...prevFinalValue.generalappinstruction,
              appointment,
            ], // Add the new resource to the list
          };
        });
      }
      setappointment({
        code: "",
        description: "",
      });
      handleClose();
    }
  };
  const handlechange = (key, value, Autocomp, order) => {
    if (typeof key === "object") {
      setState({
        ...state,
        ...key,
      });
    } else {
      if (typeof value === "string") {
        setState({ ...state, [key]: value });
      } else if (typeof value === "object") {
        if (Array.isArray(value)) {
          if (Autocomp) {
            setState({
              ...state,
              [key]: value?.[0] ?? null,
            });
          } else {
            setState({
              ...state,
              [key]: [...state?.[key], ...value],
            });
          }
        } else {
          setState({
            ...state,
            [key]: { ...state?.[key], ...value },
          });
        }
      }
    }
  };

  useEffect(() => {
    const generalData = TableReadData[0];
    if (history?.location?.state?.IsEdit && generalData) {
      let generalarry = [];
      generalData?.generalappinstruction?.map((val) => {
        let mappedData = {
          code: {
            _id: val?._id || "",
            label: val?.code,
            Value: val?.code,
          },
          description: val?.display,
        };
        generalarry.push(mappedData);
      });
      setvalueshow(generalarry);
    }
  }, [TableReadData]);
  return (
    <div>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                GENERAL APPOINTMENT INSTRUCTIONS
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  //   className={classes.newRes}
                  // color="primary"
                  onClick={handleClick}
                  style={{
                    marginTop: "-6px",
                    color: "#0071F2",
                    fontFamily: "poppinsemibold",
                  }}
                  button
                  disableTouchRipple
                  id={"resource_definition_card_Id"}
                >
                  + Add New
                </Button>
                <div style={{ marginBottom: "8px" }}>
                  {/* <img
                    src={expandarrow}
                    alt="icon"
                    // style={{
                    //   transform: show ? "rotate(180deg)" : "rotate(359deg)",
                    // }}
                  /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {/* {props?.data?.map((index) => {
            return ( */}
          {valueshow?.map((v, i) => {
            return (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: "34px",
                        padding: "8px 8px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {i + 1}
                      {/* {index + 1} */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginLeft: "4%",
                        display: "flex",
                        padding: "20px",
                      }}
                    >
                      <div>General Instructions</div>
                      {/* <div>
                        {v.code ? v.code.label : v.code ? v.code : " "}
                        {""}
                        <div className={classes.dot} />
                        {v.code ? v.code.title : v.code ? v.code : " "}
                        {""}
                      </div> */}
                      <Grid style={{ marginLeft: "10px" }}>
                        {v?.code?.label}
                      </Grid>
                      <Grid style={{ marginLeft: "10px" }}>
                        {v?.description}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        onClick={(e) => {
                          handleClick(e);
                          handleEditvalue(v, i);
                        }}
                        style={{ color: "#0071F2", fontSize: 18 }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                      onClick={() => deleteGeneral(i)}
                    >
                      <DeleteIcon style={{ color: "#FF4D4A", fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Card>
      </Card>

      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginLeft: "-120px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "700px", height: "280px" }}>
          <Grid container direction="row" lg={12} md={12} xl={12} xs={12}>
            <Grid item lg={11} md={11} xl={11}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: 14,
                  marginBottom: 8,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                Add General Appointment Instructions
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <CloseIcon
                style={{
                  marginTop: "-18px",
                  cursor: "pointer",
                  marginLeft: "36px",
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            xl={12}
            xs={12}
            direction={"row"}
            spacing={2}
          >
            <Grid item lg={4} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 10,
                  marginTop: 0,
                }}
              >
                Instruction Code
                {/* <span style={{ color: "red" }}>*</span> */}
              </Typography>
              <SelectBox
                parent_id={"generalAppointment-select"}
                value={appointment?.code ?? {}}
                // onchange={(e, value) => onClick("instructionCode", value)}
                onchange={(e, v) => {
                  handleChangelist("code", v);
                }}
                list={
                  Array.isArray(
                    drop?.res_general_instruction_dropdown_qdm?.data
                  )
                    ? drop?.res_general_instruction_dropdown_qdm?.data
                    : []
                }
              />
            </Grid>
            <Grid item lg={12} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: -4,
                }}
              >
                Description
              </Typography>
              <textarea
                style={{
                  width: "660px",
                  height: "120px",
                  fontSize: 16,
                  resize: "none",
                  padding: 14,
                }}
                value={appointment?.description ?? ""}

              ></textarea>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            onClick={handleClose}
            button
            disableTouchRipple
            style={{ marginRight: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            //color="primary"
            button
            disableTouchRipple
            style={{
              marginRight: "18px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
            onClick={() => {
              specialSave();
            }}
          >
            {currentEditIndex !== null ? "Update" : "Save"}
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default GeneralAppoitmentInstructions;
