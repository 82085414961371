import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import ResourceCard from "./resourcecard";
import "../font.css";
import { withAllContexts, withHoverDrawer } from "../../HOCs";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import GeneralAppointmentCard from "./generalappointmentcard";
import SpecialAppointmentCard from "./specialappointmentcard";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
//import Snackbar from "@material-ui/core/Snackbar";
//import MuiAlert from "@material-ui/lab/Alert";
import { AlertProps, AxiosCall, extractIdAndValue, valitationFunc } from "../../utils";
import { Grid } from "@material-ui/core";
import {CreateRule } from 'rulebuilder-v1'
import Config from "../../config";
import EditIcon from "@material-ui/icons/CreateOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
}));

const errorlist = ["entityType", "ApptTypeCode", "organization_id", "generalInstruction"];
const CreateAppoointment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState({
    key: "",
    _id: "",
    status: "true",
    organization_id: {},
    ApptTypeCode: {},
    entityType: {},
    HealthcareServiceCriteria: "",
    PatientInclusionCriteria: "",
    // dataArr
    ApptInstructions: [],
    TotNoOfResourcesRequired: [],
    generalInstruction: [],
    // EditArr
    ApptInstructionsEditArr: [],
    TotNoOfResourcesRequiredEditArr: [],
    generalInstructionEditArr: [],
    // editIndex
    editIndex: null,
    error:{}
  });
  const backfun = () => {
    history.push({pathname: routes.availabilitymanagement, state:{index: 2}});
  };
  const [RuleModalOpen,setRuleModalOpen] = useState({
    isSetupResouce:false,
    isIncCriteria: false,
  })
  const [options,setOptions] = useState();
  const [saveRuleHealthcare,setsaveRuleHealthcare] = useState();
  const [saveRulePatient,setsaveRulePatient] = useState();
  const [entityName,setEntityName] = useState();
  const [isSaving,setisSaving] = useState();

  React.useEffect(
    (props) => {
      if (history?.location?.state?.editVal?._id) {
        let getState = history?.location?.state?.editVal
        let generalInstructionArr = []
        getState?.generalinstruction?.map((v,i)=>{
          let obj = {
            fcode: {label:v?.fcode?.InstructionCode,_id:v?.fcode?._id,value:v?.fcode?.InstructionDesc},
            description: v?.description	?? v?.fcode?.InstructionDesc ?? "",
          }
          generalInstructionArr.push(obj)
        })
        let appInstructionArr = []
        getState?.apptinstructions?.map((v,i)=>{
          let obj = {
            AppInstructionCriteria: {label:v?.AppInstructionCriteria?.display,_id:v?.AppInstructionCriteria?._id,value:v?.AppInstructionCriteria?.display},
            AppInstructions: v?.AppInstructions,
            Appcode: {label:v?.Appcode?.display,_id:v?.Appcode?._id,value:v?.AppInstructions},
          }
          appInstructionArr.push(obj)
        })
        let resInstructionArr = []
        getState?.totnoofresourcesrequired?.map((v,i)=>{
          let obj = {
            SeqNumber: v?.SeqNumber ?? 0,
            ResourceType: {label:v?.ResourceType?.display,value:v?.ResourceType?.display,_id:v?.ResourceType?._id},
            ResourceRole: {label:v?.ResourceRole?.display,value:v?.ResourceRole?.display,_id:v?.ResourceRole?._id},
            ResourceInclusionCriteria: v?.ResourceInclusionCriteria?._id === null ? "" : v?.ResourceInclusionCriteria,
            Duration: v?.Duration ?? 0,
            DurnUOM: v?.DurnUOM	?? "",
            RequiredYN: v?.RequiredYN ?? true,
          }
          resInstructionArr.push(obj)
        })
        setState({
          ...state,
          organization_id: getState?.organization_id
            ? {label:getState?.organization_id?.name,value:getState?.organization_id?.name,_id:getState?.organization_id?._id}
            : "",
          status: getState?.status ? getState?.status : "",
          ApptTypeCode: getState?.ApptTypeCode 
            ? {label:getState?.ApptTypeCode?.display,value:getState?.ApptTypeCode?.display,_id:getState?.ApptTypeCode?._id}
            : "",
          entityType: getState?.entityType 
            ? {label:getState?.entityType?.display,value:getState?.entityType?.display,_id:getState?.entityType?._id}
            : "",
          key: getState?._key ? getState?._key : "",
          _id: getState?._id ? getState?._id : "",
          TotNoOfResourcesRequired:
            getState?.totnoofresourcesrequired?.length > 0
              ? resInstructionArr
              : [],
          generalInstruction:
            getState?.generalinstruction?.length > 0
              ? generalInstructionArr
              : [],
          ApptInstructions:
            getState?.apptinstructions?.length > 0
              ? appInstructionArr
              : [],
        });
        setsaveRuleHealthcare(getState?.healthcareservicecriteria)
        setsaveRulePatient(getState?.patientInclusioncriteria)
        getFacililityById(getState?.entityType?._id)
      }
    },
    [history?.location?.state?.editVal]
  );

  const getOrgTypeAndAppType = async () => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            filter: {
            type: [
                "PRACTROLE",
                "RESOURCETYPE",
                "HEALTHISSUES",
                "APPTYPE",
                "ORGTYPE"
            ],
            tenantid: process.env.REACT_APP_TENANTID,
            facilityid: process.env.REACT_APP_FACILITY_ID,
            lang: "en"
            },
            queryid: process.env.REACT_APP_QUERY_GENERAL_MASTER
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
          setOptions(Resp);
        }
    } catch(e) {
        console.error(e)
    }
  }
  React.useEffect(()=>{
    getOrgTypeAndAppType()
  },[])

  const getFacililityById = async (id) => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            filter: {
              orgtype: id
            },
            queryid: process.env.REACT_APP_GET_FACILITY
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        let result = []
        if (Array.isArray(Resp)) {
            Resp?.map((v,i)=>{
                let obj = {
                    _id:v?._id,
                    label:v?.name,
                    value:v?.name
                }
                result.push(obj)
            })
            setEntityName(result);
        }
    } catch (e) {
        console.error(e)
    }
  }

  const onChangeAll = async (e, val, types) => {
   
    if (state.error[types]) {
      state.error[types]=false
    }
    if(types === "entityType"){
      getFacililityById(val?._id)
    }
    if (
      types === "TotNoOfResourcesRequired" ||
      types === "generalInstruction" ||
      types === "ApptInstructions"
    ) {
      if (state.editIndex || state.editIndex === 0) {
        state[types][state.editIndex] = val;
        state.editIndex = null;
        state[types + "EditArr"] = [];
      } else {
        state[types].push(val);
      }
      setState({ ...state });
    } else {
      setState({ ...state, [types]: val });
    }
  };

  const deleteFun = (types, index) => {
    let filtered = state[types].filter(function (value, i, arr) {
      return i !== index;
    });
    state[types] = filtered;
    setState({ ...state });
  };

  const editFun = (types, index) => {
    let filtered = state[types].filter(function (value, i, arr) {
      return i === index;
    });
    state[types + "EditArr"] = filtered;
    state.editIndex = index;
    setState({ ...state });
  };
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setAlertState(false);
  // };
  const saveFuntion = async () => {
    let { error, errorState } = await valitationFunc(state, errorlist);
    if (!error) {
      setisSaving(true)
      const TotNoOfResourcesRequiredarr = state?.TotNoOfResourcesRequired?.map(
        (v, i) => {
          return {
            SeqNumber: i,
            ResourceType: v?.ResourceType?._id ?? "",
            ResourceRole: v?.ResourceRole?._id ?? "",
            ResourceInclusionCriteria: v?.ResourceInclusionCriteria?._id ?? "",
            Duration: parseInt(v?.Duration),
            DurnUOM: v?.DurnUOM,
            RequiredYN: true,
          };
        }
      );
      const generalInstructionarr = state?.generalInstruction?.map((v, i) => {
        return {
          fcode: history?.location?.state?.editVal?._id ? v?.fcode?._id ? v?.fcode?._id : v?._id : v?._id,
          description: v?.description,
        };
      });
      const ApptInstructionsarr = state?.ApptInstructions?.map((v, i) => {
        // ApptInstructionsarr save
        return {
          AppInstructionCriteria: v?.AppInstructionCriteria?._id,
          AppInstructions: v?.AppInstructions,
          Appcode: history?.location?.state?.editVal?._id ? v?.Appcode?._id  ? v?.Appcode?._id : v?._id : v?._id,
        };
      });
      let filter = {};
      if (history?.location?.state?.editVal?._id) {
        filter = { filter: { _id: history?.location?.state?.editVal?._id ?? "" } };
      }
      let payload = [
        {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          entity: Config.ResourcebyApptTypeEntity,
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
          ...filter,
          doc: {
            id: 0,
            organization_id: state.organization_id._id,
            status: true,
            ApptTypeCode: state.ApptTypeCode._id,
            healthcareservicecriteria: saveRuleHealthcare?._id ?? "",
            patientInclusioncriteria: saveRulePatient?._id ?? "",
            apptinstructions: ApptInstructionsarr,
            totnoofresourcesrequired: TotNoOfResourcesRequiredarr,
            entitytype: state.entityType._id,
            generalinstruction: generalInstructionarr,
          },
        },
      ];
      let Resp = await AxiosCall(payload,process.env.REACT_APP_UPSERT_DOC)
      if(Resp?.Code	=== 201){
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: `"Resource by Appointment Type ${history?.location?.state?.editVal?._id ? "Updated" : "Created" } Successfully!"`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
         });
        backfun();
      } else {
        props.alert.setSnack("error");
      }
      setisSaving(false)
  
      // await dispatch(
      //   actions.RES_UPSERT_INSER_NEW({
      //     state,
      //     key: history?.location?.state?.key
      //       ? history?.location?.state?.key
      //       : "",
      //   })
      // );
    } else {
      setisSaving(false)
      // setAlertState(true);
      if(!errorState?.ApptTypeCode && !errorState?.entityType && !errorState?.organization_id && errorState?.generalInstruction){
        props.alert.setSnack({
         open: true,
         severity: AlertProps.severity.error,
         msg: "GENERAL APPOINTMENT INSTRUCTIONS is Mandatory Fields!",
         vertical: AlertProps.vertical.top,
         horizontal: AlertProps.horizontal.right,
         tone: true,
        });
      } else {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the Mandatory Fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
         });
      }
       setState({
         ...state,
         error: errorState,
       });
    }
  };
  return (
    <div>
      {/* <Snackbar
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert severity="error">Fill all Mandatory Fields</Alert>
      </Snackbar> */}
      <div style={{ backgroundColor: "#F6F6F6" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: "8px 16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={backfun}
              style={{
                backgroundColor: "#DCDCDC",
                width: 28,
                height: 28,
                marginRight: 12,
              }}
            >
              <img
                src={backarrow}
                alt="icon"
                style={{ transform: "rotate(90deg)" }}
              />
            </IconButton>
            <p> {history?.location?.state?.key ? "Edit Appointment type" : "Create New Appointment type"}</p>
          </div>
        </div>
        <hr style={{ marginTop: "0px" }} />
      </div>
      <Card
        style={{
          padding: "16px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "16px",
          borderRadius: 6,
        }}
      >
        <div style={{ marginBottom: 12 }}>
          <span
            style={{
              color: "#101010",
              fontSize: 12,
              fontFamily: "poppinsemibold",
            }}
          >
            ORGANIZATION
          </span>
        </div>
        <div style={{ display: "flex" }}>

          <div style={{ marginRight: "2%" }}>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Entity Type <span className={classes.req_red}>*</span>
            </span>
            <Autocomplete
              id="combo-box-demo"
              options={extractIdAndValue("ORGTYPE",options)}
              getOptionLabel={(option) => option.label}
              style={{ width: 400, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField {...params} error={state?.error?.entityType} variant="outlined" />
              )}
              onChange={(e, values) => onChangeAll(e, values, "entityType")}
              value={state.entityType}
            />
          </div>
          <div>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Entity Name <span className={classes.req_red}>*</span>
            </span>
            <Autocomplete
              id="combo-box-demo"
              // options={
              //   resourceAppointment?.resource_enitity_name?.data
              //     ? resourceAppointment.resource_enitity_name.data
              //     : []
              // }
              options={entityName ?? []}
              getOptionLabel={(option) => option.label}
              style={{ width: 600, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField {...params} error={state?.error?.organization_id}  variant="outlined" />
              )}
              onChange={(e, values) =>
                onChangeAll(e, values, "organization_id")
              }
              value={state.organization_id}
            />
          </div>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1%",
          marginLeft: "2%",
        }}
      >
        <p style={{ color: "#6F6F6F", fontSize: 12 }}>
          APPOINTMENT TYPE <span className={classes.req_red}> *</span>
        </p>
        <Autocomplete
          id="combo-box-demo"
          options={extractIdAndValue("APPTYPE",options)}
          getOptionLabel={(option) => option.label}
          style={{ width: 300, marginLeft: "1%", backgroundColor: "white" }}
          size={"small"}
          renderInput={(params) => <TextField {...params} error={state?.error?.ApptTypeCode} variant="outlined" />}
          onChange={(e, values) => onChangeAll(e, values, "ApptTypeCode")}
          value={state.ApptTypeCode}
        />
      </div>
      <Card
        style={{
          padding: "16px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "16px",
          borderRadius: 6,
        }}
      >
        <p style={{ fontFamily: "poppinsemibold" }}>
          SETUP RESOURCE REQUIRED BY APPOINTMENT TYPE
        </p>
        {saveRuleHealthcare?._id ? (
              <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {1}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%" }}>
                      <div>{"HEALTHCARE SERVICE CRITERIA"}</div>
                      <div>
                        {"Rule Name : "+saveRuleHealthcare?.ruleName} <div className={classes.dot} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        style={{ color: "#0071F2", fontSize: 18 }}
                        onClick={() => {
                          setRuleModalOpen({...RuleModalOpen, isSetupResouce : true,isIncCriteria: false})
                        }}
                      />
                    </IconButton>
                    {/* <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <DeleteIcon
                        onClick={() =>
                          props.deleteFun("TotNoOfResourcesRequired", i)
                        }
                        style={{ color: "#FF4D4A", fontSize: 18 }}
                      />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>

        ) : (
          <Card style={{ padding: 16, paddingTop: 0 }} elevation="2">
            <p style={{ fontFamily: "poppinsemibold" }}>
              HEALTHCARE SERVICE CRITERIA
            </p>
            <Button variant="outlined" color="secondary" onClick={()=>setRuleModalOpen({...RuleModalOpen, isSetupResouce : true,isIncCriteria: false})}>
              + Add New Rule
            </Button>
          </Card>
        )}
        {saveRulePatient?._id ? (
          <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {2}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%" }}>
                      <div>{"PATIENT INCLUSION CRITERIA"}</div>
                      <div>
                        {"Rule Name : "+saveRulePatient?.ruleName} <div className={classes.dot} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        style={{ color: "#0071F2", fontSize: 18 }}
                        onClick={() => {
                          setRuleModalOpen({...RuleModalOpen, isSetupResouce : false,isIncCriteria: true})
                        }}
                      />
                    </IconButton>
                    {/* <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <DeleteIcon
                        onClick={() =>
                          props.deleteFun("TotNoOfResourcesRequired", i)
                        }
                        style={{ color: "#FF4D4A", fontSize: 18 }}
                      />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
        ) : (
          <Card
            style={{ padding: 16, paddingTop: 0, marginTop: "1%" }}
            elevation="2"
          >
            <p style={{ fontFamily: "poppinsemibold" }}>
              PATIENT INCLUSION CRITERIA
            </p>
            <Button variant="outlined" color="secondary" onClick={()=>setRuleModalOpen({...RuleModalOpen,isIncCriteria: true})}>
              + Add New Rule
            </Button>
          </Card>
        )}
      </Card>
      <div style={{ marginTop: "16px" }}>
        <ResourceCard
          data={state.TotNoOfResourcesRequired}
          editData={state.TotNoOfResourcesRequiredEditArr}
          onChangeAll={onChangeAll}
          deleteFun={deleteFun}
          editFun={editFun}
          options={options}
          alert={props.alert}
        />
      </div>
      <GeneralAppointmentCard
        data={state.generalInstruction}
        onChangeAll={onChangeAll}
        deleteFun={deleteFun}
        editFun={editFun}
        editData={state.generalInstructionEditArr}
        required={true}
        alert={props?.alert}
      />
      <SpecialAppointmentCard
        data={state.ApptInstructions}
        onChangeAll={onChangeAll}
        deleteFun={deleteFun}
        editFun={editFun}
        editData={state.ApptInstructionsEditArr}
        options={options}
        alert={props?.alert}
      />
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          float: "right",
          marginRight: "4%",
          paddingBottom: "30px",
        }}
      >
        <Button
          // variant="contained"
          variant="outlined"
          size="medium"
          style={{ marginRight: "14px" }}
          onClick={() => backfun()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={isSaving ? null : saveFuntion}
        >
          {/* {history?.location?.state?.key ? "Edit" : "Save"} */}
          {history?.location?.state?.editVal?._id ? isSaving ? "Updating..." : "Update" : isSaving  ? "Saving..." :"Save"}
        </Button>
      </div>
      
        {RuleModalOpen?.isIncCriteria && (
          <div >
          <CreateRule 
              alert={props.alert}
              savedRuleData={(data) => {
                  setsaveRulePatient(data);
                  setRuleModalOpen({...RuleModalOpen, isSetupResouce : false, isIncCriteria: false})
              }}
              handleClose={()=>setRuleModalOpen({...RuleModalOpen, isSetupResouce : false, isIncCriteria: false})} 
              open={RuleModalOpen?.isIncCriteria} 
              dbName={process.env.REACT_APP_DATABASE_NAME}
              factId={"factsdefinition/10026"}
              ruleId={saveRulePatient?._id}
              metadataId={ "88b23ef1-7199-4503-b631-2de82ace6e03"}
              metadata_dbname={"ATP_Metadata_Dev"}
              rulesParams={{
                  tenantId: process.env.REACT_APP_TENANTID,
                  facilityId: process.env.REACT_APP_FACILITY_ID,
              }}
          />
          </div>
        )}
        {RuleModalOpen?.isSetupResouce && (
          <div >
          <CreateRule 
              alert={props?.alert}
              savedRuleData={(data) => {
                  setsaveRuleHealthcare(data);
                  setRuleModalOpen({...RuleModalOpen, isSetupResouce : false, isIncCriteria: false})
              }}
              handleClose={()=>setRuleModalOpen({...RuleModalOpen, isSetupResouce : false, isIncCriteria: false})} 
              open={RuleModalOpen?.isSetupResouce} 
              dbName={process.env.REACT_APP_DATABASE_NAME}
              metadataId={ "88b23ef1-7199-4503-b631-2de82ace6e03"}
              metadata_dbname={"ATP_Metadata_Dev"}
              factId={"factsdefinition/10026"}
              ruleId={saveRuleHealthcare?._id}
              rulesParams={{
                  tenantId: process.env.REACT_APP_TENANTID,
                  facilityId: process.env.REACT_APP_FACILITY_ID,
              }}
          />
          </div>
        )}
    </div>
  );
};
export default withHoverDrawer(withAllContexts(CreateAppoointment));
