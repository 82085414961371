import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import { CommonTable, SearchWithFilter } from "../../components";
import { Row } from "qdm-component-library";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";
import { AlertProps, AxiosCall, extractIdAndValue } from "../../utils";
import Config from "../../config";

const useStyles = makeStyles((theme) => ({
  boxsize: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
      width: "150px",
    },
  },
  filterselectDiv: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    marginRight: "1%",
    border: "1px solid #E0E0E0",
  },
  filterselect: {
    backgroundColor: "white",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const ResourcebyTypeTable = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { parent_id } = props;

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [options,setOptions] = useState();
  const [resource, setresource] = React.useState();
  const [ResTable, setResTable] = React.useState();

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
  };

  const handleDrawerOpen = () => {
    history.push(routes.createappointment);
  };
  const dispatch = useDispatch();

  const statusChange = async (e, values) => {
    let id = values.allData._key;
    let checked = e.target.checked;
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Status Updating",
    });
    let payload = [
      {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        entity: Config.ResourcebyApptTypeEntity,
        is_metadata: true,
        metadataId: process.env.REACT_APP_METADATAID,
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
        filter: {
          _key: id,
        },
        doc: {
          status: checked,
        },
      },
    ]
    let Resp = await AxiosCall(payload,process.env.REACT_APP_UPSERT_DOC)
    if(Resp?.Code	=== 201){
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Status Changed Successfully!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
       });
    } else {
      props.alert.setSnack("error");
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  const handleEdit = (e, values, index) => {
    let ResourceDataObj = ResTable
    ResourceDataObj = ResourceDataObj[index]
    history.push({
      pathname: routes.createappointment,
      state: {
        editVal: ResourceDataObj,
      },
    });
  };
  const entityFunCall = async (e, values, swis) => {
    if (swis === "type") {
      if (values?.label) {
        getFacililityById(values?._id)
        getResourceList(values?._id,"")
      }
    } else if (swis === "name") {
      if (values?.label) {
        getResourceList("",values?._id)
       
      }
    }
  };

  React.useEffect(()=>{
    getOrgTypeAndAppType();
  },[])

  React.useEffect(()=>{
    getResourceList();
  },[page,perPage])

  const onSearchChange = (value = "") => {
    // if (value?.length > 1) {
    //   dispatch(
    //     actions.RESOURCE_BY_TABLE_DATA({
    //       search: value.trim(),
    //       page: page,
    //       perPage: perPage,
    //     })
    //   );
    // } else if (!value) {
    //   dispatch(
    //     actions.RESOURCE_BY_TABLE_DATA({
    //       page: page,
    //       perPage: perPage,
    //     })
    //   );
    // }
  };
  const getOrgTypeAndAppType = async () => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            filter: {
            type: [
                "ORGTYPE"
            ],
            tenantid: process.env.REACT_APP_TENANTID,
            facilityid: process.env.REACT_APP_FACILITY_ID,
            lang: "en"
            },
            queryid: process.env.REACT_APP_QUERY_GENERAL_MASTER
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
          setOptions(Resp);
        }
    } catch(e) {
        console.error(e)
    }
  }
  const getFacililityById = async (id) => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            filter: {
              orgtype: id
            },
            queryid: process.env.REACT_APP_GET_FACILITY
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        let result = []
        if (Array.isArray(Resp)) {
            Resp?.map((v,i)=>{
                let obj = {
                    _id:v?._id,
                    label:v?.name,
                    value:v?.name
                }
                result.push(obj)
            })
            setresource(result);
        }
    } catch (e) {
        console.error(e)
    }
  }
  const getResourceList = async (type,name) => {
    try {
        let payload = {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          filter: {
            entitytype: type ?? "",
            entityname: name ?? "",
            page: page,
            perPage: perPage
          },
          queryid: Config.getResourseTableList
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
          setResTable(Resp);
        }
    } catch(e) {
        console.error(e)
    }
  }

  return (
    <div id={`${parent_id}-parent-div`}>
      <div id={`${parent_id}-parent-sub-div`} style={{ marginBottom: 14 }}>
        <Row
          id={`${parent_id}-parent-row`}
          style={{ justifyContent: "flex-end" }}
        >
          <div
            id={`${parent_id}-Entity-Type-div`}
            className={classes.filterselectDiv}
          >
            <span
              id={`${parent_id}-Entity-Type-span`}
              style={{ paddingLeft: 12, color: "#6F6F6F" }}
            >
              Entity Type :
            </span>
            <Autocomplete
              id={`${parent_id}-Entity-Type-autocomplete`}
              options={
               extractIdAndValue("ORGTYPE",options)
              }
              getOptionLabel={(option) => option.label}
              style={{ width: 200, border: "none" }}
              size={"small"}
              className={classes.filterselect}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(e, values) => entityFunCall(e, values, "type")}
            />
          </div>
          <div
            id={`${parent_id}-Entity-name-div`}
            className={classes.filterselectDiv}
          >
            <span
              id={`${parent_id}-Entity-name-span`}
              style={{ paddingLeft: 12, color: "#6F6F6F" }}
            >
              Entity Name :
            </span>
            <Autocomplete
              id={`${parent_id}-Entity-name-autocomplete`}
              options={
                resource ?? []
              }
              getOptionLabel={(option) => option.label}
              style={{ width: 400, border: "none" }}
              size={"small"}
              className={classes.filterselect}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(e, values) => entityFunCall(e, values, "name")}
            />
          </div>
        </Row>
      </div>
      <Card
        id={`${parent_id}-Entity-name-card`}
        style={{ borderRadius: 10, paddingnBottom: "2%" }}
      >
        <div
          id={`${parent_id}-Add-New-div`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1%",
          }}
        >
          <div
            id={`${parent_id}-${props.header.replaceAll(" ", "-")}-div`}
            style={{ marginLeft: "18px" }}
          >
            <p
              id={`${parent_id}-${props.header.replaceAll(" ", "-")}-p`}
              style={{ fontFamily: "poppinsemibold" }}
            >
              {props.header}
            </p>
          </div>
          <div
            id={`${parent_id}-Add-New-button-div`}
            style={{ marginRight: "18px", display: "flex" }}
          >
            <SearchWithFilter
              id="appointment"
              placeholder="Search code and Description!"
              //  loading={data?.length > 0 ? loading : false}
              onSearchChange={onSearchChange}
              hideSearchBar={true}
              hideFilter={true}
              //  onFilterClicked={onFilterClicked}
              //  isFilterApplied={props?.isFilterApplied}
            />
            <Button
              id={`${parent_id}-Add-New-button`}
              variant="contained"
              color="primary"
              onClick={handleDrawerOpen}
              size="medium"
              style={{ height: "35px" }}
            >
              + Add New
            </Button>
          </div>
        </div>
        <div
          id={`${parent_id}-CommonTable-div`}
          style={{
            padding: 20,
            height: "61vh",
            overflow: "auto",
            paddingTop: 0,
          }}
        >
          <CommonTable
            parent_id={"resourceappointment"}
            Header={[
              "S.No",
              "Code",
              "Description",
              "Resources",
              "Status",
              "Action",
            ]}
            dataList={
              ResTable ?? []
            }
            tableData={[
              { type: ["INCRIMENT"], name: "" },
              { type: ["TEXT"], name: "code" },
              { type: ["TEXT"], name: "display" },
              { type: ["TEXT"], name: "resources" },
              { type: ["CHECKBOX"], name: "status" },
              { type: ["EDIT"], name: "action" },
            ]}
            handleCheckBox={statusChange}
            handleEdit={handleEdit}
            handlepageChange={(currentPage, PerPage) =>
              handlepageChange(currentPage, PerPage)
            }
            TableCount={
              ResTable?.[0]
                ?.TotalCount
            }
            incrementCount={page}
            showPagination={true}
            rowsPerPageOptions={[
              { label: "10 Rows", value: 10 },
              { label: "50 Rows", value: 50 },
              { label: "100 Rows", value: 100 },
            ]}
          />
        </div>
      </Card>
    </div>
  );
};
export default withAllContexts(ResourcebyTypeTable);
