import { Dialog, withStyles } from "@material-ui/core";
import React from "react";
import { VerticalList } from "../../../components";
import GeneralMasterForm from "./generalMasterForm";
import TableFormData from "./tableFormData"
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";

const useStyles = (theme) => ({
  paper: { minWidth: "800px" },
});

class GeneralMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
      formOpen: false,
      formData: [],
      search:"",
      listSearch: '',
      perPage : 10,
      page: 0,
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  getlistData = async () => {
    // GENERAL_MASTERS_READ
    await this.props.COLUMN_TYPE_MASTERS();
    let dataslist = await this.props.GENERAL_MASTERS_READ();
    this.state.formData = dataslist.payload.data??[];
    this.setState({ ...this.state });
  }
  componentDidMount(){
    this.getlistData()
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false });
  };
  openForm = (val) => {
    // console.log("val", val);
    this.setState({ ...this.state, formOpen: true, editeList: null });
  };
  handleGeneralMasterFormClose = async (type) => {
    if(type === "reload"){
      await this.setState({ ...this.state, formOpen: false, editeList: null });
      await this.getlistData()
    }else{
      await this.setState({ ...this.state, formOpen: false, editeList: null });
    }
  };
  handleGeneralMasterForm = (data) => {
    let state = this.state;
    state.formData.push(data);
    this.setState({ ...state });
  };

  editListItem = (data) => {
    if(data?.name){
      let values = this.state?.formData.filter(val => val?.name === data?.name)[0];
      this.setState({ ...this.state, formOpen: true, editeList: values });
    }
  }

  handleSearch = async(value = "", title) => {
    // console.log(value,"value",title,"title")
    if (value?.length > 0) {
      await this.props.GET_GENERAL_MASTER_TYPE({
        type: title,
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim()
      });
    } else if (!value) {
     await this.props.GET_GENERAL_MASTER_TYPE({
      type: title,
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });
  }

  handleListSearch = async (value) => {
    console.clear()
    console.log(value)
    let dataslist = await this.props.GENERAL_MASTERS_READ({search: value});
    this.state.formData = dataslist.payload.data??[];
    this.setState({ ...this.state, listSearch: value });
  }

  


  render() {
    const { open, openIndex } = this.state;
    // console.log("data recieved", this.state);
    let { classes } = this.props;

    // console.log(JSON.stringify(this.props.general_master_list))

    return (
      <React.Fragment>
        <VerticalList
          showSearch={true}
          in_styles={{overflow: "auto", height: "73vh", width: "100%"}}
          searchText={this.state.listSearch}
          handleListSearch={(value) => this.handleListSearch(value)}
          parent_id={"general_master"}
          isLeftList={true}
          addFunc={this.handleAddBtn}
          changeTab={this.changeTab}
          editListItem={this.editListItem}
          editList={true}
          openForm={this.openForm}
          addNew={true}
          emptyData={this.state?.formData?.length === 0 ? true : false}
          // searchCmpProps starts here
          id={"timetable"}
          // placeholder={"search code & Description"}
          // loading={this.props?.searchLoading}
          onSearchChange={this?.handleSearch}
          search={this.state.search}
          hideSearchBar={true}
          hideFilter={true}
          // handleSort ={this.handleSort}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={
            // this.props.general_master_list?.loading ? "loading..." :
              this.state?.formData?.map((val,i)=>{
                  return{
                      name:val?.name,
                      component: <TableFormData 
                        changeState={this.changeState}
                        disableList={["Code"]}
                        uppercaseList={["Code"]}
                        open={open}
                        openIndex={openIndex} 
                        parent_id={val?.name}
                        data={val}
                      />
                  }
              })
          }
        />
        <Dialog
          open={this.state.formOpen}
          onClose={this.handleFormClose}
          classes={{ paper: classes.paper }}
        >
          <GeneralMasterForm
            editeList={this.state?.editeList??null}
            handleFormClose={this.handleGeneralMasterFormClose}
            handleFormData={this.handleGeneralMasterForm}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  general_master_list: state?.generalMasterSlice?.generalMastersRead
});

export default connect(
  mapStateToProps,
  actions
)(withStyles(useStyles)(GeneralMaster));