
import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import {
  TopNavBar,
  HoverNavBar,
  CommonTabList,
  OverlayCompt,
  CommonTable,
  VerticalList,
} from "../../../components";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EventForm from "./eventConfigForm";
import { Div } from "qdm-component-library";
import { useEffect } from "react";
import { AlertContext, BackdropContext } from "../../../contexts";
import { useContext } from "react";
import ConfirmationDialog from "../../../screens/availabilityManagement/timeTableManagement/TimeTableManagementForm/confirmationDialog";
import { AlertProps, EventEntity, searchCatalog } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 56,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    marginTop: "60px",
    marginLeft: "10px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
  root2: {
    height: "90%",
    backgroundColor: "white",
    padding: 12,
    border: "1px solid #ddd",
    borderRadius: 12,
    margin: 30,
  },
  titleText: {
    fontSize: 16,
    color: "#101010",
    marginLeft: 40,
    fontFamily: "pc_semibold",
  },
  inputText: {
    fontSize: 14,
    margin: "8px 0px 12px 0px",
    "& input": {
      padding: "10px 12px",
    },
    backgroundColor: "#00000008",
    border: "1px solid #1010100D",
  },
  addBtnGrid: {
    width: "18%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  TitleDiv: {
    display: "flex",
    alignItems: "center",
  },
  DivBtn: {
    display: "flex",
  },
  DivBtn1: {
    aliginItem: "center",
    justifyContent: "end",
  },
  DivBtn1Input: {
    width: "58%",
  },
  btnAdd: {
    padding: "5px 12px",
  },
  btnSearch: {
    fontSize: 21,
  },
  btInputDive: {
    justifyContent: "end",
  },
  form: {
    paddingTop: 15,
  },
}));

const EventConfig = (props) => {
  const axios = require("axios");
  const classes = useStyles({ props });
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const [eventData, seteventData] = useState([]);
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    data: "",
  });

  const [state, setState] = useState({
    openForm: false,
    editData: null,
    perPage: 10,
    page: 0,
    search: "",
    editIndex: null,
    isView: false,
  });
  const handleForm = async (type) => {
    let { openForm } = state;
    setState({
      openForm: !openForm,
      editData: null,
    });
    getEventData(state.page, state.perPage, state?.search);
  };
  const handleEdit = (e, data, index) => {
    setState({
      ...state,
      openForm: true,
      editData: data,
    });
  };
  const handleView = (e, data, index) => {
    setState({
      ...state,
      openForm: true,
      editData: data,
      isView: true,
    });
  };
  const handleSearch = async (value = "") => {
    if (value?.length > 0) {
      await getEventData(state.page, state.perPage, value.trim());
      setState({
        ...state,
        page: state.page,
        perPage: state.perPage,
        search: value.trim(),
      });
    } else if (!value) {
      getEventData()
    }
    setState({
      ...state,
      search: "",
    });
  };
  const handleDeleteData = async (data) => {
    try {
      let payload = JSON.stringify({
        db_name: process.env.REACT_APP_METADATA_DB_NAME,
        entity: EventEntity,
        filter: `event_catalog._id == '${data?._id ?? data?._id}'`,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_SOFT_DELETE,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };
  
      axios.request(config).then((response) => {
        if (response?.data?.Code === 201) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Event Catalog Record Successfully Deleted",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          getEventData(0, 10, "");
          setModalOpen({ open: false, data: "" });
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Event Catalog Record Unable Deleted",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
      });
    } catch(error) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };

  const handleDelete = async (e, data, index) => {
    setModalOpen({ open: true, data: data });
  };
  const handleModalClose = () => {
    setModalOpen({
      open: false,
      data: "",
    });
  };
  const handlepageChange = async (currentPage, PerPage) => {
    setState({
      ...state,
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
      search: state?.search,
    });

    await getEventData((currentPage - 1) * PerPage, PerPage, state?.search);
  };

  const getEventData = async (page, perPage, search) => {
    let data = JSON.stringify({
      db_name: process.env.REACT_APP_METADATA_DB_NAME,
      filter: {
        searchname: search || "",
        catalogid: "",
        page: page || 0,
        perPage: perPage || 10,
      },
      queryid: searchCatalog,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        seteventData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getEventData(state?.page, state?.perPage, state?.search);
  }, []);
  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div className={classes.topNavbar}>
        <TopNavBar />
      </div>
      {
        <div className={classes.hovarNavBar}>
          <HoverNavBar />
        </div>
      }
      {/* Content */}
      <div className={classes.content}>
        <Div style={{ display: "flex", justifyContent: "space-between" }}>
          <CommonTabList
            parent_id={"admintabpanel"}
            title="EVENT CONFIGURATION"
            list={[]}
          />
        </Div>
        <React.Fragment>
          <Grid style={{ padding: "30px" }}>
            <VerticalList
              parent_id={"timetable"}
              addFunc={() => handleForm(1)}
              id={"timetable"}
              placeholder={"Search"}
              onSearchChange={handleSearch}
              hideSearchBar={false}
              hideFilter={true}
              TabList={[
                {
                  name: "Event Configuration",
                  component: (
                    <CommonTable
                      parent_id={"timetable"}
                      rightAlign={["Status", "Action"]}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleView={handleView}
                      Header={[
                        { label: "S.No", showSort: false, showFilter: false },
                        {
                          label: "Event Code",
                          showSort: false,
                          showFilter: false,
                        },
                        {
                          label: "Event Name",
                          showSort: false,
                          showFilter: false,
                        },
                        // {
                        //   label: "Work Flow",
                        //   showSort: false,
                        //   showFilter: false,
                        // },
                        // {
                        //   label: "Speciality",
                        //   showSort: false,
                        //   showFilter: false,
                        // },
                        // {
                        //   label: "Diagnosis",
                        //   showSort: false,
                        //   showFilter: false,
                        // },
                        { label: "View", showSort: false, showFilter: false },
                        { label: "Action", showSort: false, showFilter: false },
                      ]}
                      dataList={eventData}
                      tableData={[
                        { type: ["INCRIMENT"], name: "" },
                        {
                          type: ["TEXT"],
                          name: "eventcode",
                        },
                        { type: ["TEXT"], name: "eventname" },
                        // { type: ["TEXT"], name: "workflow" },
                        {
                          type: ["VIEWICON"],
                          name: "",
                          icon: (
                            <VisibilityIcon
                              style={{
                                color: "#0071F2",
                                fontSize: 16,
                                marginRight: "5px",
                              }}
                            />
                          ),
                          label: "View",
                        },
                        { type: ["EDIT", "DELETE"], align: "right" },
                      ]}
                      handlepageChange={(currentPage, PerPage) =>
                        handlepageChange(currentPage, PerPage)
                      }
                      TableCount={eventData?.[0]?.totalcount}
                      incrementCount={state.page}
                      showPagination={true}
                      rowsPerPageOptions={[
                        { label: "10 Rows", value: 10 },
                        { label: "50 Rows", value: 50 },
                        { label: "100 Rows", value: 100 },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </Grid>

          <OverlayCompt
            parent_id={"timetable"}
            open={state?.openForm}
            children={
              <React.Fragment>
                {state?.openForm && (
                  <EventForm
                    editData={state?.editData}
                    closeForm={handleForm}
                    getEventData={getEventData}
                    isView={state?.isView}
                    eventData={eventData}
                  />
                )}
              </React.Fragment>
            }
          />
        </React.Fragment>
      </div>
      <ConfirmationDialog
        modalOpen={modalOpen}
        parent_id={"delete_time_table"}
        handleModalClose={handleModalClose}
        handleDeleteData={handleDeleteData}
      />
    </div>
  );
};

export default EventConfig;
