import React, { useContext, useState } from "react";
import { CommonTable, CommonTabList } from "../common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Col,
  Div,
  H6,
  Paper,
  Row,
  TextArea,
  TextInput,
} from "qdm-component-library";
import { SelectBox } from "../common/smartForm/component";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  AlertProps,
  AxiosCall,
  extractIdAndValue,
  factoperatorlist,
  facttypelist,
} from "../../utils";
import { AlertContext } from "../../contexts";
import Config from "../../config";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 56,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    //   marginTop:"60px",
    //   marginLeft:"10px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
}));
export const FactsForm = (props) => {
  const headerList = {
    allHeaders : [
      "S.No",
      "Collection Name",
      "FieldName",
      "Display Name",
      "Control Type",
      "Column Type",
      // "Query/GM",
      "Query ID",
      "Query Filter",
  
      !props?.isFormOpen.isView && "Action",
    ],
    schemaHeaders : [
      "S.No",
      "Collection Name",
      "FieldName",
      "Display Name",
      // "Control Type",
      "Column Type",
      // "Query/GM",
      // "Query ID",
      // "Query Filter",
  
      !props?.isFormOpen.isView && "Action",
    ]
  }
  const alert = useContext(AlertContext);
  const classes = useStyles({ props });
  const { labelStyle, borderStyle, errorBorderStyle } = styles;

  // ---------state------------

  const [addNew, setAddNew] = React.useState(false);
  const [warningAlert, setwarningAlert] = useState();
  const [state, setState] = React.useState({
    factId: "",
    factName: "",
    facts: [],
    facttype: {},
  });
  const [error, setError] = React.useState(false);
  const [facttypeError, setFacttypeError] = React.useState(false);
  const [showtypes, setshowtypes] = React.useState(false);
  const [fact, setFact] = React.useState({
    collectionName: {},
    fieldName: {},
    displayName: "",
    controlType: {},
    queryOrGm: {},
    queryId: "",
    queryFilter: "",
    columnType: "",
    operators: [],
    sequence: "",
    icon: "",
    dependent: [],
  });
  const [factEditIndex, setFactEditIndex] = React.useState(null);
  const [factError, setFactError] = React.useState(false);
  const [collectionList, setCollectionList] = React.useState([]);
  const [generalMaster, setGeneralMaster] = React.useState([]);
  const [fieldList, setFieldList] = React.useState([]);
  const [operator, setOperator] = useState([]);
  // const [factTypeshow, setFactTypeshow] = useState([]);

  // ----------events-----------

  const handleFormState = (key, value) => {
    if(key === "facttype"){
      if(state?.facts?.length > 0){
        setwarningAlert(true);
        return;
      }
    }
    setState({
      ...state,
      [key]: value,
    });
  };
  const handleChange = (key, value) => {
    if (key == "fieldName") {
      setFact({
        ...fact,
        [key]: value,
        columnType: value?.type ? value?.type : "",
      });
    } else if (key == "collectionName") {
      setFact({
        ...fact,
        [key]: value,
        dependent: [],
        fieldName: {},
        columnType: "",
      });
    } else {
      setFact({
        ...fact,
        [key]: value,
      });
    }
  };

  const clearAllNotificationTypes = () =>{
    setState({
        ...state,
        facts:[],
    })
    setFact({
      collectionName: {},
      fieldName: {},
      displayName: "",
      controlType: {},
      queryOrGm: {},
      queryId: "",
      queryFilter: "",
      columnType: "",
      operators: [],
      sequence: "",
      icon: "",
      dependent: [],
    })
    setwarningAlert(false)
}

  const setaddfunction = () => {
    setFacttypeError(true);
    if (!state?.facttype?.value) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select the fact type field",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }
    setAddNew(true);
    clearFacts();
  };

  const getOperators = async (id) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        queryid: factoperatorlist,
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        let result = [];
        if (Array.isArray(Resp)) {
          Resp?.map((v, i) => {
            let obj = {
              _id: v?._id,
              label: v?.label,
              value: v?.value,
            };
            result.push(obj);
          });

          setOperator(result);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getCollectionName = async () => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        docType: "DOCUMENT",
        excludeDefault: false,
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
      };
      let Resp = await AxiosCall(
        payload,
        process.env.REACT_APP_GET_COLLECTION_LIST
      );
      if (Resp) {
        const list = [];
        Resp?.Result?.map((v, i) => {
          let obj = {
            _id: v?._id,
            label: v?.name,
            value: v?.name,
            type: v?.type,
          };
          list.push(obj);
        });
        setCollectionList(list);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getGeneralMaster = async () => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
          type: ["RULECONTROLTYPE", "RULEQUERYORGM","FACTTYPE"],
          tenantid: process.env.REACT_APP_TENANTID,
          facilityid: "",
          lang: "en",
        },
        queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        setGeneralMaster(Resp);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getFieldName = async (collectionName) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        entity: [collectionName],
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
      };
      let Resp = await AxiosCall(
        payload,
        process.env.REACT_APP_GET_COLLECTION_ATTRIBUTES
      );
      if (Resp) {
        const list = [];

        Resp?.Result?.[0]?.[collectionName]?.map((v, i) => {
          let obj = {
            _id: v?.columnName,
            label: v?.columnName,
            value: v?.columnName,
            type: v?.columnType,
          };
          list.push(obj);
        });
        setFieldList(list);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveFacts = () => {
    setFactError(true);
    if (
      !fact?.collectionName?.value ||
      !fact?.fieldName?.value ||
      !fact?.displayName ||
      !fact?.columnType ||
      // !fact?.queryOrGm?.value ||
      // !fact?.queryId ||
      (state?.facttype?.value === "Schema" ? false : !fact?.controlType?.value ) ||
      (state?.facttype?.value === "Schema" ? false : !fact.operators.length > 0)
    ) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }
    // validation for query filter object
    if (fact?.queryFilter) {
      try {
      let stringToObj = JSON.parse(fact?.queryFilter);
      if (Array.isArray(stringToObj)) {
        let ischeck = false
        for (const item of stringToObj) {
          if (typeof item !== "object" || item === null || Array.isArray(item) || Object.keys(item).length === 0) {
            ischeck = true;
          }
        }
        if(ischeck){
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please enter a valid query filter!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        }
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter a valid query filter!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }

        // if (Array.isArray(stringToObj)) {
        //   if (Object.keys(stringToObj).length < 1) {
        //     alert?.setSnack({
        //       open: true,
        //       severity: AlertProps.severity.error,
        //       msg: "Object can't be empty!",
        //       vertical: AlertProps.vertical.top,
        //       horizontal: AlertProps.horizontal.right,
        //       tone: true,
        //     });
        //     return;
        //   }
        // } else {
        //   alert?.setSnack({
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Please enter a valid query filter!",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.right,
        //     tone: true,
        //   });
        //   return;
        // }
      } catch (e) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter a valid query filter!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
    }
    setFactError(false);
    if (factEditIndex == null) {
      let list = [...state?.facts];
      list.push(fact);
      handleFormState("facts", list);
    } else {
      let list = [...state?.facts];
      list[factEditIndex] = fact;
      handleFormState("facts", list);
    }
    clearFacts();
    setAddNew(false);
    setFactEditIndex(null);
  };
  const clearFacts = () => {
    setFactError(false);
    setFact({
      collectionName: {},
      fieldName: {},
      displayName: "",
      controlType: {},
      queryOrGm: {},
      queryId: "",
      queryFilter: "",
      columnType: "",
      operators: [],
      sequence: "",
      icon: "",
      dependent: [],
    });
  };

  const handleSubmit = async () => {
    setError(true);
    if (
      !state?.factName ||
      !state?.facttype?.value ||
      !state?.facts?.length > 0
    ) {
      if (!state?.factName || !state?.facttype?.value) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill at leat one fact!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      setError(false);
      let constructFacts = [];
      state?.facts?.map((v, i) => {
        let operatorList = []
        v?.operators?.map((val,i)=>{
          operatorList?.push(val?._id)
        })
        let obj = {
          collectionname: v?.collectionName?.value,
          fieldname: v?.fieldName?.value,
          displayname: v?.displayName,
          controltype: v?.controlType?._id,

          queryorgm: v?.queryOrGm?._id,
          queryid: v?.queryId,
          queryfilter: v?.queryFilter ? JSON.parse(v?.queryFilter) : null,
          columnType: v?.columnType,
          operators: operatorList ?? [],
          sequence: parseInt(v?.sequence || 0),
          icon: v?.icon,
          dependent: v?.dependent?.map((dep) => dep.value),
        };
        constructFacts.push(obj);
      });
      let payload = {
        factid: state?.factId,
        factname: state?.factName,
        facttype: state?.facttype?._id,
        facts: constructFacts,
      };
      let filter = { filter: { factid: state?.factId ? state?.factId : "" } };
      let masterPayload = [];
      let data = state?.factId
        ? {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            entity: Config.factsDefinationEntityName,
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
            ...filter,
            doc: payload,
          }
        : {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            entity: Config.factsDefinationEntityName,
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
            // ...filter,
            doc: payload,
          };
      masterPayload.push(data);
      let Resp = await AxiosCall(
        masterPayload,
        process.env.REACT_APP_UPSERT_DOC
      );
      if (Resp.Code === 201) {
        props?.getFacts();
        props?.setisFormOpen({
          isForm: false,
          isEdit: false,
          isView: false,
        });
        setState({
          factId: "",
          factName: "",
          facttype: "",
          facts: [],
        });
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: state?.factId ? "Update Successfully" : "Saved Successfully!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Failed to Save!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    }
  };

  const handleListEdit = (index) => {
    let getSingleFact = state?.facts[index];
    setAddNew(true);
    setFactEditIndex(index);
    setFact({
      collectionName: getSingleFact?.collectionName,
      fieldName: getSingleFact?.fieldName,
      displayName: getSingleFact?.displayName,
      controlType: getSingleFact?.controlType,
      queryOrGm: getSingleFact?.queryOrGm,
      queryId: getSingleFact?.queryId,
      queryFilter: getSingleFact?.queryFilter,
      operators: getSingleFact?.operators,
      columnType: getSingleFact?.columnType,
      sequence: getSingleFact?.sequence,
      icon: getSingleFact?.icon,
      dependent: getSingleFact?.dependent,
    });
  };
  const handleDelete = (e, data, index) => {
    let list = JSON.parse(JSON.stringify(state?.facts));
    list.splice(index, 1);
    setState({
      ...state,
      facts: list,
    });
  };

  const handleGoBack = () => {
    props?.getFacts();
    props?.setEditState({ edit: false, editState: {} });
    setState({
      factId: "",
      factName: "",
      facttype: "",
      facts: [],
    });
    props?.setisFormOpen({
      isForm: false,
      isEdit: false,
      isView: false,
    });
  };

  // -----------Life Cycles-----------

  React.useEffect(() => {
    getFieldName(fact?.collectionName?.value);
  }, [fact?.collectionName?._id]);

  React.useEffect(() => {
    getCollectionName();
    getGeneralMaster();
    getOperators();
  }, []);

  React.useEffect(() => {
    if (props?.editState?.edit) {
      const facts = props?.editState?.editState?.facts;
      let constructFacts = [];
    
      facts?.map((v, i) => {
        let dependentarray = [];
        if (Array.isArray(v?.dependent)) {
          v?.dependent?.map((val) => {
            let obj = {
              _id: val,
              label: val,
              value: val,
              type: val,
            };
            dependentarray?.push(obj);
          });
        }
        let operatorsArray = [];
        if (Array.isArray(v?.operators)) {
          v?.operators?.map((val) => {
            let obj = {
              _id: val?._id,
              label: val?.display,
              value: val?.display,
            };
            operatorsArray?.push(obj);
          });
        }


        let obj = {
          collectionName: {
            _id: "",
            label: v?.collectionname,
            value: v?.collectionname,
          },
          fieldName: {
            _id: "",
            label: v?.fieldname,
            value: v?.fieldname,
          },
          displayName: v?.displayname,
          controlType: {
            _id: v?.controltype?._id,
            label: v?.controltype?.display,
            value: v?.controltype?.display,
          },
          columnType: v?.columnType,
          queryOrGm: {
            _id: v?.queryorgm?._id,
            label: v?.queryorgm?.display,
            value: v?.queryorgm?.display,
          },
          queryId: v?.queryid,
          operators: operatorsArray ?? [],
          queryFilter: v?.queryfilter ? JSON.stringify(v?.queryfilter) : "",
          sequence: v?.sequence,
          icon: v?.icon,
          dependent: dependentarray,
        };
        constructFacts.push(obj);
      });
      setState({
        factId: props?.editState?.editState?.factid,
        factName: props?.editState?.editState?.factname,
        // facttype: props?.editState?.editState?.facttype,
        facttype: {
          _id: props?.editState?.editState?.facttype?._id,
          label: props?.editState?.editState?.facttype?.display,
          value: props?.editState?.editState?.facttype?.display,
        },
        facts: constructFacts,
      });
    }
  }, [props?.editState]);
  return (
    <div>
      <Div>
        <CommonTabList
          parent_id={"agerange_form"}
          backbtn
          backFun={handleGoBack}
          title={
            props?.isFormOpen?.isForm
              ? "Add New Fact"
              : props?.isFormOpen?.isEdit
              ? "Edit Fact"
              : "View Fact"
          }
          list={[]}
        />
      </Div>
      <Div className="pi-paper">
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Paper className="pi-paper">
              <Div>
                <H6 className="pi-title">{"Facts Definition"}</H6>
              </Div>
              <Row>
                <Col lg={4} inLineStyles={{ padding: 10 }}>
                  <TextInput
                    value={state?.factId}
                    onChange={(e) => handleFormState("factId", e.target.value)}
                    label="Fact ID"
                    labelStyle={labelStyle}
                    placeholder=""
                    style={borderStyle}
                    disabled
                  />
                </Col>
                <Col lg={4} inLineStyles={{ padding: 10 }}>
                  <TextInput
                    value={state?.factName}
                    onChange={(e) =>
                      handleFormState("factName", e.target.value)
                    }
                    label={
                      <>
                        Fact Name<span style={{ color: "red" }}> *</span>
                      </>
                    }
                    labelStyle={labelStyle}
                    placeholder=""
                    style={
                      error && !state?.factName ? errorBorderStyle : borderStyle
                    }
                    disabled={props?.isFormOpen?.isView}
                  />
                </Col>
                <Col lg={4} inLineStyles={{ padding: 10 }}>
                  <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Fact Type<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <SelectBox
                    // id={`${parent_id}-Workflow-selectBox`}
                    // list={factType}
                    list={extractIdAndValue("FACTTYPE", generalMaster)}
                    value={state?.facttype}
                    onchange={(e, v) => handleFormState("facttype", v)}
                    labelStyle={labelStyle}
                    placeholder=""
                    error={
                      (error && !state?.facttype.value) ||
                      (facttypeError && !state?.facttype.value)
                    }
                    disabled={props?.isFormOpen?.isEdit || props?.isFormOpen?.isView}
                    // value={state?.facttype}
                    disableClearable
                    // error={error?.workflow}
                    // value={reftype?.workflow}
                    // disabled={isView ? true : false}
                    // onchange={(e, value) => handleFormState("workflow", value)}
                    // disabled={props?.isFormOpen?.isView}
                  />
                </Col>
              </Row>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className="pi-paper">
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Div>
                    <H6 className="pi-title">{"Facts"}</H6>
                  </Div>
                </Grid>
                {!props?.isFormOpen?.isView && (
                  <Grid item>
                    <Div
                    //   onClick={() => setisNew(true)}
                    >
                      <Button
                        className={classes.addBtn}
                        onClick={() => {
                          // setAddNew(true);
                          // clearFacts();
                          setaddfunction();
                        }}
                        color="primary"
                      >
                        + Add New
                      </Button>
                    </Div>
                  </Grid>
                )}
              </Grid>

              {/**Rights Access Grid view */}
              <Grid container style={{ paddingTop: "10px" }}>
                <CommonTable
                  parent_id={"category_details"}
                  handleEdit={(e, data, index) => {
                    handleListEdit(index);
                  }}
                  handleDelete={handleDelete}
                  rightAlign={["Action"]}
                  Header={state?.facttype?.value === "Schema" ? headerList?.schemaHeaders : headerList?.allHeaders}
                  // dataList={state?.facts}
                  dataList={state?.facts.map((item) => ({
                    ...item,

                    queryId: item?.queryId || "Null",
                    queryFilter: item?.queryFilter || "Null",
                  }))}
                  tableData={
                    state?.facttype?.value === "Schema" ?
                    [
                    { type: ["INCRIMENT"], name: "1" },
                    {
                      type: ["TEXT"],
                      name: "collectionName",
                      optionLabel: "label",
                    },
                    { type: ["TEXT"], name: "fieldName", optionLabel: "label" },
                    { type: ["TEXT"], name: "displayName" },
                    {
                      type: ["TEXT"],
                      name: "columnType",
                    },

                    // { type: ["TEXT"], name: "queryOrGm", optionLabel: "label" },

                    !props?.isFormOpen.isView && {
                      type: ["EDIT", "DELETE"],
                      name: "",
                      align: "right",
                    },
                  ] :
                  [
                    { type: ["INCRIMENT"], name: "1" },
                    {
                      type: ["TEXT"],
                      name: "collectionName",
                      optionLabel: "label",
                    },
                    { type: ["TEXT"], name: "fieldName", optionLabel: "label" },
                    { type: ["TEXT"], name: "displayName" },
                    { type: ["TEXT"], name: "controlType", optionLabel: "label",},
                    {
                      type: ["TEXT"],
                      name: "columnType",
                    },

                    // { type: ["TEXT"], name: "queryOrGm", optionLabel: "label" },
                    { type: ["TEXT"], name: "queryId" },
                    { type: ["TEXT"], name: "queryFilter" },

                    !props?.isFormOpen.isView && {
                      type: ["EDIT", "DELETE"],
                      name: "",
                      align: "right",
                    },
                  ]
                  }
                  // condition={condition}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            {!props?.isFormOpen?.isView && (
              <Paper className="pi-paper">
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        props?.getFacts();
                        props?.setisFormOpen({
                          isForm: false,
                          isEdit: false,
                          isView: false,
                        });
                        clearFacts();
                        setState({
                          factId: "",
                          factName: "",
                          facttype: "",
                          facts: [],
                        });
                      }}
                    >
                      {" "}
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                    >
                      {props?.editState?.edit ? "Update" : "save"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Div>
      <Dialog
        open={addNew}
        maxWidth="lg"
        fullWidth
        onClose={() => setAddNew(false)}
        disableBackdropClick
      >
        <Paper className="pi-paper" style={{ padding: "30px 20px" }}>
          <Grid container justifyContent="space-between">
            <Grid item style={{ paddingLeft: "10px" }}>
              <Typography variant="h6">Add Fact </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setAddNew(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Row>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Collection Name<span style={{ color: "red" }}> *</span>
              </Typography>
              <SelectBox
                list={collectionList ?? []}
                onchange={(e, v) => {
                  handleChange("collectionName", v);
                }}
                placeholder={"select..."}
                error={factError && !fact?.collectionName?.value}
                // // disableClearable
                // disabled={
                //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                required={true}
                value={fact?.collectionName}
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Field Name<span style={{ color: "red" }}> *</span>
              </Typography>
              <SelectBox
                list={fieldList ?? []}
                onchange={(e, v) => {
                  handleChange("fieldName", v);
                }}
                placeholder={"select..."}
                // // disableClearable
                required={true}
                value={fact?.fieldName}
                // disabled={
                //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                error={factError && !fact?.fieldName?.value}
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <TextInput
                value={fact?.displayName}
                onChange={(e) => handleChange("displayName", e.target.value)}
                label={
                  <>
                    Display Name<span style={{ color: "red" }}> *</span>
                  </>
                }
                labelStyle={labelStyle}
                placeholder=""
                style={
                  factError && !fact?.displayName
                    ? errorBorderStyle
                    : borderStyle
                }
              />
            </Col>
            {state?.facttype?.value === "Schema" ? null : (
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Control Type<span style={{ color: "red" }}> *</span>
              </Typography>
              <SelectBox
                list={extractIdAndValue("RULECONTROLTYPE", generalMaster)}
                placeholder={"select..."}
                // // disableClearable
                required={true}
                value={fact?.controlType}
                // disabled={
                //     props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                error={factError && !fact?.controlType?.value}
                onchange={(e, v) => {
                  handleChange("controlType", v);
                }}
              />
            </Col>
            )}
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <TextInput
                value={fact?.columnType}
                label={
                  <>
                    Column Type
                    <span style={{ color: "red", border: "none" }}> *</span>
                  </>
                }
                labelStyle={labelStyle}
                placeholder=""
                style={
                  factError && !fact?.columnType
                    ? errorBorderStyle
                    : borderStyle
                }
                disabled
              />
            </Col>
            {state?.facttype?.value === "Schema" ? null : (
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Query/GM
                {/* <span style={{ color: "red" }}> *</span> */}
              </Typography>
              <SelectBox
                list={extractIdAndValue("RULEQUERYORGM", generalMaster)}
                placeholder={"select..."}
                // // disableClearable
                required={true}
                value={fact?.queryOrGm}
                // disabled={
                //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                // error={factError && !fact?.queryOrGm?.value}
                onchange={(e, v) => {
                  handleChange("queryOrGm", v);
                }}
              />
            </Col>
            )}
            {state?.facttype?.value === "Schema" ? null : (
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <TextInput
                value={fact?.queryId}
                onChange={(e) => {
                  handleChange("queryId", e.target.value);
                }}
                label={
                  <>
                    Query Id
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </>
                }
                labelStyle={labelStyle}
                placeholder=""
                // style={
                //   factError && !fact?.queryId ? errorBorderStyle : borderStyle
                // }
                // disabled={fact?.queryOrGm?.value === "GM"}
              />
            </Col>
            )}
            {state?.facttype?.value === "Schema" ? null : (
            <Col lg={8} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                  marginBottom: "5px",
                }}
                variant="body1"
              >
                Operators<span style={{ color: "red" }}> *</span>
              </Typography>

              <Autocomplete
                fullWidth
                size="small"
                options={operator}
                value={fact?.operators ?? []}
                onChange={(e, value) => handleChange("operators", value)}
                getOptionLabel={(option) => option.label}
                multiple
                onBlur={() => {
                  if (!fact?.operators?.length) {
                    setFactError(true);
                  }
                }}
                onFocus={() => {
                  setFactError(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    name="example"
                    variant="outlined"
                    style={
                      factError && !fact?.operators?.length
                        ? errorBorderStyle
                        : borderStyle
                    }
                  />
                )}
              />
            </Col>
            )}
            {state?.facttype?.value === "Filter" ? (
              <Col lg={4} inLineStyles={{ padding: 10 }}>
                <TextInput
                  value={fact?.sequence}
                  onChange={(e) => handleChange("sequence", e.target.value)}
                  type="number"
                  label={<>Sequence</>}
                  labelStyle={labelStyle}
                  placeholder=""
                />
              </Col>
            ) : null}

            {state?.facttype?.value === "Filter" ? (
              <Col lg={4} inLineStyles={{ padding: 10 }}>
                <TextInput
                  value={fact?.icon}
                  onChange={(e) => handleChange("icon", e.target.value)}
                  label={<>Icon</>}
                  labelStyle={labelStyle}
                  placeholder=""
                />
              </Col>
            ) : null}

            {state?.facttype?.value === "Filter" ? (
              // <Col
              //   lg={4}
              //   style={{
              //     padding: 10,
              //     display: "flex",
              //     flexDirection: "column",
              //     justifyContent: "start",
              //     alignItems: "start",
              //   }}
              // >
              //   <Typography
              //     style={{
              //       fontSize: "12px",
              //       fontFamily: "pc_regular",
              //       color: "#6F6F6F",
              //       marginBottom: 8,
              //     }}
              //     variant="body1"
              //   >
              //     Is Dependent
              //   </Typography>
              //   <FormControlLabel
              //     control={
              //       <Checkbox
              //         color={"primary"}
              //         checked={fact?.isdependent}
              //         onChange={(e) =>
              //           handleChange("isdependent", e.target.checked)
              //         }
              //         name="isdependent"
              //         label="Is Dependent"
              //       />
              //     }
              //   />
              // </Col>
              <Col lg={4} inLineStyles={{ padding: 10 }}>
                <Typography
                  style={{
                    fontSize: "12px",
                    Fontfamily: "pc_regular",
                    color: "#6F6F6F",
                  }}
                  variant="body1"
                >
                  Dependent
                </Typography>
                <SelectBox
                  list={fieldList ?? []}
                  onchange={(e, v) => {
                    handleChange("dependent", v);
                  }}
                  // placeholder={"select..."}
                  // // disableClearable
                  // required={true}
                  value={fact?.dependent}
                  multi={true}
                  // disabled={
                  //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                  // }
                  // error={factError && !fact?.fieldName?.value}
                />
              </Col>
            ) : null}

            {state?.facttype?.value === "Schema" ? null : (
              <Col lg={12} inLineStyles={{ padding: 10 }}>
                <TextArea
                  value={fact?.queryFilter}
                  onChange={(e) => {
                    handleChange("queryFilter", e.target.value);
                  }}
                  label={"Query Filter"}
                  labelStyle={labelStyle}
                  placeholder='eg -  [
                  {
                    "keyname": "resourcetype",
                    "valuefieldname": "resource_type"
                  }
                ]'
                  multiline
                  rows={8}
                  style={borderStyle}
                />
              </Col>
            )}
          </Row>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button onClick={clearFacts}> Clear</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={saveFacts}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <Dialog
          open={warningAlert}
          keepMounted
          aria-labelledby="alert-title"
          aria-describedby="alert-description"
      >
          <DialogTitle id="alert-title" >
              <Typography style={{ display: 'flex' }}>
              <ErrorOutlineOutlinedIcon style={{ width: 22, height: 32, marginRight: 7, color: "#F58B00" }}/>
              <Typography variant='span' style={{ paddingTop: 5, fontWeight: "bold",}}>{"Warning"}</Typography>
              </Typography>
              <CloseIcon onClick={() => setwarningAlert(false)} style={{ float: "right", marginTop: "-26px", cursor: "pointer" }} />
          </DialogTitle>
          <DialogContent >
              <DialogContentText id="alert-description" style={{ border: `1.5px solid "#F58B00"`, borderRadius: "8px" }}>
                  <Typography style={{
                      background: "#FEF3E5",
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      padding: "8px 11px", color: "#F58B00",
                      fontWeight: 800, minWidth: 500
                  }}>Message</Typography>
                  <Typography style={{ padding: "10px 10px 15px 10px" }}>
                      {"Changing the Fact Type will result in the loss of Facts List data. Please proceed carefully."}
                  </Typography>
              </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "5px 23px 17px", justifyContent: "flex-end" }}>
              <Button variant='contained' onClick={() => clearAllNotificationTypes()} style={{ color: "white", background: "#F58B00" }}>
                  {"I Understand & Clear all"}
              </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
};
