import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  // TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  formParent: {
    paddingRight: 10,
  },
}));

export const CreateUserRole = (props) => {
  const classes = useStyles();
  const { state = {}, handlechange, parent_id, SelectorPatientAuto,finalValue,setFinalValue,error } = props;
  const [userRolelist, setuserRolelist] = useState([]);

  const getUserRole = () => {
    const axios = require("axios");
    let data = JSON.stringify({
      db_name: "primarycareng",
      queryid: "a11785e5-0368-40b4-b79b-c8a675ffca73",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://arangodbservice.dev.ainqaplatform.in/api/read_qdmqueries",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setuserRolelist(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserRole();
  }, []);

  return (
    <div>
      <Paper id={`${parent_id}-UserRole-paper`} className={classes.root}>
        <Typography
          id={`${parent_id}-UserRoleCategory Type-title-typography`}
          className={classes.title}
          variant="h6"
        >
          Booking Permission
        </Typography>
        <Grid
          id={`${parent_id}-UserRoleCategory-grid`}
          className={classes.form}
        >
          <Grid
            id={`${parent_id}-UserRoleCategory-title`}
            className={classes.formParent}
            lg={8}
            md={8}
          >
            <Typography
              id={`${parent_id}-UserRoleCategory-title-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              User Roles
              <span id={`${parent_id}-Code-star-span`} style={{ color: "red" }}>
                *
              </span>
            </Typography>
            <SelectBox
                parent_id={"UserRoleCategory-select"}
              // disabled={Boolean(editData)}
              //   defaultValue={state?.Patientcategory}
              multi={true}
              list={userRolelist}
              onchange={(e,v) =>
                setFinalValue({
                  ...finalValue,
                  userroles: v,
                })
              }
              value={finalValue?.userroles}
              error={error && !finalValue?.userroles.length}
              // list={[]}
                // value={state?.UserRoleCategory ?? []}
              //   onchange={(e, v) => handlechange("UserRoleCategory", [v], true, v)}
              // error={props.error?.applicable_type}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default CreateUserRole;
