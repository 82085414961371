import { Button, Card, Grid, IconButton, makeStyles } from '@material-ui/core'
import EditIcon from "@material-ui/icons/CreateOutlined";
import React, { useEffect, useState } from 'react'
import { CreateRule } from 'rulebuilder-v1';
import { withAllContexts } from '../../../HOCs';

const useStyles = makeStyles((theme) => ({
    req_red: {
      color: "red",
    },
}));
const PatientInclusionCriteria = (props) => {
  useEffect(()=>{
    setsaveRulePatient(props?.finalValue?.patientInclCriteria);
  },[props?.finalValue?.patientInclCriteria])
    const [saveRulePatient,setsaveRulePatient] = useState();
    const [RuleModalOpen,setRuleModalOpen] = useState({
        isIncCriteria: false,
      })
    const classes = useStyles();
  return (
    <>
    <Card
        style={{
          padding: "16px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "16px",
          borderRadius: 6,
        }}
      >
        <p style={{ fontFamily: "poppinsemibold" }}>
        PATIENT INCLUSION CRITERIA
        </p>
        {saveRulePatient?._id ? (
          <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {1}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%" }}>
                      {/* <div>{"PATIENT INCLUSION CRITERIA"}</div> */}
                      <div>
                        {"Rule Name : "+saveRulePatient?.ruleName} <div className={classes.dot} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        style={{ color: "#0071F2", fontSize: 18 }}
                        onClick={() => {
                          setRuleModalOpen({...RuleModalOpen, isSetupResouce : false,isIncCriteria: true})
                        }}
                      />
                    </IconButton>
                    {/* <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <DeleteIcon
                        onClick={() =>
                          props.deleteFun("TotNoOfResourcesRequired", i)
                        }
                        style={{ color: "#FF4D4A", fontSize: 18 }}
                      />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
        ) : (
          <Card
            style={{ padding: 16, paddingTop: 0, marginTop: "1%" }}
            elevation="2"
          >
            {/* <p style={{ fontFamily: "poppinsemibold" }}>
              PATIENT INCLUSION CRITERIA
            </p> */}
            <Button variant="outlined" color="secondary" onClick={()=>setRuleModalOpen({...RuleModalOpen,isIncCriteria: true})}>
              + Add New Rule
            </Button>
          </Card>
        )}
      </Card>
       {RuleModalOpen?.isIncCriteria && (
        <div >
        <CreateRule
            alert={props.alert}
            savedRuleData={(data) => {
                setsaveRulePatient(data);
                props?.setFinalValue({
                  ...props?.finalValue,
                  patientInclCriteria: data,
                })
                setRuleModalOpen({...RuleModalOpen, isSetupResouce : false, isIncCriteria: false})
            }}
            handleClose={()=>setRuleModalOpen({...RuleModalOpen, isSetupResouce : false, isIncCriteria: false})} 
            open={RuleModalOpen?.isIncCriteria} 
            dbName={process.env.REACT_APP_DATABASE_NAME}
            factId={process.env.REACT_APP_PATIENT_INCLUSION_CRITERIA_FACTID}
            ruleId={saveRulePatient?._id}
            metadataId={process.env.REACT_APP_METADATAID}
            metadata_dbname={process.env.REACT_APP_METADATA_DB_NAME}
            rulesParams={{
                tenantId: process.env.REACT_APP_TENANTID,
                facilityId: process.env.REACT_APP_FACILITY_ID,
            }}
        />
        </div>
      )}
      </>
  )
}

export default withAllContexts(PatientInclusionCriteria)