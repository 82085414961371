import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { CommonTabList } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import { useStyles } from "./styles";
import { TreeItem, TreeView } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import OverRideDetail from "./overRideDetail";
import OverRideFormManage from "./overRideFormManage";

const LoadPeriods = (props) => {
  const classes = useStyles();
  const [loadedPeriods,setLoadedPeriods] = useState([]);

  const findTheKeyss = (filterKeys, totalObject) => {
    let result = [];
    filterKeys.forEach((key) => {
        if(totalObject[key] === true){
            result.push(key)
        }
    });
    return result
  };

  useEffect(() => {
    const filterKeys = Object.keys(props?.val);
    const filteredKeys = findTheKeyss(filterKeys, props?.val);
    if(props?.type === "month"){
      const returnMonths = month.filter((val) =>  filteredKeys.includes(val?.value));
      setLoadedPeriods(returnMonths)
    }else if(props?.type === "week"){
      const returnWeek = Week.filter((val) => filteredKeys.includes(val?.value));
      setLoadedPeriods(returnWeek)
    }else{
      const reuturnDays = Days.filter((val) => filteredKeys.includes(val?.value));
      setLoadedPeriods(reuturnDays)
    }
  }, []);
  
  return(
    <div className={classes.align}>
      {loadedPeriods.map((values, i) => (
        <Typography className={classes.infoTypo}>
          {(i === loadedPeriods.length - 1 && loadedPeriods.length > 1
            ? " & "
            : "") +
            values.label +
            (i === loadedPeriods.length - 1 ||
            i === loadedPeriods.length - 2
              ? ""
              : ", ")}
        </Typography>
      ))}
    </div>
  )
}

const OverRideTimeTable = (props) => {
  const { closeOverRide, overRideData } = props;
  console.log("overRideData", overRideData);
  const editData = null;
  const classes = useStyles();
  const [overRideOpen, setOverRideOpen] = useState(false);
  const [scheduleDef, setScheduledDef] = useState({
    resources: [],
  });
  const [headers, setHeaders] = useState({});
  console.log("setScheduledDefcccc", scheduleDef);
  const [resourcePeriod, setResourcePeriod] = useState({
    appointmentList: [
      {
        duration_type: "",
        day_type: "",
        start_time: "",
        end_time: "",
        max_appoinment: "",
        max_appoinment_allowed: "",
        max_walking: "",
        max_over_booking: "",
        max_wait_list: "",
        consultation_type: "",
      },
    ],
    holidays: [],
  });
  const [resourceList, setResourceList] = React.useState([]);

  useEffect(() => {
    setHeaders({
      facility: overRideData?.organization_id?.name,
      resource_role: overRideData?.resource_role,
      resource_type: overRideData?.resource_type,
      start_date: overRideData?.start_date,
      end_date: overRideData?.end_date,
      resource_name: overRideData?.resource_name,
      appointmentperiod: overRideData?.appointmentperiod
    });
  }, []);

  const addNewSchedule = async () => {
    if (resourcePeriod?.id) {
      const index = resourceList.indexOf(resourcePeriod);
      console.log("index", index);
    } else {
      const newList = JSON.parse(JSON.stringify(resourceList));
      const settingResouceId = JSON.parse(JSON.stringify(resourcePeriod));
      const settingResouceKeys = Object.keys(settingResouceId);
      // const holidayData = stateData.holidays_time_table.data;
      // const holidayList = holidayData?.filter((val) => settingResouceKeys?.includes(val?.value));
      // settingResouceId["holidaysList"] = holidayList?.map((val) => {
      //   return {
      //     holidayid: val?.value,
      //     includeholiday: true
      //   }
      // })
      settingResouceId["id"] = uuidv4();
      settingResouceId["isNew"] = true;
      newList.push(settingResouceId);
      setResourceList(newList);
      setOverRideOpen(false);
      setResourcePeriod({
        appointmentList: [
          {
            duration_type: "",
            day_type: "",
            start_time: "",
            end_time: "",
            max_appoinment: "",
            max_appoinment_allowed: "",
            max_walking: "",
            max_over_booking: "",
            max_wait_list: "",
            consultation_type: "",
          },
        ],
        holidays: [],
      });
    }
  };

  // const addNewSchedule =  async () => {
  // }

  // handle Over Ride
  const handleOverRide = () => {
    setOverRideOpen(true);
  };

  // handle delete the overRide Slot
  const handleDelete = (i) => {
    const newDelete = JSON.parse(JSON.stringify(resourceList));
    newDelete.splice(i, 1);
    JSON.parse(JSON.stringify(newDelete));
    setResourceList(newDelete);
  };

  const handleEdit = (i, val) => {
    setResourcePeriod(val);
    setOverRideOpen(true);
  };

  // handle cancel
  const handleCancel = () => {
    setResourcePeriod({
      appointmentList: [
        {
          duration_type: "",
          day_type: "",
          start_time: "",
          end_time: "",
          max_appoinment: "",
          max_appoinment_allowed: "",
          max_walking: "",
          max_over_booking: "",
          max_wait_list: "",
          consultation_type: "",
        },
      ],
      holidays: [],
    });
    setOverRideOpen(false);
  };

  const getMonthData = (val) => {
    const filterKeys = Object.keys(val);
    console.log("filterKeys",filterKeys);
    console.log("month",month);
    const returnMonths = month.filter((val) =>  filterKeys.includes(val?.value));
    console.log("returnMonths",returnMonths);
    return returnMonths?.map((value) => <p>{value}</p>)
  }

  return (
    <React.Fragment>
      <CommonTabList
        backbtn
        backFun={closeOverRide}
        title={"Schedule Definition Override"}
        list={[]}
      />
      <div className={classes.root}>
        <Typography className={classes.headerTypo}>
          Original Schedule Detail
        </Typography>
        <div className={classes.box}>
          <TreeView
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpanded={["root"]}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<ArrowRightIcon />}
          >
            <TreeItem
              nodeId="1"
              classes={{ selected: classes.selected }}
              label={
                <div
                  style={{
                    padding: "10px 16px",
                    backgroundColor: "#F9F9F9",
                    borderRadius: "8px",
                    border: "1px solid #DEE5EC",
                  }}
                >
                  <div
                    className={classes.align}
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className={classes.align} style={{ gap: "20px" }}>
                      <div className={classes.align} style={{ gap: "8px" }}>
                        <img src="/images/icons8-hospital-3.svg" />
                        <Typography className={classes.subHeaderTypo}>
                          {headers.facility}
                        </Typography>
                      </div>
                      <div className={classes.align} style={{ gap: "8px" }}>
                        <img src="/images/icons8-identification-documents.svg" />
                        <Typography className={classes.subHeaderTypo}>
                          {headers.resource_type}
                        </Typography>
                      </div>
                      <div className={classes.align} style={{ gap: "8px" }}>
                        <img src="/images/Group 94306.svg" />
                        <Typography className={classes.subHeaderTypo}>
                          {headers.resource_role}
                        </Typography>
                      </div>
                      <div className={classes.align} style={{ gap: "8px" }}>
                        <img src="/images/icons8-doctor-male.svg" />
                        {headers?.resource_name?.map((val) => (
                          <Typography className={classes.subHeaderTypo}>
                            {val}
                          </Typography>
                        ))}
                      </div>
                      <div className={classes.align} style={{ gap: "8px" }}>
                        <img src="/images/icons8-calendar.svg" />
                        <Typography className={classes.infoTypo}>
                          {headers.start_date} - {headers.end_date}
                        </Typography>
                      </div>
                    </div>
                    {resourceList?.length === 0 && (
                      <div>
                        <Button
                          className={classes.noBtn}
                          onClick={handleOverRide}
                          contained
                        >
                          Add Override
                        </Button>
                      </div>
                    )}
                  </div>
                  {headers?.appointmentperiod?.map((val, i) => (
                    <div
                      className={classes.align}
                      key={val?.id}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #DCDCDC",
                        borderRadius: "10px",
                        margin: "12px 0px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={classes.align}>
                        <div
                          className={classes.align}
                          style={{
                            gap: 8,
                            padding: "16px",
                            borderRight: "1px solid #DCDCDC",
                          }}
                        >
                          <Typography className={classes.headerTypo}>
                            ({i + 1})
                          </Typography>
                          <div className={classes.align}>
                            <LoadPeriods type="month" val={val} />
                          </div>
                          <div className={classes.dot}></div>
                          <div className={classes.align}>
                            <LoadPeriods type="week" val={val} />
                          </div>
                          <div className={classes.dot}></div>
                          <div className={classes.align}>
                            <LoadPeriods type="day" val={val} />
                          </div>
                        </div>
                        <div
                          className={classes.align}
                          style={{ gap: 8, padding: "16px" }}
                        >
                          <Typography className={classes.headerTypo}>
                            TIMINGS
                          </Typography>
                          {val?.appointmentdurationid?.map((value, index) => (
                            <>
                              <div className={classes.align}>
                                <Typography className={classes.infoTypo}>
                                  {value?.starttime} - {value?.endtime}
                                </Typography>
                              </div>
                              {val?.appointmentdurationid?.length - 1 !== index && (
                                <div className={classes.dot}></div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          </TreeView>
        </div>

        <OverRideDetail
          handleOverRide={handleOverRide}
          resourceList={resourceList}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: "10px" }}
        >
          <Grid>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              // onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              className={classes.confirmButton}
              variant="outlined"
              // onClick={() => addNewSchedule()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={overRideOpen}
        onClose={handleCancel}
        PaperProps={{
          style: { maxWidth: "1200px", padding: "20px 0px 20px 20px" },
        }}
      >
        <OverRideFormManage
          addNewSchedule={addNewSchedule}
          setScheduledDef={setScheduledDef}
          scheduleDef={scheduleDef}
          setResourcePeriod={setResourcePeriod}
          resourcePeriod={resourcePeriod}
          setOverRideOpen={setOverRideOpen}
          resourceList={resourceList}
          handleCancel={handleCancel}
          options={props?.options}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default OverRideTimeTable;

const month = [
  { value: "month1", label: "Jan", disabled: false, name: "Jan" },
  { value: "month2", label: "Feb", disabled: false, name: "Feb" },
  { value: "month3", label: "Mar", disabled: false, name: "Mar" },
  { value: "month4", label: "Apr", disabled: false, name: "Apr" },
  { value: "month5", label: "May", disabled: false, name: "May" },
  { value: "month6", label: "Jun", disabled: false, name: "Jun" },
  { value: "month7", label: "Jul", disabled: false, name: "Jul" },
  { value: "month8", label: "Aug", disabled: false, name: "Aug" },
  { value: "month9", label: "Sep", disabled: false, name: "Sep" },
  { value: "month10", label: "Oct", disabled: false, name: "Oct" },
  { value: "month11", label: "Nov", disabled: false, name: "Nov" },
  { value: "month12", label: "Dec", disabled: false, name: "Dec" },
];
const Week = [
  { value: "week1", label: "Week 1", disabled: false, name: 1 },
  { value: "week2", label: "Week 2", disabled: false, name: 2 },
  { value: "week3", label: "Week 3", disabled: false, name: 3 },
  { value: "week4", label: "Week 4", disabled: false, name: 4 },
  { value: "week5", label: "Week 5", disabled: false, name: 5 },
];
const Days = [
  { value: "day1", label: "Sun", disabled: false, name: "Sunday" },
  { value: "day2", label: "Mon", disabled: false, name: "Monday" },
  { value: "day3", label: "Tue", disabled: false, name: "Tuesday" },
  { value: "day4", label: "Wed", disabled: false, name: "Wednesday" },
  { value: "day5", label: "Thu", disabled: false, name: "Thursday" },
  { value: "day6", label: "Fri", disabled: false, name: "Friday" },
  { value: "day7", label: "Sat", disabled: false, name: "Saturday" },
];
const Holidays = []