import React from "react";
import { Div } from "qdm-component-library";
import TopNavbar from "../navbar/topNavBar";
import PatientAddress from "./address";
import PatientCommunication from "./communication";
import PatientCotactPoint from "./contactPoint";
import PatientIdentification from "./patientIdentification";
import PatientNames from "./patientNames";
import Qualification from "./qualification";
import Publication from "./publication";
import Training from "./training";
import JobExp from "./jobExperience";
import Awards from "./aawardsAndAchievements";
import { withAllContexts } from "../../../HOCs";
import Practitionerbyrole from "../addPractotionerRole/practitionerbyrole";
import { withHoverDrawer } from "../../../HOCs";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { AlertProps, checkMaxDateValidation, checkDateValidation, getUtcTime, _calculateAge } from "../../../utils";
import { Button, CircularProgress } from "@material-ui/core";
import { routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import Biography from "./biography";
import { addUserToKeyClock, upsertDocument } from "../../../function/commonapi";
import Config from "../../../config";
import moment from "moment";
const practitionerInitialstate = {
  identification: [{}],
  names: [{}],
  details: {},
  practitioner_description: {},
  communication: {},
  contact: [{}],
  Submitrole: {},
  address: [],
  publication: [{}],
  reporting: {},
  practitionerbyrole: [],
  qualification: [],
  training: [],
  job: [],
  awards: [],
};
class Addpractitioner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: 1,
      error:false,
    };
    this.successfun = this.successfun.bind(this);
  }
  async componentDidMount() {
    //loading true
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Fetching practitioner details...",
    });
    await this.getallmasters();
    await this.getCountryData();
    let urlParams = this.props?.location?.state?.Id ?? null;
    if (urlParams) {
      await this.getPractitionerById(urlParams);
    }
    //loading true
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }

  getPractitionerById = async (id) => {
    const data = await this.props.PRACTITIONER_GET_BY_ID({ id: id });
    if (data.payload.data) {
      let updateddata = JSON.parse(JSON.stringify(data.payload.data));
      let patientData = this.props.patientData;
      
      patientData.setPatientData({
        ...patientData,
        ...updateddata,
      });
    }
  };

  getallmasters = async () => {
    const data = await this.props.PRACTITIONER_MASTERS();
    this.setState({
      allMasters: data?.payload?.data,
    });
  };

  getCountryData = async () => {
    const data = await this.props.PRACTITIONER_COUNTRY_MASTER();
    this.setState({
      countryDropdownData: data?.payload?.data,
    });
  };

  validation = (data) => {
    let edit = this.props?.location?.state?.Id ?? null;

    // PRACTITIONER IDENTIFICATION
    const identificationValidation = () => {
      if (data.identification?.length === 0) {
        return true;
      } else {
        // id_number, id_type
        let patientData = this.props.patientData;
        let errorFiles = {};
        let isErr = false;
        let arr = []
        
        let error = data.identification.map((val, i) =>
          val?.id_type?.label?.length > 0 &&
          val?.id_number?.length > 0 &&
          val?.expiry_date?.length > 0 &&
          checkDateValidation(
            (edit && this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date) 
            ? this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date 
            : moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date) === null &&
          arr.push(this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date === val?.expiry_date )
        );

        data.identification.map((val, i) => {
          if (
            !val?.id_type?.label?.length > 0 ||
            !val?.id_number?.length > 0 ||
            !val?.expiry_date||
            checkDateValidation( edit ? this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date : moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date)
          ) {
            if(val?.id_type?.label && val?.id_number && val?.expiry_date){
              isErr = false
            } else {
              isErr = true
            }
            errorFiles = {
              id_type: val?.id_type?.label?.length > 0 ? false : true,
              id_number: val?.id_number?.length > 0 ? false : true,
              expiry_date: val?.expiry_date ? false : true,
              // expiry_date: checkDateValidation( edit ? this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date : moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date) ? true : false
            };
          }
          
          patientData.identification[i]["error"] = {...errorFiles};
          errorFiles = {};
        });
        
        patientData.setPatientData({
          ...patientData,
        });
        return isErr//error.includes(arr.includes(true) ? false : true);
        // return error.includes(true)
      }
    };

    // PRACTITIONER DETAILS
    const detailsValidation = () => {
      if (data?.names?.length === 0) {
        return true;
      } else {
        // first_name, last_name, middle_name, prefix, suffix, use, dob, gender
        let error = data.names.map((val) =>
          val?.prefix?.label?.length > 0 &&
            val?.use?.label?.length > 0 &&
            // val?.middle_name?.length > 0 &&
            val?.last_name?.length > 0 &&
            val?.first_name?.length > 0
            ? true
            : false
        );
        error.push(data?.details?.dob ? data?.details?.dob?.toString().length > 0 ? true : false : false);
        error.push(data?.details?.gender?.label?.length > 0 ? true : false);
        error.push(data?.details?.nationality?.label?.length > 0 ? true : false);
        // error.push(data?.details?.practitoner_id?.length > 0 ? true : false);
        // error.push(data?.details?.email?.length > 0 ? true : false);

        //  set error
        let patientData = this.props.patientData;
        let errorFiles = {};
        let errorDetailsFiles = {
          dob: data?.details?.dob ? data?.details?.dob?.toString().length > 0 ? false : true : false,
          gender: data?.details?.gender?.label?.length > 0 ? false : true,
          nationality: data?.details?.nationality?.label?.length > 0 ? false : true,
          // practitoner_id: data?.details?.practitoner_id?.length > 0 ? false : true,
          // email: data?.details?.email?.length > 0 ? false : true,
        };
        patientData.details["error"] = errorDetailsFiles;
        data.names.map((val, i) => {
          if (
            !val?.prefix?.label?.length > 0 ||
            !val?.use?.label?.length > 0 ||
            // !val?.middle_name?.length > 0 ||
            !val?.last_name?.length > 0 ||
            !val?.first_name?.length > 0
          ) {
            errorFiles = {
              first_name: val?.first_name?.length > 0 ? false : true,
              last_name: val?.last_name?.length > 0 ? false : true,
              // middle_name: val?.middle_name?.length > 0 ? false : true,
              prefix: val?.prefix?.label?.length > 0 ? false : true,
              use: val?.use?.label?.length > 0 ? false : true,
            };
          }
          patientData.names[i]["error"] = errorFiles;
          errorFiles = {};
        });
        patientData.setPatientData({
          ...patientData,
        });
        return error.includes(false);
      }
    };

    // practitionerRoleValidation
    const practitionerRoleValidation = () => {
      if (data.practitionerbyrole?.length === 0) {
        return true
      } else {
        let error = data?.practitionerbyrole?.map(
          (val) => val?.primary_specialty
        )?.[0]
          ? false
          : true;
        return error;
      }
    };

    // Practitioner Description
    const descriptionValidation = () => {
      if(data.practitioner_description?.length === 0){
        return false;
      }else{
        // practitioner_type
         let error = [];
         error.push(data?.practitioner_description?.practitioner_type?.label?.length > 0 ? true : false);

         let patientData = this.props.patientData;
         let errorDetailsFiles = {
           practitioner_type: data?.practitioner_description?.practitioner_type?.label?.length > 0 ? true : false,
          //  gender: data?.details?.gender?.label?.length > 0 ? false : true,
          //  nationality: data?.details?.nationality?.label?.length > 0 ? false : true,
           // practitoner_id: data?.details?.practitoner_id?.length > 0 ? false : true,
           // email: data?.details?.email?.length > 0 ? false : true,
         };
         patientData.practitioner_description["error"] = errorDetailsFiles;
         patientData.setPatientData({
           ...patientData,
         });
         return error.includes(false);
      }
    }
    
    // CONTACT DETAILS
    const contactValidation = () => {
     if(data?.contact?.length === 0){
      return {isEmail : true}
     } else {
      const emailExists = data?.contact?.find(item => item.mode?.label?.toLocaleLowerCase() === "email");
      if(emailExists){
        let err = {}
        data?.contact?.map((v,i)=>{
            if(v?.mode?.label === "Email" && v?.mode?._id === "CodingMaster/10469"){
              if(v?.mobile === "" || v?.mobile === undefined || v?.mobile === null){
                err = {isEmail : true}
                return null;
              } else {
                let isEmailCheck = isValidEmail(v?.mobile)
                if(isEmailCheck){
                  err = {isEmailCheck : true};
                  return null;
                } else {
                  err = {isEmailCheck : false};
                  return null;
                }
              }
            }
        })
        if(err?.isEmailCheck === false){
          return false
        } else {
          return err
        }
      } else {
        return {isEmail : true}
      }
     }
      
    };
    
    const isValidEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return !regex.test(email);
    };

    // CONTACT DETAILS
    // const contactValidation = () => {
    //   if(data.contact?.length === 0){
    //     return false;
    //   }else{
    //     // mode, mobile
    //      let error = data.contact.map(val => (val?.mode?.label?.length > 0 && ( val?.mode?.label === "Phone" ? (val?.mobile?.length > 9 ? true : false): (val?.mobile?.length > 0 ? true : false) ) ) ? true : false );
    //      return error.includes(false);
    //   }
    // }

    // const emailValidationIncon = () => {
    //   if (data?.contact?.filter(val => val?.mode?.label?.toLocaleLowerCase() === "email").length > 0 && data?.contact?.filter(val => val?.mode?.label?.toLocaleLowerCase() === "email")?.[0]?.mobile) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }

    const alert = (mage) => {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: mage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    };
    this.setState({
      error: true,
    });
    const idenErr = identificationValidation();
    const detailsErr =  detailsValidation();
    const descriptErr = descriptionValidation();
    const cntErr = contactValidation();
    const practRoleErr = practitionerRoleValidation();
    const hasPrimarySpecialty = data?.practitionerbyrole?.some(val => val?.primary_specialty);
    if(!detailsErr && !descriptErr && !practRoleErr && !idenErr && !cntErr){
      return false
    } else if (detailsErr || descriptErr || idenErr){
      alert("Please Fill all the Mandatory Fields!")
      return true
    } else if (cntErr?.isEmail){
      alert("Please Add Atleast 1 Email in Contact Details!")
      return true
    } else if (cntErr?.isEmailCheck){
      alert("Entered Email Is Incorrect!")
      return true
    } else if (practRoleErr && data.practitionerbyrole?.length === 0){
      alert("Please Fill Atleaset One Practitioner By Role!")
      return true
    } else if (practRoleErr && !hasPrimarySpecialty){
      alert("Please Select a Primary Specialty For Any One Practitioner By Role!");
      return true
    }

    // if (identificationValidation()) {
    //   alert("Please fill identification mandatory fields");
    //   return true;
    // } else if (detailsValidation()) {
    //   alert("Please fill practitioner details mandatory fields");
    //   return true;
    // } else if(descriptionValidation()){
    //   alert("Please fill practitioner description mandatory fields");
    //   return true;
    // } else if (practitionerRoleValidation()) {
    //   // alert("Please Add Atleast One practitioner Role");
    //   return true;
    // } else if (!isValidEmail(data?.contact?.filter(val => val?.mode?.label?.toLocaleLowerCase() === "email").length > 0 && 
    // data?.contact?.filter(val => val?.mode?.label?.toLocaleLowerCase() === "email")?.[0]?.mobile)) {
    //   alert("Email is Invalid");
    //   return true;
    // }
    // else
    // if(contactValidation()){
    //   alert("Please fill practitioner contact mandatory fields");
    //   return true;
    // }
  };

  async successfun() {
    let data = this.props.patientData;
    if (this.validation(data)) {
    } else {
      let edit = this.props?.location?.state?.Id ?? null;
      const formatDateToUnix = (date) => {
        if (typeof date === 'string') {
          date = new Date(date);
        }
        return date instanceof Date && !isNaN(date) ? Math.floor(date.getTime() / 1000) : 0;
      };
      const formatToDDMMYY = (input) => {
        let date;
        if (input instanceof Date) {
          date = input;
        } 
        else if (typeof input === "string") {
          const [day, month, year] = input.split("/").map(Number);
          date = new Date(year, month - 1, day);
        } 
        else {
          throw new Error("Invalid date format");
        }
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear());
        return `${day}/${month}/${year}`;
      }
      
      //loading true
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: edit ? "Updating practitioner..." : "Creating practitioner...",
      });

      let personId = null;
      // if (!edit) {
      //   //keyclock user creation
      //   let keyclockError = false;
      //   let tokenData = localStorage.getItem("payload");
      //   let parsedToken = JSON.parse(tokenData);

      //   let email_id = data?.contact?.filter(val => val?.mode?.label === "Email")?.[0]?.mobile ?? null
      //   let addUserKeyClock = await addUserToKeyClock(
      //     parsedToken.access_token,
      //     email_id
      //   ).then((res) => {
      //     if (res?.data?.Code === 201) {
      //       // alert("User created successfully!", true);
      //     } else {
      //       if (res?.data?.error) {
      //         keyclockError = true
      //         this.props.alert.setSnack({
      //           open: true,
      //           severity: AlertProps.severity.error,
      //           msg: res?.data?.errorMessage ?? "Something  went wrong Keyclock mail filed",
      //           vertical: AlertProps.vertical.top,
      //           horizontal: AlertProps.horizontal.right,
      //         });
      //         // return alert(res?.data?.errorMessage);
      //       }
      //     }
      //   })
      //     .catch((error) => {
      //       keyclockError = true
      //       this.props.alert.setSnack({
      //         open: true,
      //         severity: AlertProps.severity.error,
      //         msg: "Something  went wrong Keyclock mail filed",
      //         vertical: AlertProps.vertical.top,
      //         horizontal: AlertProps.horizontal.right,
      //       });
      //       // return alert("Something  went wrong", false);
      //     });
      //   if (keyclockError) {
      //     this.props.backDrop.setBackDrop({
      //       ...this.props.backDrop,
      //       open: false,
      //       message: "",
      //     });
      //     return false
      //   }

      //   //user creation
      //   let list = {
      //     identifier: [],
      //     name: [
      //       {
      //         use: "string",
      //         text: "",
      //         family: "",
      //         given: "",
      //         prefix: "",
      //         suffix: "",
      //         period: [
      //           {
      //             start: Math.floor(Date.now() / 1000),
      //             end: 0,
      //           },
      //         ],
      //       },
      //     ],
      //     telecom: [
      //       {
      //         system: "Email",
      //         value: email_id ?? "", // number
      //         use: "Work", // phone
      //         rank: "1", // 1
      //         period: [
      //           {
      //             start: Math.floor(Date.now() / 1000),
      //             end: 0,
      //           },
      //         ],
      //       },
      //     ],
      //     gender: "",
      //     birthDay: "",
      //     address: [],
      //     photo: [],
      //     active: true,
      //     link: [],
      //     Id: 0,
      //     RelatedPersonID: [],
      //     OrgID: [],
      //   };
      //   let sendUserData = {
      //     entity: Config.personEntity,
      //     metadataId: Config.metadataid,
      //     list,
      //   };
      //   let personerror = false;
      //   let addUserUpsert = await upsertDocument(sendUserData)
      //     .then((res) => {
      //       if (res?.data?.Code === 201) {
              
      //         personId = res?.data?.Result?.[0]?.properties?.doc?.Id ?? 0;
      //         // alert("User created successfully!", true);
      //       } else {
      //         personerror = true
      //         this.props.alert.setSnack({
      //           open: true,
      //           severity: AlertProps.severity.error,
      //           msg: res?.data?.errorMessage ?? "Something  went wrong person creation",
      //           vertical: AlertProps.vertical.top,
      //           horizontal: AlertProps.horizontal.right,
      //         });
      //         // return alert(res?.data?.errorMessage);
      //       }
      //     })
      //     .catch((error) => {
      //       personerror = true
      //       this.props.alert.setSnack({
      //         open: true,
      //         severity: AlertProps.severity.error,
      //         msg: "Something  went wrong person creation",
      //         vertical: AlertProps.vertical.top,
      //         horizontal: AlertProps.horizontal.right,
      //       });
      //       // return alert("Something  went wrong", false);
      //     });
      //   if (personerror) {
      //     this.props.backDrop.setBackDrop({
      //       ...this.props.backDrop,
      //       open: false,
      //       message: "",
      //     });
      //     return false
      //   }


      // }
      let qualificationArr = [];
      data?.qualification?.map((v,i)=>{
        let obj = {
          name: v?.qualification_name ?? "",
          university_board: v?.name ?? "",
          issue_date: formatDateToUnix(v?.issued_by),
          valid_from: formatDateToUnix(v?.start_date),
          valid_to: formatDateToUnix(v?.end_date),
        }
        qualificationArr.push(obj)
      })

      let publicationDetailsArr = [];
      data?.publication?.map((v,i)=>{
        let obj = {
          description: v?.description ?? "",
          link: v?.link ?? ""
        }
        publicationDetailsArr.push(obj)
      })

      let trainingDetailsArr = [];
      data?.training?.map((v,i)=>{
        let obj = {
          organization: v?.organization ?? "",
          course_name: v?.course_name ?? "",
          responsible_person: v?.responsible_person ?? "",
          course_start_date: formatDateToUnix(v?.course_start_date) ?? 0,
          course_end_date: formatDateToUnix(v?.course_end_date) ?? 0,
          certificate_issued_date: formatDateToUnix(v?.certificate_issue_date) ?? 0,
          certificate_expiry_date: formatDateToUnix(v?.certificate_expiry_date) ?? 0,
        }
        trainingDetailsArr.push(obj)
      })

      let jobExperienceArr = [];
      data?.job?.map((v,i)=>{
        let obj = {
          sequence_no: v?.sequence_no ?? "",
          organization: v?.organization ?? "",
          department: v?.department ?? "",
          designation: v?.designation ?? "",
          role: v?.role ?? "",
          start: formatDateToUnix(v?.start_date) ?? 0,
          end: formatDateToUnix(v?.end_date) ?? 0,
          report_to: v?.reporting_to ?? "",
          grade: v?.grade ?? "",
          pay_scale: v?.pay_scale ?? "",
          job_description: v?.job_description ?? ""
        }
        jobExperienceArr.push(obj)
      })

      let achievementsAwardsDetailsArr = [];
      data?.awards?.map((v,i)=>{
        let obj = {
          organization: v?.organization ?? "",
          type: v?.type ?? "",
          title: v?.title ?? "",
          note: v?.notes ?? "",
          date: formatDateToUnix(v?.date) ?? 0,
        }
        achievementsAwardsDetailsArr.push(obj)
      })

      let practitionerRoleArr = [];
      data?.practitionerbyrole?.map((v, i) => {
          let obj = {
            role: v?.select_role?._id ?? "",
            specialtyid: v?.speciality?._id ?? "",
            active: true,
            entitytype: v?.select_entity?._id ?? "",
            entityname: v?.entity_name?._id ?? "",
            primaryspeciality: v?.primary_specialty ?? false,
            locationid: v?.select_location?._id ?? "",
            start: formatDateToUnix(v?.start_date),
            end: formatDateToUnix(v?.end_date)
          };
          practitionerRoleArr.push(obj);
      });

      let identifierArr = [];
      data?.identification?.forEach((v) => {
        let attach = v?.doc?.map((doc) => ({
          _id: doc?._id ?? "",
          reftype: "",
          date: "",
          fileName: doc?.fileName ?? "",
          fileid: doc?.fileid ?? "",
          filetype: doc?.filetype ?? "",
          base64string: doc?.base64string ?? "",
          id: 0,
          objectid: doc?.objectid ?? "",
          url: "",
        })) ?? [];
        let expiryDate = v?.expiry_date instanceof Date ? v.expiry_date : new Date(v?.expiry_date); 
        let obj = {
          use: "",
          code: v?.id_type?._id ?? "",
          value: v?.id_number ?? "",
          start: 0,
          end: expiryDate.getTime() ? Math.floor(expiryDate.getTime() / 1000) : 0,
          attachments: attach,
        };
      
        identifierArr.push(obj);
      });
      
      let nameArr = [];
      data?.names?.map((v,i)=>{
        let obj = {
          use: v?.use?._id ?? "",
          title: "",
          lang: "en",
          firstname: v?.first_name ?? "",
          mldname: v?.middle_name ?? "",
          lstname: v?.last_name ?? "",
          prefix: v?.prefix?._id ?? "",
          start: 0,
          // suffix: v?.suffix?._id ?? "",
          end: 0,
          fourthname: "",
          fulname: `${v?.first_name ?? ""} ${v?.middle_name ?? ""} ${v?.last_name ?? ""}`
        }
        nameArr.push(obj);
      })

      let telecomArr = [];
      data?.contact?.map((v,i)=>{
        let obj = {
          system: v?.mode?._id ?? "",
          value: v?.mobile ?? "",
          use: v?.use?._id ?? "",
          rank: Number(v?.priority?.display ?? v?.priority?.label ?? ""),
          start: 0,
          end: 0
        }
        telecomArr.push(obj)
      })

      let addressArr = [];
      data?.address?.map((v,i)=>{
        let obj = {
          use: v?.use?._id ?? "",
          type: v?.type?._id ?? "",
          text: v?.addressLine1 ?? "",
          line: v?.addressLine2 ?? "",
          lang: "en",
          city: v?.city?._id ?? "",
          district: v?.district?._id ?? "",
          state: v?.state?._id ?? "",
          postalcode: v?.pincode?._id ?? "",
          country: v?.country?._id ?? "",
          start: 0,
          end: 0
        }
        addressArr.push(obj)
      })
      let photoArr = []
      if(data?.details?.photo?.fileid){
        photoArr = [
          {
            _id : data?.details?.photo?._id ?? "",
            reftype : "",
            date : "",
            fileName : data?.details?.photo?.fileName ?? "",
            fileid : data?.details?.photo?.fileid ?? "",
            filetype : data?.details?.photo?.filetype ?? "",
            base64string : data?.details?.photo?.base64string ?? "",
            id : 0,
            objectid : data?.details?.photo?.objectid ?? "",
            url : "",
          }
        ];
      }

      let communicationLanguage = [];
      data?.practitioner_description?.communication_languages?.map((v,i)=>{
        communicationLanguage.push(v?._id ?? "")
      })
      // const date = new Date(data?.details?.dob);
      // const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
      const payload = [
        {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          entity: process.env.REACT_APP_PRACTITIONER_ENTITY,
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
          practitioner_id: data?.pract_id ?? "",
          doc: {
            active: data?.pract_id ? data?.details?.status : true,
            tenantid: process.env.REACT_APP_TENANTID,
            facilityid: process.env.REACT_APP_FACILITY_ID,
            privilage: "",
            description: "",
            altdescrption: [
              {
                lang: "en",
                description: ""
              }
            ],
            alias: [
              {
                code: "",
                type: "",
                description: "",
                start: 0,
                end: 0
              }
            ],
            practitionerdetails: {
              practitionerid: data?.details?.practitoner_id ?? "",
              personid: "",
              practitioner_type: data?.practitioner_description?.practitioner_type?._id ?? "",
              communication: [],
              qualification: qualificationArr ?? [],
              biography_description: data?.details?.biography_description ?? "",
              publication_details: publicationDetailsArr ?? [],
              training_details: trainingDetailsArr ?? [],
              job_experience: jobExperienceArr ?? [],
              achievements_awards_details: achievementsAwardsDetailsArr ?? [],
              files: [],
              practitioner_role: practitionerRoleArr ?? [],
              applorg: [    
                {
                  entStatus: "",
                  entitytype: "",
                  entitycode: "",
                  efffr: 0,
                  effto: 0,
                  reason: "",
                  statushistory: [
                    {
                      eventid: "",
                      frmstatus: "",
                      tostatus: "",
                      modifieddate: 0,
                      modifiedby: "",
                      remarks: ""
                    }
                  ]
                }
              ]
            },
            personDetails: {
              _id: data?.personid ?? "",
              identifier: identifierArr ?? [],
              name: nameArr ?? [],
              telecom: telecomArr ?? [],
              gender: data?.details?.gender?._id ?? "",
              birthdate: formatToDDMMYY(data?.details?.dob) ?? "",
              age: String(_calculateAge(new Date(data?.details?.dob))) ?? "",
              age_type: "",
              approxageentered: false,
              address: addressArr ?? [],
              photo: photoArr ?? [],
              personid: data?.personid ?? "",
              usertype: "",
              roleid: "",
              maritalstatus: data?.details?.marital_status?._id ?? "",
              nationality: data?.details?.nationality?._id ?? "",
              ethinicitycode: "",
              racecode: "",
              religioncode: "",
              genderidentitycode: "",
              disabilitycode: "",
              citizenshipcode: "",
              birthgendercode: "",
              birthPlace: "",
              preferredlanguage: "",
              bloodgroup: "",
              residencystatus: false,
              communicationlanguage: communicationLanguage ?? [],
              isdeceased: false,
              deceaseddate: 0,
              relatedPerson: [
                {
                  relationship: "",
                  relatedpersonid: ""
                }
              ],
              status: "CodingMaster/11413",
              statushistory: [],
              attachments: [],
              grcid: "",
              deceasedreason: ""
            }
          }
        }
      ]
      // PRACTITIONER_UPSERT
      let urlParams = this.props?.location?.state?.Id ?? null;
      let upsertRec = await this.props.PRACTITIONER_UPSERT(payload,process.env.REACT_APP_SAVE_PRACT);

      if (
        upsertRec?.payload?.data?.[0]?.error === true &&
        upsertRec?.payload?.data?.[0]?.statusCode === 400
      ) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg:
          upsertRec?.[0]?.response?.[0]?.message ??
            "Some thing error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else if (upsertRec?.payload?.data?.[0]?.error === true) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: urlParams ? "Failed to Update Practitioner" : "Failed to Save Practitioner",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else if (upsertRec?.payload?.data?.[0]?.error === false && upsertRec?.payload?.data?.[0]?.statusCode === 201){
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: urlParams
            ? "Practitioner updated successfuly"
            : "Practitioner created successfuly",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        // practitionermaster
        let patientData = this.props.patientData;
        await patientData.setPatientData({
          ...practitionerInitialstate,
        });
        await this.props.history.push(routes.practitionermaster);
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Contact Admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }

      //loading false
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });

    }
    // this.props.handleDrawerClose();
  }
  setActive = (id) => {
    this.setState({
      activeId: id,
    });
  };
  styles = {
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    loadscreen: {
      display: "flex",
      justifyContent: "center",
      margin: "16px",
      borderRadius: "6px",
      background: "#fff",
      alignItems: "center",
      minHeight: "76vh",
    },
  };
  saveAddress = (index) => { };

  backHandle = async () => {
    let patientData = this.props.patientData;
    await patientData.setPatientData({
      ...practitionerInitialstate,
    });
    await this.props.history.push(routes.practitionermaster);
  };

  render() {
    const activeId = this.props.IsactiveRole ? 2 : this.state.activeId;
    const patientRegister = [
      {
        id: 1,
        link: "/",
        label: "Practitioner",
      },
      {
        id: 2,
        link: "/",
        label: "Practitioner By Role",
      },
    ];

    return (
      <Div
        id={'addpractitioner-parent-div'}
        className="light-background-color">
        <TopNavbar
          parent_id={'topnavbar'}
          activeId={activeId}
          navClick={this.setActive}
          successfun={this.successfun}
          title={
            this.props?.location?.state?.Id
              ? "Edit Practitioner"
              : "Add New Practitioner"
          }
          navBarArr={patientRegister}
          backHandle={this.backHandle}
        // handleDrawerClose={this.props.handleDrawerClose}
        />

        {this.props.practitionerbyid.loading &&
          this.props.practitioner_masters.loading ? (
          <div
            id={'addpractitioner-circularprogress-div'}
            style={this.styles.loadscreen}>
            <CircularProgress
              id={'addpractitioner-parent-div'}
            />
          </div>
        ) : (
          <Div
            id={'addpractitioner-parent-div'}
            style={{
              padding: "5px 15px",
              color: "#101010",
              overflow: "hidden",
            }}
          >
            {/* PRACTITIONER IDENTIFICATION */}
            <PatientIdentification
              parent_id={'patientidentification'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
              edit={this.props?.location?.state?.Id??null}
              oldData={this.props.practitionerbyid}
              error={this?.state?.error}
            />
            {/* PRACTITIONER DETAILS */}
            <PatientNames
              parent_id={'PatientNames'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
              error={this?.state?.error}
            />

            {/* Practitioner Description */}
            <PatientCommunication
              parent_id={'Patientcommunication'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
              error={this?.state?.error}
            />
            {/* CONTACT DETAILS */}
            <PatientCotactPoint
              parent_id={'Patientcotactpoint'}
              // subtitle_message={"  ( At leaset add one email )"}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
              error={this?.state?.error}
            />
            {/* Practitioner By Role */}
            <Practitionerbyrole
              parent_id={'Practitionerbyrole'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
              error={this?.state?.error}
            />

            {/* ADDRESS */}
            <PatientAddress
              parent_id={'PatientAddress'}
              allMasters={this?.state?.allMasters}
              countryDropdownData={this?.state?.countryDropdownData}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* QUALIFICATION */}
            <Qualification
              parent_id={'Qualification'}
              saveto={"patientData"} setTo={"setPatientData"} />

            {/* biography */}
            <Biography
              parent_id={'Biography'}
              saveto={"patientData"} setTo={"setPatientData"} />

            {/* Publication÷ */}
            <Publication
              parent_id={'Publication'}
              saveto={"patientData"} setTo={"setPatientData"} />

            {/* Training */}
            <Training
              parent_id={'Training'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* Job Experience */}
            <JobExp
              parent_id={'JobExp'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* Awards / Achievements */}
            <Awards
              parent_id={'Awards'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />
            <Div
              id={'addpractitioner-buttons-div'}
              className="p-continue-action">
              <Button
                id={'addpractitioner-Cancel-button'}
                variant="text"
                className={"topNav-actions cancelBtn"}
                onClick={() => {
                  this.backHandle()
                }}
              >
                Cancel
              </Button>
              <Button
                id={'addpractitioner-Update-Save-button'}
                variant={"contained"}
                color={"primary"}
                // disabled={true}
                disabled={this.props?.practitioner_inset?.loading ?? false}
                onClick={() => this.successfun()}
                style={{ marginLeft: 10 }}
              >
                {this.props?.location?.state?.Id ? (
                  <>
                    {this.props?.practitioner_inset?.loading
                      ? "Updating..."
                      : "Update"}
                  </>
                ) : (
                  <>
                    {this.props?.practitioner_inset?.loading
                      ? "Saving..."
                      : "Save"}
                  </>
                )}
              </Button>
            </Div>
          </Div>
        )}
      </Div>
    );
  }
}

// export default withRouter(withAllContexts(Addpractitioner));
const mapStateToProps = (state) => ({
  // oraganizationRegisterPatient: state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  practitioner_inset: state?.practitionerSlice?.practitioner_upsert,
  practitionerbyid: state?.practitionerMasterSlice?.practitionerbyid,
  practitioner_masters: state?.practitionerMasterSlice?.practitioner_masters,
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(withHoverDrawer(Addpractitioner))));
