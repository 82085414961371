import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,

    "& .MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .MuiAppBar-root": {
      width: "80%",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px 12px 0px 12px",
    //paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
  },
  tab: {
    fontSize: "12px",
    textTransform: "unset",
    fontFamily: "poppinsemibold",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: "20px",
  },
  title: {
    paddingTop: "14px",
    paddingLeft: "25px",
    paddingBottom:"10px"
  },
  withbackbtn: {
    paddingTop: "14px",
    paddingLeft: "25px",
    display: "grid",
    gridTemplateColumns: "30px 1fr",
    paddingBottom: "14px",
  },
  backicon: {
    borderRadius: "18px",
    background: "#e6e6e6",
    cursor: "pointer",
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          id={`simple-tabpanel-box`}
          className={classes.box}
          style={{ ...props?.boxStyles }}
        >
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CommonTabList(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { title, list, backbtn, backFun = () => null, parent_id } = props;
  console.log(list, "lsdkdkkkett");
  let location = useLocation();
  useEffect(() => {
    // debugger
    if (location?.state) {
      setValue(location?.state?.index ? location?.state?.index : 0);
    }
    // eslint-disable-next-line
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      id={`${parent_id}_tab_grid`}
      container
      spacing={0}
      style={{ background: "#f6f6f6" }}
    >
      <Grid item md={12}>
        <div id={`${parent_id}_tab_div`} className={classes.root}>
          <div style={{ display: "flex" }}>
            {title.length > 0 && (
              <div
                id={`${parent_id}_tab_sub_div`}
                className={backbtn ? classes.withbackbtn : classes.title}
                style={{ minWidth: backbtn ? 258 : "200px" }}
              >
                {backbtn && (
                  <NavigateBeforeIcon
                    id={`${parent_id}_tab_backFun_icon`}
                    className={classes.backicon}
                    onClick={backFun}
                  />
                )}
                <Typography
                  id={`${parent_id}_tab_backFun_${title?.replaceAll(
                    " ",
                    "-"
                  )}_Typography`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "15px",
                    textAlign: "left",
                    padding: "0px 10px",
                  }}
                >
                  {title}
                </Typography>
              </div>
            )}
            {list.length > 0 && (
              <AppBar
                id={`${parent_id}_tab_backFun_appbar`}
                position="static"
                color="default"
                style={{ boxShadow: "none", zIndex: "auto" }}
              >
                {
                  <Tabs
                    id={`${parent_id}_tab_backFun_Tabs`}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.root}
                  >
                    {list.map((val, i) => (
                      <Tab
                        id={`${parent_id}_${i}_tab_${val.name.replaceAll(
                          " ",
                          "-"
                        )}_Tab`}
                        className={classes.tab}
                        label={val.name}
                        {...a11yProps(i)}
                      />
                    ))}
                  </Tabs>
                }
              </AppBar>
            )}
          </div>
          {list.map((val, i) => (
            <TabPanel
              id={`${parent_id}_${i}_tab_${val.component}_TabPanel`}
              value={value}
              index={i}
              style={{ padding: "0px" }}
              boxStyles={props?.styles}
            >
              {val.component}
            </TabPanel>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default CommonTabList;
