import React, { useContext, useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import { CommonTabList } from "../../common";
import { Button, Grid, Typography } from '@material-ui/core';
import {Col, Div, H6, Paper, Row } from 'qdm-component-library'
import { SelectBox } from '../../common/smartForm/component';
import { CommonTable } from '../..';
import { AlertProps, AxiosCall, extractIdAndValue } from '../../../utils';
import { AlertContext } from '../../../contexts';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600,
    },
    content: {
      width: '100%',
      height: 'calc(100vh - 64px)',
      overflow: "auto",
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 56
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0
      },
    //   marginTop:"60px",
    //   marginLeft:"10px",
    },
    topNavbar: {
    },
    sideNavbar: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    hovarNavBar: {
    display: "flex",
     position:"relative",
     top:"63px",
  },
  }));

const RefRights = (props) => {
    const alert = useContext(AlertContext);
    let parent_id;
    const classes = useStyles({ props });

    /** Drop Down List States */
    const [options,setOptions] = useState([]);
    const [facility,setFacility] = useState([]);
    const [practType,setPractType] = useState([]);
    const [pract,setPract] = useState([]);
    const [userRoles,setUserRoles] = useState([]);

    /** Fields States*/
    const [state,setState] = useState({
        facilityid: {},
        facilitytype: {},
        practitionerid: {},
        practitionertype: {},
        specialty: {},
        userpermission: []
      });
    const [rights,setRights] = useState({
        userRole:{},
        access:true,
        index:""
    });  

    const [isNew,setisNew] = useState(true);
    const [error,setError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isAlertOpen, setisAlertOpen] = useState(false);

    const getOrgTypeAndSpeciality = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                type: [
                    "SPECIALTY",
                    "ORGTYPE"
                ],
                tenantid: process.env.REACT_APP_TENANTID,
                facilityid: process.env.REACT_APP_FACILITY_ID,
                lang: "en"
                },
                queryid: process.env.REACT_APP_QUERY_GENERAL_MASTER
            }
            let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
            if(Resp){
                setOptions(Resp);
            }
        } catch(e) {
            console.error(e)
        }

    }
    const getUserRoles = async (id) => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                queryid: process.env.REACT_APP_GET_USER_ROLES
            }
            let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
            if(Resp){
                let result = []
                if (Array.isArray(Resp)) {
                    Resp?.map((v,i)=>{
                        let obj = {
                            _id:v?._id,
                            label:v?.rolename,
                            value:v?.rolename
                        }
                        result.push(obj)
                    })
                    setUserRoles(result);
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const getFacililityById = async (id) => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                  orgtype: id
                },
                queryid: process.env.REACT_APP_GET_FACILITY
            }
            let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
            let result = []
            if (Array.isArray(Resp)) {
                Resp?.map((v,i)=>{
                    let obj = {
                        _id:v?._id,
                        label:v?.name,
                        value:v?.name
                    }
                    result.push(obj)
                })
                setFacility(result);
            }
        } catch (e) {
            console.error(e)
        }
    }
    const getPractTypeById = async (id) => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    specialityid: id ?? ""
                },
                queryid: process.env.REACT_APP_GET_PRACT_TYPE
            }
            let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
            let result = []
            if (Array.isArray(Resp)) {
                Resp?.map((v,i)=>{
                    let obj = {
                        _id:v?._id,
                        label:v?.value,
                        value:v?.value
                    }
                    result.push(obj)
                })
                setPractType(result);
            }
        } catch (e) {
            console.error(e)
        }
    }
    const getPractById = async (id,speId) => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    specialityid: speId ?? "",
                    roleid: id ?? "",
                    locationid: ""
                },
                queryid: process.env.REACT_APP_GET_PRACT
            }
            let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
            let result = []
            if (Array.isArray(Resp)) {
                Resp?.map((v,i)=>{
                    let obj = {
                        _id:v?.id,
                        label:v?.name,
                        value:v?.name
                    }
                    result.push(obj)
                })
                setPract(result);
            }
        } catch (e) {
            console.error(e);
        }
    }
    const handleChange = async (e,v,type,index) => {
        if(type==="userpermission"){
            if(index){
                let i = Number(index)
                let  userPerData = state?.userpermission
                userPerData[i] = v
                setState({
                    ...state,
                    [type]: userPerData
                })
                setRights({ ...rights, userRole:{},access:true,index:"" })
                setisNew(false)
            } else {
                if (state?.userpermission?.some(item => item.permissionrole._id === v.permissionrole._id)) {
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.warning,
                        msg: "Existing User Role Can't Added Again!",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                } else {
                    let  userPerData = state?.userpermission
                    userPerData.push(v);
                    setState({
                        ...state,
                        [type]: userPerData
                    })
                    setRights({ ...rights, userRole:{},access:true })
                    setisNew(false)
                }
            }
        } else {
            setState({
                ...state,
                [type]: v
            })
        }   
        if(type === "facilitytype"){
            setState({
                ...state,
                facilitytype:v,
                facilityid: {},
            })
            getFacililityById(v?._id)
        }
        if(type === "specialty"){
            setState({
                ...state,
                specialty: v,
                practitionerid: {},
                practitionertype: {},
            })
            getPractTypeById(v?._id)
            if(!v?._id){
                getPractById(v?._id,"")
            }
        }
        if(type === "practitionertype"){
            setState({
                ...state,
                practitionertype:v,
                practitionerid: {},
            })
            getPractById(v?._id,state?.specialty?._id)
        }

    }
    const isDuplicate = async (facilityid,facilitytype,practitionertype,practitionerid,specialty,mode,_id) => {
        try {
            let checkDuplicatePayload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                  facilityid: facilityid ?? "",
                  facilitytype: facilitytype ?? "",
                  practitionertype: practitionertype ?? "",
                  practitionerid: practitionerid ?? "",
                  specialty: specialty ?? "",
                  mode: mode ?? "",
                  erefrightsid: _id ?? ""
                },
                queryid: process.env.REACT_APP_CHECK_DUPLICATE
            }
            let Resp = await AxiosCall(checkDuplicatePayload,process.env.REACT_APP_READ_QDM_QUERY)
            return Resp?.[0]
        } catch (e) {
            console.error(e)
        }
    }
    const handleSave = async () => {
        try {
            let constructUserPermission = []
            let eref_id = ""
            let currentData = {
                facilityid: props?.refRights?.facilityid,
                facilitytype: props?.refRights?.facilitytype,
                practitionerid: props?.refRights?.practitionerid,
                practitionertype: props?.refRights?.practitionertype,
                specialty: props?.refRights?.specialty,
            }
            let tempState = {...state};
            delete tempState.userpermission;
            if(JSON.stringify(currentData)===JSON.stringify(tempState)){
                eref_id = props?.refRights?._id
            }else{
                if(!state?.specialty?._id || !state?.practitionerid?._id || !state?.practitionertype?._id){
                    eref_id = props?.refRights?._id
                } else {
                    eref_id = ""
                }
            }
            if(state?.facilityid?._id && state?.facilitytype?._id && state?.userpermission?.length > 0){
                
                let checkDuplicate = await isDuplicate(state?.facilityid?._id,state?.facilitytype?._id,
                    state?.practitionertype?._id,state?.practitionerid?._id,state?.specialty?._id,
                    props?.refRights?._id ? "edit" : "add",eref_id
                );
                if(!checkDuplicate){
                    setisAlertOpen(false)
                    setIsSaving(true)
                    state?.userpermission?.map((v,i)=>{
                        let obj = {
                            permissionrole : v?.permissionrole?._id,
                            permission : v?.permission
                        }
                        constructUserPermission.push(obj)
                    })
                    let payload = {
                        facilityid: state?.facilityid?._id ?? "",
                        facilitytype: state?.facilitytype?._id ?? "",
                        practitionerid: state?.practitionerid?._id ?? "",
                        practitionertype: state?.practitionertype?._id ?? "",
                        specialty: state?.specialty?._id ?? "",
                        userpermission: constructUserPermission
                    }
                    let filter = { filter: { _id: props?.refRights?._id } };
                    let masterPayload = []
                    let data = {
                        db_name: process.env.REACT_APP_DATABASE_NAME,
                        entity: process.env.REACT_APP_ENTITY_NAME,
                        is_metadata: true,
                        metadataId: process.env.REACT_APP_METADATAID,
                        metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
                        ...filter,
                        doc: payload
                    };
                    masterPayload.push(data)
                    let Resp = await AxiosCall(masterPayload,process.env.REACT_APP_UPSERT_DOC)
                    if(Resp.Code === 201){
                        setIsSaving(false)
                        setisNew(true)
                        setState({
                            facilityid: "",
                            facilitytype: "",
                            practitionerid: "",
                            practitionertype: "",
                            specialty: "",
                            userpermission: []
                        })
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: props?.refRights?._id ? "Update Successfully" : "Saved Successfully!",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        props?.setrefRightsList({
                            isSavedNew:true
                        })
                        props?.setisFormOpen({
                            ...props?.isFormOpen,
                            isForm:false,
                            isView:false,
                            isEdit : false,
                        })
                    } else {
                        setIsSaving(false)
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Failed to Save!",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                    }
                } else {
                    setisAlertOpen(true)
                }
            } else {
                setError(true);
                alert?.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: state?.facilityid?._id && state?.facilitytype?._id && 
                    state?.userpermission?.length < 1 ? "Please Add Minimum 1 Rights" : "Please fill mandatory fields",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                    tone: true,
                  });
            }
        } catch (e) {
            console.error(e)
        }
    }
    const condition = (value, type) => {
        if (type === "DELETE") {
            if (value._id) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }
    const handleDelete = (e, data, index) => {
        let list = JSON.parse(JSON.stringify(state?.userpermission));
        list.splice(index, 1)
        setState({
            ...state,
            userpermission: list
        })
    }
    const handleListEdit = (index) => {
        let getSingleUserPermission = state?.userpermission[index];
        setisNew(true)
        setRights({ ...rights, userRole:getSingleUserPermission?.permissionrole,access:getSingleUserPermission?.permission,index:String(index) })
        
    }
    const handleCancel = () => {
        props?.setisFormOpen({
            ...props?.isFormOpen,
            isForm:false,
            isView:false,
            isEdit:false,
        })
        setState({
            facilityid: "",
            facilitytype: "",
            practitionerid: "",
            practitionertype: "",
            specialty: "",
            userpermission: []
        })
        props?.setrefRights({
            _id:"",
            facilitytype:"",
            facilityid:"",
            specialty:"",
            practitionertype:"",
            practitionerid:"",
            userrole:[],
        })
    }

    useEffect(()=>{
        getOrgTypeAndSpeciality();
        getUserRoles();
    },[])

    useEffect(()=>{
        if(props?.refRights?._id){
            if(props?.refRights?.userpermission?.length > 0){
                setisNew(false);
            }
            getFacililityById(props?.refRights?.facilitytype?._id)
            getPractById(props?.refRights?.practitionertype?._id,props?.refRights?.specialty?._id)
            getPractTypeById(props?.refRights?.specialty?._id)
            setState({
                facilityid: props?.refRights?.facilityid,
                facilitytype: props?.refRights?.facilitytype,
                practitionerid: props?.refRights?.practitionerid,
                practitionertype: props?.refRights?.practitionertype,
                specialty: props?.refRights?.specialty,
                userpermission: props?.refRights?.userpermission,
              })
        }
    },[props?.refRights])
    
  return (
      <div>
      {/** Tab Heading */}
        <Div>
            <CommonTabList
                parent_id={'admintabpanel'}
                title="REFERRAL RIGHTS DEFINITION"
                list={[]}
                backbtn={true}
                backFun={()=>{
                    props?.setisFormOpen({
                        ...props?.isFormOpen,
                        isForm:false,
                        isView:false,
                        isEdit:false,
                    })
                    props?.setrefRights({
                        _id:"",
                        facilitytype:"",
                        facilityid:"",
                        specialty:"",
                        practitionertype:"",
                        practitionerid:"",
                        userrole:[],
                    })
                }}
            />
        </Div>
        <Div className='pi-paper'>
            <Grid container spacing={2} direction='column'>
            {/** Referal Rights Fields */}
                <Grid item>
                    <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                        <Div id={`${parent_id}-parent-sub-div`}>
                        <H6 id={`${parent_id}-title-h6`} className="pi-title">
                            {"REFERRAL RIGHTS"}
                        </H6>
                        </Div>
                        <Row>
                        {/** Facility Type */}
                        <Col lg={4} inLineStyles={{ padding: 10 }}>
                            <Typography
                            style={{
                                fontSize: "12px",
                                Fontfamily: "pc_regular",
                                color: "#6F6F6F",
                            }}
                            variant="body1"
                            >
                            Facility Type<span style={{ color: "red" }}> *</span>
                            </Typography>
                            <SelectBox
                            id={`${parent_id}-facilitytype-selectBox`}
                            list={extractIdAndValue("ORGTYPE",options)}
                            onchange={(e,v) => {
                                handleChange(e,v,"facilitytype");    
                            }}
                            placeholder={"select..."}
                            error={error && !state?.facilitytype?._id}
                            // disableClearable
                            disabled={props?.isFormOpen?.isView || props?.isFormOpen?.isEdit}
                            required={true}
                            value={state?.facilitytype}
                            />
                        </Col>
                        {/** Facility */}
                        <Col lg={8} inLineStyles={{ padding: 10 }}>
                            <Typography
                            style={{
                                fontSize: "12px",
                                Fontfamily: "pc_regular",
                                color: "#6F6F6F",
                            }}
                            variant="body1"
                            >
                            Facility<span style={{ color: "red" }}> *</span>
                            </Typography>
                            <SelectBox
                            id={`${parent_id}-facility-selectBox`}
                            list={facility ?? []}
                            placeholder={"select..."}
                            // disableClearable
                            required={true}
                            value={state?.facilityid}
                            disabled={props?.isFormOpen?.isView || props?.isFormOpen?.isEdit}
                            error={error && !state?.facilityid?._id}
                            onchange={(e,v) => {
                                handleChange(e,v,"facilityid");    
                            }}
                            />
                        </Col>
                        {/** Speciality */}
                        <Col lg={4} inLineStyles={{ padding: 10 }}>
                            <Typography
                            style={{
                                fontSize: "12px",
                                Fontfamily: "pc_regular",
                                color: "#6F6F6F",
                            }}
                            variant="body1"
                            >
                            Speciality
                            </Typography>
                            <SelectBox
                            id={`${parent_id}-speciality-selectBox`}
                            list={extractIdAndValue("SPECIALTY",options)}
                            placeholder={"select..."}
                            // disableClearable
                            disabled={props?.isFormOpen?.isView}
                            value={state?.specialty}
                            onchange={(e,v) => {
                                handleChange(e,v,"specialty");    
                            }}
                            />
                        </Col>
                        {/** pract Type */}
                        <Col lg={4} inLineStyles={{ padding: 10 }}>
                            <Typography
                            style={{
                                fontSize: "12px",
                                Fontfamily: "pc_regular",
                                color: "#6F6F6F",
                            }}
                            variant="body1"
                            >
                            Practitionar Type
                            </Typography>
                            <SelectBox
                            id={`${parent_id}-Practitionartype-selectBox`}
                            list={practType}
                            placeholder={"select..."}
                            // disableClearable
                            disabled={props?.isFormOpen?.isView}
                            value={state?.practitionertype}
                            onchange={(e,v) => {
                                handleChange(e,v,"practitionertype");    
                            }}
                            />
                        </Col>
                        {/** pract */}
                        <Col lg={4} inLineStyles={{ padding: 10 }}>
                            <Typography
                            style={{
                                fontSize: "12px",
                                Fontfamily: "pc_regular",
                                color: "#6F6F6F",
                            }}
                            variant="body1"
                            >
                            Practitionar
                            </Typography>
                            <SelectBox
                            id={`${parent_id}-Practitionar-selectBox`}
                            list={pract}
                            placeholder={"select..."}
                            // disableClearable
                            disabled={props?.isFormOpen?.isView}
                            value={state?.practitionerid}
                            onchange={(e,v) => {
                                handleChange(e,v,"practitionerid");    
                            }}
                            />
                        </Col>
                        </Row>
                    </Paper>
                </Grid>
            {/** Referal Rights Access */}
                <Grid item>
                    <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                     {/** Rights Access Heading & Add new button */}
                        <Grid container justifyContent='space-between'>
                            <Grid item >
                                <Div id={`${parent_id}-parent-sub-div`}>
                                    <H6 id={`${parent_id}-title-h6`} className="pi-title">
                                        {"RIGHTS"}
                                    </H6>
                                </Div>
                            </Grid>
                            {!props?.isFormOpen?.isView && (   
                            <Grid item> 
                                <Div id={`${parent_id}-parent-sub-div`} onClick={()=>setisNew(true)}>
                                    <Button
                                    id={`${parent_id}-Add-New-button`}
                                    className={classes.addBtn}
                                    onClick={()=>setisNew(true)}
                                    color="primary"
                                    >
                                        + Add New
                                    </Button>
                                </Div>
                            </Grid>
                            )}
                        </Grid>
                     {/**Rights Access Fields */}
                     {isNew && (
                        <Grid container style={{padding:"10px 0"}} justifyContent='space-between' alignItems='center' xs={12}>
                            <Grid item xs={6}>
                                <Grid container spacing={6} xs={12} alignItems='center'>
                                    <Grid item xs={10}>
                                        <Typography
                                        style={{
                                            fontSize: "12px",
                                            Fontfamily: "pc_regular",
                                            color: "#6F6F6F",
                                        }}
                                        variant="body1"
                                        >
                                            User Role<span style={{ color: "red" }}> *</span>
                                        </Typography>
                                        <SelectBox
                                        id={`${parent_id}-facilitytype-selectBox`}
                                        list={userRoles}
                                        placeholder={"select..."}
                                        // disableClearable
                                        value={rights?.userRole ?? ""}
                                        onchange={(e,v) => setRights({ ...rights, userRole:v })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                    
                                        <Typography
                                        style={{
                                            fontSize: "12px",
                                            Fontfamily: "pc_regular",
                                            color: "#6F6F6F",
                                        }}
                                        variant="body1"
                                        >
                                            Rights<span style={{ color: "red" }}> *</span>
                                        </Typography>
                                        <Switch
                                            checked={rights?.access}
                                            onClick={(e) => setRights({ ...rights, access:e.target.checked })}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        {/* <SelectBox
                                        id={`${parent_id}-facilitytype-selectBox`}
                                        list={[{id:true,value:"Yes",label:"Yes"},{id:false,value:"No",label:"No"}]}
                                        placeholder={"select..."}
                                        disableClearable
                                        value={rights?.access ?? ""}
                                        onchange={(e,v) => setRights({ ...rights, access:v })}
                                        /> */}
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/**Rights Access save & clear button */}              
                            <Grid item  xs={6}> 
                                <Grid container alignItems='center' style={{marginTop : "10px"}} spacing={2}>
                                    <Grid item>
                                        <Div id={`${parent_id}-parent-sub-div`}>
                                            <Button
                                            id={`${parent_id}-Add-New-button`}
                                            // className={classes.addBtn}
                                            onClick={() => {
                                                if(rights?.index && rights?.userRole?.value){
                                                    let i = Number(rights?.index)
                                                    let getSinglePermissionData = state?.userpermission[i]
                                                    if(state?.userpermission?.some(item => item?.permissionrole?._id === rights?.userRole?._id && 
                                                        item.permissionrole._id !== getSinglePermissionData.permissionrole._id)) {
                                                        alert?.setSnack({
                                                            open: true,
                                                            severity: AlertProps.severity.warning,
                                                            msg: "Existing User Role Can't Added Again!",
                                                            vertical: AlertProps.vertical.top,
                                                            horizontal: AlertProps.horizontal.right,
                                                            tone: true,
                                                        });
                                                    } else {
                                                        getSinglePermissionData.permission =rights?.access
                                                        getSinglePermissionData.permissionrole =rights?.userRole
                                                        getSinglePermissionData.permissionName =rights?.access ? "Yes" : "No"
                                                        handleChange("",getSinglePermissionData,"userpermission",rights?.index)
                                                    }
                                                } else if (rights?.userRole?.value){
                                                    let obj = {
                                                        permissionrole:rights?.userRole,
                                                        permission:rights?.access,
                                                        permissionName:rights?.access ? "Yes" : "No"
                                                    }
                                                    handleChange("",obj,"userpermission")
                                                } else {
                                                    alert?.setSnack({
                                                        open: true,
                                                        severity: AlertProps.severity.error,
                                                        msg: "Please Select the User Role and Save!",
                                                        vertical: AlertProps.vertical.top,
                                                        horizontal: AlertProps.horizontal.right,
                                                        tone: true,
                                                    });
                                                }
                                            }}
                                            color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Div>
                                    </Grid>
                                    <Grid item>
                                        <Div id={`${parent_id}-parent-sub-div`}>
                                            <Button
                                            id={`${parent_id}-Add-New-button`}
                                            // className={classes.addBtn}
                                            onClick={() => setRights({ ...rights, userRole:{},access:true })}
                                            color="primary"
                                            >
                                                Clear
                                            </Button>
                                        </Div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                     )}
                    {/**Rights Access Grid view */}
                        <Grid container style={{paddingTop:"10px"}}>    
                        <CommonTable
                            parent_id={"category_details"}
                            handleEdit={(e, data, index) => {
                                handleListEdit(index);
                            }}

                            handleDelete={handleDelete}
                            rightAlign={["Action"]}
                            Header={["User Role", "Rights", "Action"]}
                            dataList={state?.userpermission}
                            tableData={[
                                // { type: ["INCRIMENT"], name: "1" },
                                { type: ["TEXT"], name: "permissionrole", optionLabel: "label" },
                                { type: ["TEXT"], name: "permissionName" },
                                props?.isFormOpen?.isView ? {} : { type: ["EDIT", "DELETE"], name: "", align: "right" },
                            ]}
                            condition={condition}
                        />
                        </Grid>
                    </Paper>
                </Grid>
            {/**Save and cancel button */}
                {!props?.isFormOpen?.isView && (
                    <Grid
                    id={"agerange-form-rangedetails-grid"}
                    style={{ padding: "20px" }}
                    >
                        <Button
                            id={"agerange_form_rangedetails_Save_button"}
                            onClick={isSaving ? null : handleSave}
                            style={{ float: "right" }}
                            variant="contained"
                            color="primary"
                        >
                            {props?.refRights?._id ? isSaving ? "Updating..." : "Update" : isSaving  ? "Saving..." :"Save"}
                        </Button>
                        <Button
                            id={"agerange_form_rangedetails_Cancel_button"}
                            onClick={handleCancel}
                            style={{ float: "right", marginRight: "20px" }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                    </Grid>
                )}  
            </Grid>
        </Div>
            <Dialog
                open={isAlertOpen}
                keepMounted
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title" >
                    <Typography style={{ display: 'flex' }}>
                    <ErrorOutlineOutlinedIcon style={{ width: 22, height: 32, marginRight: 7, color: "#F58B00" }}/>
                    <Typography variant='span' style={{ paddingTop: 5, fontWeight: "bold",}}>{"Warning"}</Typography>
                    </Typography>
                    <CloseIcon onClick={() => setisAlertOpen(false)} style={{ float: "right", marginTop: "-26px", cursor: "pointer" }} />
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-description" style={{ border: `1.5px solid "#F58B00"`, borderRadius: "8px" }}>
                        <Typography style={{
                            background: "#FEF3E5",
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            padding: "8px 11px", color: "#F58B00",
                            fontWeight: 800, minWidth: 500
                        }}>Message</Typography>
                        <Typography style={{ padding: "10px 10px 15px 10px" }}>
                            {"The entered data with the assigned rights already exists and has been saved. Duplicate entries are not allowed."}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: "5px 23px 17px", justifyContent: "flex-end" }}>
                    <Button variant='contained' onClick={() => setisAlertOpen(false)} style={{ color: "white", background: "#F58B00" }}>
                        {"I Understand"}
                    </Button>
                </DialogActions>
            </Dialog>

      </div>
  )
}

export default RefRights