import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  // TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AxiosCall, extractIdAndValue } from "../../../utils";
import Config from "../../../config";
import { AlertContext } from "../../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  formParent: {
    paddingRight: 10,
  },
}));
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
  outlinedDelButton: {
    borderColor: "#FF4D4A",
    color: "#FF4D4A",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  modalHeading: {
    fontSize: 16,
    color: "#2A60BC",
    // marginBottom: 10,
  },
  outlinedButton: {
    borderColor: "#DEDEDE",
    color: "#2A60BC",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
};
const { labelStyle, borderStyle, errorBorderStyle } = styles;

const BookingSource = (props) => {
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const {
    state = {},
    handlechange,
    parent_id,
    SelectorPatientAuto,
    finalValue,
    setFinalValue,
    error,
  } = props;
  const [bookingSources, setBookingSources] = useState([]);
  const [userRoles,setUserRoles] = useState([]);
  const [workQueue,setWorkQueue] = useState([]);


  const getBookingSourceValue = () => {
    const axios = require("axios");
    let data = JSON.stringify({
      db_name: "primarycareng",
      filter: {
        type: ["BOOKSOURCE"],
        tenantid: "",
        facilityid: "",
        lang: "",
      },
      queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setBookingSources(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserRoles = async (id) => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            queryid: process.env.REACT_APP_GET_USER_ROLES
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
            let result = []
            if (Array.isArray(Resp)) {
                Resp?.map((v,i)=>{
                    let obj = {
                        _id:v?._id,
                        label:v?.rolename,
                        value:v?.rolename
                    }
                    result.push(obj)
                })
                setUserRoles(result);
            }
        }
    } catch (e) {
        console.error(e)
    }
  }

  const geWorkQueue = async (id) => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            queryid: Config.getWorkQueueQueryID
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
            let result = []
            if (Array.isArray(Resp)) {
                Resp?.map((v,i)=>{
                    let obj = {
                        _id:v?._id,
                        label:v?.queuename,
                        value:v?.queueid
                    }
                    result.push(obj)
                })
                setWorkQueue(result);
            }
        }
    } catch (e) {
        console.error(e)
    }
  }

  const handleChangeList = (key,val) => {
    if (finalValue?.userroles?.length > 0) {
      const seen = new Set();
      const filteredArr = val.filter((el) => {
        const duplicate = seen.has(el.label);
        seen.add(el.label);
        return !duplicate;
      });
      setFinalValue({
        ...finalValue,
        [key]: filteredArr,
      })
    } else {
      setFinalValue({
        ...finalValue,
        [key]: val,
      })
    }
  }

  useEffect(() => {
    getBookingSourceValue();
    getUserRoles();
    geWorkQueue();
  }, []);
  return (
    <div>
      <Paper id={`${parent_id}-BookingSource-paper`} className={classes.root}>
        <Typography
          id={`${parent_id}-BookingSourceCategory Type-title-typography`}
          className={classes.title}
          variant="h6"
        >
          BOOKING SOURCE DETAILS
        </Typography>
        <Grid
          id={`${parent_id}-BookingSourceCategory-grid`}
          className={classes.form}
          container
        >
          <Grid
            id={`${parent_id}-BookingSourceCategory-title`}
            className={classes.formParent}
            lg={4}
            md={4}
            item
          >
            <Typography
              id={`${parent_id}-BookingSourceCategory-title-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Booking Source
              <span id={`${parent_id}-Code-star-span`} style={{ color: "red" }}>
                *
              </span>
            </Typography>
            <SelectBox
              list={extractIdAndValue("BOOKSOURCE", bookingSources)}
              onchange={(e, v) =>
                setFinalValue({
                  ...finalValue,
                  bookingsource: v,
                })
              }
              value={props?.finalValue?.bookingsource}
              error={error && !props?.finalValue?.bookingsource?.value}
              disableClearable

              // disabled={Boolean(editData)}
              //   defaultValue={state?.Patientcategory}

              // error={props.error?.applicable_type}
            />
          </Grid>
          <Grid
            id={`${parent_id}-UserRoleCategory-title`}
            className={classes.formParent}
            lg={4}
            md={4}
            item
          >
            <Typography
              id={`${parent_id}-UserRoleCategory-title-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              User Roles
              <span id={`${parent_id}-Code-star-span`} style={{ color: "red" }}>
                *
              </span>
            </Typography>
            <SelectBox
                parent_id={"UserRoleCategory-select"}
              // disabled={Boolean(editData)}
              //   defaultValue={state?.Patientcategory}
              multi={true}
              list={userRoles ?? []}
              onchange={(e,v) =>
                handleChangeList("userroles",v)
              }
              value={finalValue?.userroles}
              error={error && !finalValue?.userroles.length}
              // list={[]}
                // value={state?.UserRoleCategory ?? []}
              //   onchange={(e, v) => handlechange("UserRoleCategory", [v], true, v)}
              // error={props.error?.applicable_type}
            />
          </Grid>
          <Grid
            id={`${parent_id}-BookingSourceCategory-title`}
            className={classes.formParent}
            lg={4}
            md={4}
            item
          >
            <Typography
              id={`${parent_id}-BookingSourceCategory-title-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Work Queue
              <span id={`${parent_id}-Code-star-span`} style={{ color: "red" }}>
                *
              </span>
            </Typography>
            <SelectBox
              list={workQueue ?? []}
              onchange={(e, v) =>
                setFinalValue({
                  ...finalValue,
                  workQueue: v,
                })
              }
              value={props?.finalValue?.workQueue}
              error={error && !props?.finalValue?.workQueue?.value}
              disableClearable

              // disabled={Boolean(editData)}
              //   defaultValue={state?.Patientcategory}

              // error={props.error?.applicable_type}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default BookingSource;
