import React from "react";
import {
  makeStyles,
  Typography,
  Button,
  InputAdornment,
  InputBase,
  TextField,
} from "@material-ui/core";
import { SearchWithFilter } from '../.././searchFilter';
import { withStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    fontFamily: "poppinsemibold",
    fontSize: "16px",
  },
  btn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    padding: "6px 16px",
    fontFamily: "poppin",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "none",
    borderRadius: "10px",
    width: "129px",
    "&:hover": {
      backgroundColor: "#0e5ab2",
    },
  },
  filterselectDiv: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    marginRight: "1%",
    border: "1px solid #E0E0E0",
  },
  filterselect: {
    backgroundColor: "white",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const SearchInputBox = withStyles((theme) => ({
  root: {
    flex: 1,
    gap: 5,
    padding: "4px 8px",
    marginRight: "10px",
    fontSize: 14,
    fontWeight: 300,
    fontFamily: "pc_medium",
    background: "#ECF0F8",
    borderRadius: "6px",
    border: "1px solid #DEE5EC",
    "& .MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

const CommonHeader = (props) => {
  const classes = useStyles();
  const {
    btnFun = () => null,
    value,
    parent_id,
    searchable,
    searchTitle = "Search here...",
    search = "",
    handleSearch,
  } = props;
  return (
    <div id={`${parent_id}-header-div`}>
      <div
        id={`${parent_id}-header-sub-div`}
        style={{ display: "flex", paddingBottom: "10px" }}
      >
        <Typography
          id={`${parent_id}-header-title-typography`}
          className={classes.header}
          variant="h6"
        >
          {props.title}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        {/* {searchable && (
          <SearchInputBox
            id={`${parent_id}_search_input`}
            placeholder={searchTitle}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Search htmlColor="#6A7888" fontSize="medium" />
              </InputAdornment>
            }
          />
        )} */}

        {props.showFilterDropDown && (
          <div
            id={`-Entity-Type-div`}
            className={classes.filterselectDiv}
          >
            <span
              id={`-Entity-Type-span`}
              style={{paddingLeft: 12,color: "#6F6F6F" }}
            >
              {props?.FilterTitle}
            </span>
            <Autocomplete
              id={`-Entity-Type-autocomplete`}
              options={
                props?.filterList ?? []
              }
              getOptionLabel={(option) => option.label}
              style={{ width: 200, border: "none" }}
              size={"small"}
              className={classes.filterselect}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(e, values) => props?.onFilterChange(props?.FilterTitle,e, values)}
            />
          </div>
        )} 

        <SearchWithFilter
            id={props?.id}
            title={props?.title}
            placeholder={props?.placeholder}
            loading={props?.serachLoading}
            onSearchChange={props?.onSearchChange}
            hideSearchBar={props?.hideSearchBar}
            hideFilter={props?.hideFilter ? props?.hideFilter  : true}
            onFilterClicked={props?.onFilterClicked}
            isFilterApplied={props?.isFilterApplied}
            />

        {props.BtnName && (
          <Button
            id={`${parent_id}-header-${props.BtnName.replaceAll(
              " ",
              "-"
            )}-button`}
            className={classes.btn}
            onClick={() => btnFun(value)}
            color="primary"
            variant="contained"
          >
            {props.BtnName}
          </Button>
        )}
      </div>
    </div>
  );
};
export default CommonHeader;
