import React, { useEffect } from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { GenerateForm, CommonTable } from "../../components/common";
import { SearchWithFilter } from "../../components";
import { AlertContext } from "../../contexts";
import { adminsearchqueryid, AlertProps, codingmaster, gettabledata, updatequeryid } from "../../utils";
import { set } from "date-fns";

export default function PractitionerRoleTable(props) {
  const dispatch = useDispatch();
  const { parent_id } = props;
  const alert = React.useContext(AlertContext);
  const listData = useSelector(
    (state) => state?.practitionerSlice?.practitioner_role_list?.data
  );
  const loading = useSelector(
    (state) => state?.practitionerSlice?.practitioner_role_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.practitionerSlice?.practitioner_role_upsert?.loading
  );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
    edit_index: null,
  });
  const [upsertData, setUpsertData] = React.useState([]);
  const [roleData, setRoleData] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await getTableDatas(props?.type, (currentPage - 1) * PerPage, PerPage);
    // await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props?.type,  page: (currentPage - 1) * PerPage, perPage: PerPage }));
  };

  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  // React.useLayoutEffect(() => {
  //   let type = props.type;
  //   dispatch(actions.PRACTITIONER_ROLE_READ({ type: type, page: page, perPage: perPage }));
  // }, []);
  useEffect(() => {
    getTableDatas(props.type, page, perPage);
  }, []);
  const getTableDatas = async (type, page, perpage) => {
    const axios = require("axios");
    let data = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      filter: {
        type: type,
        page: page || 0,
        perpage: perpage || 10,
      },
      queryid: gettabledata,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
       setRoleData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleEdit = (e, values, index) => {
    let data = {
      code: values?.code,
      description: values?.display,
      Type: props?.type,
      status: true,
     _id: values?._id,
      id: values?.id,
    };
    setState({
      ...state,
      edit_data: data,
      edit_index: index,
      open: true,
    });
  };

  const statusChange = async (e, val) => {
    const updatedStatus = e.target.checked;
    const filter = { _id: val?._id };
    try {
      let res = await saveForm(val, updatedStatus, filter);
      if (res?.data?.Code === 201) {
        alert.setSnack("update");
        await updatequery(
          roleData?.[0]?._id,
          upsertData?.Result?.[0]?.properties?.doc?._id
        );
        await getTableDatas(props.type, page, perPage);
      } else if (res?.data?.Code === 200) {
        alert.setSnack("error");
      }
    } catch (error) {}
  };
 const getSearchValue = (type, search) => {
  const axios = require('axios');
  let data = JSON.stringify({
    db_name: process.env.REACT_APP_DB,
    filter: {
      type: type,
      searchvalue: search || "",
    },
    queryid: adminsearchqueryid,
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_READ_QDM_QUERY,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  axios.request(config)
  .then((response) => {
    setRoleData(response?.data);
  })
  .catch((error) => {
    console.log(error);
  });
  
 }
  const onSearchChange = async (value = "") => {
    if (value?.length > 0) {
      await getSearchValue(props.type, value.trim());
    } else if (!value) {
      await getSearchValue(props?.type, "");
    }
  };
  const updatequery = async (type, value) => {
    const axios = require("axios");
    let data = JSON.stringify({
      db_name:process.env.REACT_APP_DB,
      filter: {
        codeableconcepid: type,
        codingmasterid: value,
      },
      queryid: updatequeryid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveForm = async (val, status, filter) => {
    const axios = require("axios");
    const tableData = roleData?.[0];
    const finalFilter = filter?._id ? { _id: filter._id } : (state?.edit_data ? { _id: state.edit_data._id } : {});
    const data = JSON.stringify([
      {
        db_name: process.env.REACT_APP_DB,
        entity: codingmaster,
        is_metadata: true,
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
        metadataId: process.env.REACT_APP_METADATAID,
        filter: finalFilter,
        doc: {
          system: "",
          version: "",
          code: val?.code,
          display: val?.shortdesc,
          userSelected: true,
          id: 0,
          Type: tableData?.Type,
          shortdesc: val?.shortdesc,
          status: status ?? true,
          tenantid: "",
          facilityid: "",
          privilage: "",
          description: "",
          strval: "",
        },
      },
    ]);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_UPSERT_DOC,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log(response?.data, "response///");
       setUpsertData(response?.data);
      await handleDrawerClose();
      if (!state.edit_data) {
        await updatequery(tableData?._id, response?.data?.Result?.[0]?.properties?.doc?._id);
      }
      await getTableDatas(props?.type, page, perPage);
      if (response?.data?.Code === 201) {
        alert.setSnack("success");
      } else if (response?.data?.Code === 200) {
        alert.setSnack("update");
      } else if (response?.data?.Code === 1111) {
        alert.setSnack("error", response?.data?.validation_error[0].Errormsg);
      }
    } catch (error) {}
  };
  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-button-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-button-div`}
                style={{ marginTop: "16px", float: "right", marginRight:'20px', display:'flex' }}>

                <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />

                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
                <GenerateForm
                  parent_id={'practitionerrole'}
                  nocancel
                  loading={saveloading}
                  open={state.open}
                  save={saveForm}
                  handlecancel={handleDrawerClose}
                  header={
                    state.edit_data
                      ? `Edit ${props.header}`
                      : `Add ${props.header}`
                  }
                  json={[
                    {
                      componet_type: "text",
                      label: `${props.header} code`,
                      state_name: "code",
                      value: state?.edit_data?.code ?? "",
                      required: true,
                      isUpperCase: true,
                      disabled: state.edit_data ? true : false,
                      maxlength:props.codeLength ?? 20
                    },
                    {
                      componet_type: "text",
                      label: `${props.header} description`,
                      state_name: "shortdesc",
                      value: state?.edit_data?.description ?? "",
                      required: true,
                      maxlength:props?.descriptionLength ?? 60
                    },
                    // {
                    //   componet_type: "switch",
                    //   label: "Status",
                    //   state_name: "status",
                    //   value: state?.edit_data?.status ?? false
                    // }
                  ]}
                />
              </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-circularprogress-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {/* {loading && state.edit_data === null ? (
                <div
                  id={`${parent_id}-circularprogress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress
                    id={`${parent_id}-circularprogress`}
                  />
                </div>
              ) : ( */}
                <CommonTable
                  parent_id={'practitionerrole'}
                  Header={[
                    "S.No",
                    "Type Code",
                    "Description",
                    "Status",
                    "Action",
                  ]}
                  dataList={roleData?.[0]?.coding || roleData}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "code" },
                    { type: ["TEXT"], name: "shortdesc" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={roleData?.[0]?.coding?.[0]?.TotalCount}
                  incrementCount={page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                  loading = {loading}
                />
              {/* )} */}
              {/*  */}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}
