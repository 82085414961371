import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Col,
  Div,
  H6,
  Paper,
  Row,
  TextInput,
} from "qdm-component-library";
import React, { useContext, useEffect, useState } from "react";
import { SelectBox } from "../../common/smartForm/component";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CommonTabList, CommonTable, GenerateForm } from "../../../components";
import { AlertProps, isEmptyObject } from "../../../utils";
import { AlertContext } from "../../../contexts";
import {
  constructRefTypeEditDataPayload,
  saveRefEditType,
} from "../../../screens/availabilityManagement/timeTableManagement/TimeTableManagementForm/constructJson";

const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
}));

const RefTypeForm = (props) => {
  const alert = useContext(AlertContext);
  const classes = useStyles();
  const axios = require("axios");
  const [options, setOptions] = useState([]);
  const [workflow, setWorkflow] = useState([]);
  const [Daignosis, setDaignosisDropdown] = useState([]);
  const [payer, setPayer] = useState([]);


  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    editIndex: null,
  });
  const [error, setError] = useState({});
  const [reftype, setReftype] = useState({
    refcode: "",
    reftype: "",
    workflow: {},
    spec: [],
    service:[] ,
    dignosis: [],
    payer: {},
    template: [],
  });
  const [refTemplates, setRefTemplates] = useState({
    componentname:{},
    sequence:"",
    index:""
  })
 
  const [refEditData, setRefEditData] = useState({
    resources: [],
  });
  const handleDrawerClose = () => {
    setState({ ...state, open: false, editIndex: null });
  };

  const getOptions = async () => {
    let data = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      filter: {
        type: ["SPECIALTY", "TEMPCOMPONENT", "SERVICE"],
        tenantid: "",
        facilityid: "",
        lang: "en",
      },
      queryid: process.env.REACT_APP_QUERY_GENERAL_MASTER,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setOptions(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const WorkflowOption = async () => {
    let data = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      queryid: process.env.REACT_APP_WORKFLOW_QUERYID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const dropdownOptions = response.data.map((item) => ({
          value: item.value,
          label: item.value,
          _id: item._id,
        }));
        setWorkflow(dropdownOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Diagnosis = async (val) => {
    if(val?.length > 5){
      let data = JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        filter:{
          searchdiagnosis: val
        },
        queryid: process.env.REACT_APP_DIAGNOSIS_QUERYID,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_READ_QDM_QUERY,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios.request(config).then((response) => {
        setDaignosisDropdown(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
   
  };
  const payerOption = async () => {
    let data = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      queryid: process.env.REACT_APP_PAYER_QUERY_ID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const dropdownOptions = response.data.map((item) => ({
          _id: item.value,
          label: item.label,
        }));
        setPayer(dropdownOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
   const extractIdAndValue = (type,list) => {
    let result = [];
    list?.forEach((item) => {
        item?.[type]?.Value?.map((valueItem) => {
            let obj = {
                _id: valueItem?._id,
                value: valueItem?.display,
                label: valueItem?.display,
            }
            result.push(obj);
        });
    });
    return result
  }
  const handleFormState = (name, val) => {
    if (name === "spec") {
      if (reftype?.spec?.length > 0) {
        const seen = new Set();
        const filteredArr = val.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        let updatedReftype = JSON.parse(JSON.stringify(reftype));
        updatedReftype[name] = filteredArr;
        setReftype(updatedReftype);
        setRefEditData(updatedReftype);
      } else {
        let updatedReftype = JSON.parse(JSON.stringify(reftype));
        updatedReftype[name] = val;
        setReftype(updatedReftype);
        setRefEditData(updatedReftype);
      }
    } 
    else if (name === "dignosis") {
      if (reftype?.dignosis?.length > 0) {
        const seen = new Set();
        const filteredArr = val.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        let updatedReftype = JSON.parse(JSON.stringify(reftype));
        updatedReftype[name] = filteredArr;
        setReftype(updatedReftype);
        setRefEditData(updatedReftype);
      } else {
        let updatedReftype = JSON.parse(JSON.stringify(reftype));
        updatedReftype[name] = val;
        setReftype(updatedReftype);
        setRefEditData(updatedReftype);
      }
    }  
    else if (name === "service") {
      if (reftype?.service?.length > 0) {
        const seen = new Set();
        const filteredArr = val.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        let updatedReftype = JSON.parse(JSON.stringify(reftype));
        updatedReftype[name] = filteredArr;
        setReftype(updatedReftype);
        setRefEditData(updatedReftype);
      } else {
        let updatedReftype = JSON.parse(JSON.stringify(reftype));
        updatedReftype[name] = val;
        setReftype(updatedReftype);
        setRefEditData(updatedReftype);
      }
    } 
    else {
      let updatedReftype = JSON.parse(JSON.stringify(reftype));
      updatedReftype[name] = val;
      setReftype(updatedReftype);
      setRefEditData(updatedReftype);
    }
    if (reftype?.[name]) {
      error[name] = false;
    }
  };
  const validation = () => {
    let data = {
      reftype: "",
      workflow: {},
      service:[],
    };
    let validation = {
      error: false,
    };

    Object.keys(data).forEach((v) => {
      if (typeof data[v] === "string") {
        if (!reftype[v]?.trim()) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else if (Array.isArray(data[v])) { 
        if (reftype[v].length === 0) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else if (typeof data[v] === "object") {
        if (isEmptyObject(reftype[v])) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else {
        if (
          !reftype[v] ||
          (typeof reftype[v] === "object" && isEmptyObject(state[v]))
        ) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      }
    });
    setError(validation);
    return validation;
  };
  const onSubmit = async () => {
    try {
      let valid = validation();
      if (!valid?.error) {
        if (!reftype?.template || !reftype?.template.length) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please add at least one referral template before saving",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        } 
        let specialityArray = [];
        for (let i = 0; i < reftype?.spec?.length; i++) {
          specialityArray.push(reftype?.spec?.[i]?._id);
        }
        let DaignosisArray = [];
        for (let i = 0; i < reftype?.dignosis?.length; i++) {
          DaignosisArray.push(reftype?.dignosis?.[i]?._id);
        }
        let ServiceArray = [];
        for (let i = 0; i < reftype?.service?.length; i++) {
          ServiceArray.push(reftype?.service?.[i]?._id);
        }
        let templateArray = [];
        for (let i = 0; i < reftype?.template?.length; i++) {
          templateArray.push({
            componentname: reftype?.template?.[i]?.componentname?._id,
            sequence: parseInt(reftype?.template?.[i]?.sequence),
          });
        }
        const save2 = saveRefEditType(refEditData, reftype);
        let data = [
          {
            db_name: process.env.REACT_APP_DB,
            entity: process.env.REACT_APP_TYPE_ENTITY_NAME,
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
            doc: {
              refferalcode: "",
              refferaltype: reftype?.reftype,
              queueid: reftype?.workflow?._id,
              specialityid: specialityArray ?? [],
              diagnosis: DaignosisArray ?? [],
              templates: templateArray ?? [],
              servicerequested: ServiceArray ?? [],
              payer:reftype?.payer?._id
            },
          },
        ];
        let payload = [
          {
            db_name: process.env.REACT_APP_DB,
            entity: process.env.REACT_APP_TYPE_ENTITY_NAME,
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
            filter: {
              _id: editData?._id ? `${editData?._id}` : null,
            },
            doc: save2,
          },
        ];
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_UPSERT_DOC,
          headers: {
            "Content-Type": "application/json",
          },
          data: editData?._id ? payload : data,
        };
        axios
          .request(config)
          .then((response) => {
            if (response?.data?.Code === 201) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: editData?._id
                  ? "Referral Type Updated Successfully"
                  : "Referral Type Saved Successfully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              closeForm();
            } else if (response?.data?.Code === 1111) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg:
                  "Referral Type Already Exists" ||
                  response?.data?.validation_error,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (valid.error) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
      getReferralType();
    } catch (error) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const handleDelete = (e, data, index) => {
    let updatedReftype = JSON.parse(JSON.stringify(reftype));
    let list = JSON.parse(JSON.stringify(reftype?.template));
    if (list.length === 1) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "At least one record must remain in the template.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else {
      list.splice(index, 1);
      updatedReftype.template = list;
      setReftype(updatedReftype);
      setRefEditData(updatedReftype);
    }
  };
  
  const save = async (data) => {
    try {
      if (data) {
        let seq = Number(refTemplates?.sequence);
        if (seq >= 1 && seq <= 20) {
          if (!String(refTemplates?.index)) {
            if (
              reftype?.template?.some(
                (item) =>
                  item?.componentname?._id === refTemplates?.componentname?._id ||
                  item?.sequence === refTemplates?.sequence
              )
            ) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Referral Template Already Exist",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            } else {
              reftype?.template.push(refTemplates);
              setReftype({ ...reftype, template: reftype?.template });
              setRefEditData({...refEditData, template: reftype?.template});
            }
          } else {
            if( reftype?.template?.filter(
              (item, i) =>
               ( item?.componentname?._id === refTemplates?.componentname?._id ||
                item?.sequence == refTemplates?.sequence) && i !== refTemplates?.index
            ).length > 0){
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Referral Template Already Exist",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            } else {
              let list = JSON.parse(JSON.stringify(reftype?.template));
              let tempObj = {
                componentname: refTemplates?.componentname,
                sequence: refTemplates?.sequence,
              };
              list[refTemplates?.index] = tempObj;
              const updatedReftype = {
                ...reftype,
                template: list,
              };
              setReftype(updatedReftype);
              setRefEditData(updatedReftype)
            }
            }
          // reftype?.template.push(data);
          // setReftype({ ...reftype, template: reftype?.template });
          // console.log(data, reftype?.template,'list')
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Template Sequence Should be between 1 to 20",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        }
      }
      setState({
        open: false,
        saveloading: false,
        edit_data: false,
        editIndex: null,
      });
    } catch (error) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    handleDrawerClose();
  };
  const DiagnosisSearch = (val) => {
    Diagnosis(val)
  }
  const handleChangeTemplates = (type, val, value) => {
   if(type === "componentname"){
        setRefTemplates({
          ...refTemplates, componentname: value
        })
   }else if (type === "sequence"){
        setRefTemplates({
          ...refTemplates, sequence: value
        })
   }
  }
  const handleEditTemplate = (e,data,index) =>{
    {
      setRefTemplates({
        ...refTemplates,
        componentname: data?.componentname,
        sequence: data?.sequence,
        index:index
      })
    }
    setState({
      ...state,
      editIndex: index,
      edit_data: true,
      open: true,
    });
  }
  useEffect(() => {
    getOptions();
    WorkflowOption();
    payerOption();
  }, []);
  useEffect(() => {
    if (editData) {
      const refedit = constructRefTypeEditDataPayload(editData);
      setReftype(refedit);
      setRefEditData(refedit);
    }
  }, []);
  const { labelStyle, borderStyle, errorBorderStyle } = styles;
  const { parent_id, closeForm, editData, isView, getReferralType } = props;
 
  return (
    <React.Fragment>
      <CommonTabList
        parent_id={"timetableform"}
        backbtn
        backFun={closeForm}
        title={
          isView
            ? "View Referral Type"
            : editData
            ? "Edit Referral Type"
            : "Add Referral Type"
        }
        list={[]}
      />
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root"
        style={{ margin: "20px" }}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
              <Div id={`${parent_id}-parent-sub-div`}>
                <H6 id={`${parent_id}-title-h6`} className="pi-title">
                  {"REFERRAL TYPES"}
                </H6>
              </Div>
              <Row>
                <Col
                  md={2}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <TextInput
                    value={reftype?.refcode}
                    onChange={(e) => {
                      if (/^[0-9a-zA-Z-]*$/.test(e.target.value)) {
                        handleFormState("refcode", e.target.value);
                      }
                    }}
                    label="Referral Code"
                    labelStyle={labelStyle}
                    placeholder=""
                    disabled
                    style={error?.refcode ? errorBorderStyle : borderStyle}
                  />
                </Col>
                <Col
                  md={2}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <TextInput
                    value={reftype?.reftype}
                    onChange={(e) => {
                      if (/^[0-9a-zA-Z\s@\/]*$/.test(e.target.value)) {
                        handleFormState("reftype", e.target.value);
                      }
                    }}
                    label="Referral Type"
                    isRequired={true}
                    placeholder="Enter Type"
                    labelStyle={labelStyle}
                    disabled={editData || isView ? true : false}
                    style={error?.reftype ? errorBorderStyle : borderStyle}
                  />
                </Col>
                <Col
                  md={2}
                  lg={4}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Work Flow<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <SelectBox
                    id={`${parent_id}-Workflow-selectBox`}
                    list={workflow ?? []}
                    placeholder={"select..."}
                    disableClearable
                    error={error?.workflow}
                    value={reftype?.workflow}
                    disabled={isView ? true : false}
                    onchange={(e, value) => handleFormState("workflow", value)}
                  />
                </Col>
              </Row>
              <Row>
                 <Col
                  md={2}
                  lg={10}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Service Requested<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Autocomplete
                    id={`${parent_id}-Workflow-selectBox`}
                    style={{ marginBottom: 30 }}
                    multiple
                    fullWidth
                    size="small"
                    disabled={isView ? true : false}
                    getOptionLabel={(option) => option.label}
                    value={reftype?.service ?? []}
                    options={extractIdAndValue("SERVICE", options) ?? []}
                    onChange={(e, value) => handleFormState("service", value)}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Workflow-selectBox`}
                        style={{ height: "20px"}}
                        {...params}
                        label={false}
                        variant="outlined"
                        placeholder="Select"
                        size="small"
                        error={error?.service}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  md={2}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Speciality
                  </Typography>
                  <Autocomplete
                    id={`${parent_id}-Workflow-selectBox`}
                    style={{ marginBottom: 30 }}
                    multiple
                    fullWidth
                    size="small"
                    disabled={isView ? true : false}
                    getOptionLabel={(option) => option.label}
                    value={reftype?.spec ?? []}
                    options={extractIdAndValue("SPECIALTY", options) ?? []}
                    onChange={(e, value) => handleFormState("spec", value)}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Workflow-selectBox`}
                        style={{ height: "20px" }}
                        {...params}
                        label={false}
                        variant="outlined"
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Col>
                <Col
                  md={2}
                  lg={4}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Diagnosis
                  </Typography>
                  <Autocomplete
                    id={`${parent_id}-Workflow-selectBox`}
                    style={{ marginBottom: 30 }}
                    multiple
                    fullWidth
                    disabled={isView ? true : false}
                    size="small"
                    getOptionLabel={(option) => option.label}
                    value={reftype?.dignosis}
                    options={Daignosis ?? []}
                    onInputChange={(e) => {
                      DiagnosisSearch(e.target.value);
                    }}
                    onChange={(e, value) => handleFormState("dignosis", value)}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Workflow-selectBox`}
                        style={{ height: "20px" }}
                        {...params}
                        label={false}
                        variant="outlined"
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Col>
                <Col
                  md={2}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                   Payer
                  </Typography>
                  <Autocomplete
                    id={`${parent_id}-Workflow-selectBox`}
                    style={{ marginBottom: 30 }}
                    fullWidth
                    disabled={isView ? true : false}
                    size="small"
                    getOptionLabel={(option) => option.label}
                    value={reftype?.payer}
                    options={payer ?? []}
                    onChange={(e, value) => handleFormState("payer", value)}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Workflow-selectBox`}
                        style={{ height: "20px" }}
                        {...params}
                        label={false}
                        variant="outlined"
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Col>
              </Row>
            </Paper>
          </Grid>
          <Grid item>
            <React.Fragment>
              <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                <Typography
                  id={`${parent_id}_AGE_CATEGORY_typography`}
                  className={classes.title}
                  variant="h6"
                >
                  REFERRAL TEMPLATES
                </Typography>
                <Button
                  id={`${parent_id}_Add_New_button`}
                  className={classes.addBtn}
                  onClick={() => {
                    setRefTemplates({
                      componentname: {},
                      sequence: "",
                      index: "",
                    });
                    setState({ ...state, open: true });
                  }}
                  disabled={isView}
                  color="primary"
                >
                  + Add New
                </Button>
                <Grid
                  id={`${parent_id}_table_grid`}
                  container
                  className={classes.form}
                >
                  <CommonTable
                    parent_id={"category_details"}
                    handleEdit={(e, data, index) =>
                      handleEditTemplate(e, data, index)
                    }
                    handleDelete={handleDelete}
                    rightAlign={["Action"]}
                    Header={["Component", "Sequence", "Action"]}
                    dataList={reftype?.template ?? []}
                    tableData={[
                      {
                        type: ["TEXT"],
                        name: "componentname",
                        optionLabel: "label",
                      },
                      { type: ["TEXT"], name: "sequence" },
                      { type: ["EDIT", "DELETE"], name: "", align: "right" },
                    ]}
                  />
                  <GenerateForm
                    parent_id={"category_details"}
                    nocancel
                    loading={state.saveloading}
                    open={state.open}
                    save={(data) => {
                      save(data);
                    }}
                    // let index = String(refTemplates?.index)
                    // if(refTemplates?.componentname?._id && index){
                    //   let editval = reftype?.template?.[refTemplates?.index]
                    //   const isChanged =editval?.componentname._id !== refTemplates?.componentname._id || editval?.sequence !== refTemplates?.sequence;
                    //     if (!isChanged) {
                    //      save(data)
                    //       return;
                    //     }if (reftype?.template?.some((item) => item?.componentname?._id === refTemplates?.componentname?._id || item?.sequence === refTemplates?.sequence))
                    //    {
                    //     alert?.setSnack({
                    //       open: true,
                    //       severity: AlertProps.severity.error,
                    //       msg: "Referral Template Already Exist",
                    //       vertical: AlertProps.vertical.top,
                    //       horizontal: AlertProps.horizontal.center,
                    //       tone: true,
                    //     });
                    //     return;
                    //   } else {
                    //     save(data);
                    //   }
                    // } else {
                    //   save(data)
                    // }

                    handlecancel={handleDrawerClose}
                    onchangefun={(type, val, value) => {
                      handleChangeTemplates(type, val, value);
                    }}
                    header={
                      state.editIndex !== null
                        ? "Edit Referral Template"
                        : "Add Referral Template"
                    }
                    json={[
                      {
                        componet_type: "select",
                        required: true,
                        label: "Component",
                        state_name: "componentname",
                        options:
                          extractIdAndValue("TEMPCOMPONENT", options) ?? [],
                        disabled: isView ? true : false,
                        value: refTemplates?.componentname ?? "",
                      },
                      {
                        componet_type: "text",
                        label: "Sequence",
                        state_name: "sequence",
                        type: "number",
                        required: true,
                        disabled: isView ? true : false,
                        value: refTemplates?.sequence ?? "",
                      },
                    ]}
                  />
                </Grid>
              </Paper>
            </React.Fragment>
          </Grid>
          {isView ? null : (
            <Grid
              id={"agerange-form-rangedetails-grid"}
              style={{ padding: "20px" }}
            >
              <Button
                id={"agerange_form_rangedetails_Save_button"}
                onClick={onSubmit}
                style={{ float: "right" }}
                variant="contained"
                color="primary"
              >
                {editData ? "Update" : "Save"}
              </Button>
              <Button
                id={"agerange_form_rangedetails_Cancel_button"}
                onClick={closeForm}
                style={{ float: "right", marginRight: "20px" }}
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Div>
    </React.Fragment>
  );
};

export default RefTypeForm;
