import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Popover,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from "@material-ui/core/styles";
import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { SelectBox } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
}));
function SpecialAppoitmentInstructions(props) {
  const history = useHistory();
  const { finalValue, setFinalValue, TableReadData } = props;
  const classes = useStyles();
  const [description, setDescription] = useState({
    appcode: "",
    appinstructions: "",
    appinstructioncriteria: "",
  });
  const [valueshow, setvalueshow] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [specialAppointmentdata, setspecialAppointmentdata] = useState({
    appcode: "",
    appinstructions: "",
    appinstructioncriteria: "",
    // GeneralAppointmentInst: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [Alertstate, setAlertState] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setspecialAppointmentdata({
      appcode: "",
      appinstructioncriteria: "",
      appinstructions: "",
    });
  };
  const {
    state = {},
    handlechange,
    InstructionCodeAuto,
    deleteSpecial,
    specialSave,
    criteriaAuto,
  } = props;
  const handleClose = () => {
    setAnchorEl(null);
    setspecialAppointmentdata({
      appcode: "",
      appinstructioncriteria: "",
      appinstructions: "",
    });
  };
  const handleChangelist = async (key, value, e) => {
    if (key === "appcode") {
      setspecialAppointmentdata((prevState) => ({
        ...prevState,
        appcode: value,
        appinstructions: value?.Value || "",
      }));
    } else {
      setspecialAppointmentdata((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleEditvalue = (v, i) => {
    setCurrentEditIndex(i);
    setspecialAppointmentdata({
      appcode: v?.appcode,
      appinstructioncriteria: v?.appinstructioncriteria,
      appinstructions: v?.appinstructions,
    });
  };

  const deleteSpecialvalue = (index) => {
    setFinalValue((prevFinalValue) => {
      const deletespecialResourceDefinition = [
        ...prevFinalValue.specialapptinstructions,
      ];
      deletespecialResourceDefinition.splice(index, 1);
      return {
        ...prevFinalValue,
        specialapptinstructions: deletespecialResourceDefinition,
      };
    });

    setvalueshow((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };
  const DataSave = () => {
    if (currentEditIndex !== null) {
      setFinalValue((prevFinalValue) => {
        const updatedSpecialAppointment = [
          ...prevFinalValue.specialapptinstructions,
        ];
        updatedSpecialAppointment[currentEditIndex] = specialAppointmentdata;
        return {
          ...prevFinalValue,
          specialapptinstructions: updatedSpecialAppointment,
        };
      });

      const updatedValues = [...valueshow];
      updatedValues[currentEditIndex] = specialAppointmentdata;
      setvalueshow(updatedValues);
      setspecialAppointmentdata({
        appcode: "",
        appinstructioncriteria: "",
        appinstructions: "",
      });

      setCurrentEditIndex(null);
    } else {
      if (
        specialAppointmentdata?.appcode?.label ||
        specialAppointmentdata?.appinstructioncriteria?.label ||
        specialAppointmentdata?.appinstructions
      ) {
        const updatedValues = [...valueshow, specialAppointmentdata];
        setvalueshow(updatedValues);
        setFinalValue((prevFinalValue) => {
          return {
            ...prevFinalValue,
            specialapptinstructions: [
              ...prevFinalValue.specialapptinstructions,
              specialAppointmentdata,
            ],
          };
        });
      }
      setspecialAppointmentdata({
        appcode: "",
        appinstructioncriteria: "",
        appinstructions: "",
      });
    }
  };
  useEffect(() => {
    const specialresourceData = TableReadData[0];
    if (history?.location?.state?.IsEdit && specialresourceData) {
      let specialarry = [];
      specialresourceData?.specialapptinstructions?.map((val) => {
        let mappedData = {
          appcode: {
            _id: val?.appcode?._id,
            label: val?.appcode?.display,
            Value: val.appcode?.display,
          },
          appinstructioncriteria: {
            _id: val?.appinstructioncriteria?._id || "",
            label: val?.appinstructioncriteria?.display,
            Value: val?.appinstructioncriteria?.display,
          },
          appinstructions:val?.appinstructions,
        };
        specialarry.push(mappedData)
      });
      setvalueshow(specialarry)
    }
  }, [TableReadData]);
  return (
    <div>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                SPECIAL APPOINTMENT INSTRUCTIONS
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  //   className={classes.newRes}
                  // color="primary"
                  onClick={handleClick}
                  style={{
                    marginTop: "-6px",
                    color: "#0071F2",
                    fontFamily: "poppinsemibold",
                  }}
                  button
                  disableTouchRipple
                  id={"resource_definition_card_Id"}
                >
                  + Add New
                </Button>
                <div style={{ marginBottom: "8px" }}>
                  {/* <img
                    src={expandarrow}
                    alt="icon"
                    // style={{
                    //   transform: show ? "rotate(180deg)" : "rotate(359deg)",
                    // }}
                  /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {/* {props?.data?.map((index) => {
            return ( */}
          {valueshow?.map((v, i) => {
            return (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 34,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {i + 1}
                      {/* {index + 1} */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginLeft: "4%",
                        display: "flex",
                        padding: "20px",
                      }}
                    >
                      <div>Special Instructions</div>
                      {/* <div>
                        {v.code ? v.code.label : v.code ? v.code : " "}
                        {""}
                        <div className={classes.dot} />
                        {v.code ? v.code.title : v.code ? v.code : " "}
                        {""}
                        <div className={classes.dot} />
                        {v.criteria
                          ? v.criteria.label
                          : v.criteria
                          ? v.criteria[0]
                          : " "}
                      </div> */}
                      <Grid style={{ marginLeft: "10px" }}>
                        {v?.appcode?.label}
                      </Grid>
                      <Grid style={{ marginLeft: "10px" }}>
                        {v?.appinstructioncriteria?.label}
                      </Grid>
                      <Grid style={{ marginLeft: "10px" }}>
                        {v?.appinstructions}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {/* <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        onClick={() => props.editFun("generalInstruction", i)}
                        style={{ color: "#0071F2", fontSize: 18 }}
                      />
                    </IconButton> */}

                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        onClick={(e) => {
                          handleClick(e);
                          handleEditvalue(v, i);
                        }}
                        style={{ color: "#0071F2", fontSize: 18 }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                      onClick={() => deleteSpecialvalue(i)}
                    >
                      <DeleteIcon style={{ color: "#FF4D4A", fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}

          {/* );
          })} */}
        </Card>
      </Card>

      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginLeft: "-120px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "700px", height: "280px" }}>
          <Grid container direction="row" lg={12} md={12} xl={12} xs={12}>
            <Grid item lg={11} md={11} xl={11}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: 14,
                  marginBottom: 8,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                Add Special Appointment Instructions
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <CloseIcon
                style={{
                  marginTop: "-18px",
                  cursor: "pointer",
                  marginLeft: "36px",
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            xl={12}
            xs={12}
            direction={"row"}
            spacing={2}
          >
            <Grid item lg={4} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 10,
                  marginTop: 0,
                }}
              >
                Instruction Code
                {/* <span style={{ color: "red" }}>*</span> */}
              </Typography>
              <SelectBox
                parent_id={"specialAppointmentInstructions-select"}
                list={InstructionCodeAuto ?? []}
                // list={[]}
                value={specialAppointmentdata?.appcode ?? ""}
                onchange={(e, v) => {
                  handleChangelist("appcode", v);
                }}

              />
            </Grid>
            <Grid item lg={8} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 10,
                  marginTop: 0,
                }}
              >
                Criteria
              </Typography>
              <SelectBox
                parent_id={"SpecialAppointmentCriteria-select"}
                list={criteriaAuto ?? []}
                // list={[]}
                value={specialAppointmentdata?.appinstructioncriteria ?? []}
                onchange={(e, v) => {
                  handleChangelist("appinstructioncriteria", v);
                }}

              />
            </Grid>
            <Grid item lg={12} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: -4,
                }}
              >
                Description
              </Typography>

              <textarea
                style={{
                  width: "660px",
                  height: "120px",
                  fontSize: 16,
                  resize: "none",
                  padding: 14,
                }}
                value={specialAppointmentdata?.appinstructions ?? ""}

              ></textarea>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            onClick={handleClose}
            button
            disableTouchRipple
            style={{ marginRight: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            //color="primary"
            button
            disableTouchRipple
            style={{
              marginRight: "18px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
            onClick={() => {
              DataSave();
              handleClose();
            }}
          >
            {currentEditIndex !== null ? "Update" : "Save"}
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default SpecialAppoitmentInstructions;
