import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Switch,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";

import CustomInputDatePicker from "../../datePicker/inputDatePicker";
import { SelectBox } from "../..";
import { TextFieldWithSelect } from "../../../textFieldWithSelect";
import MultipleSelectChip from "../../../multiSelect";
import TreeViewStatus from "../../../availabilitymanagement/holidayTreeTable/TreeTable/TreeViewStatus";
import { DateTimePicker } from "../../smartForm/component/datePicker";
// import ToggleButtonsMultiple from "./toggleComponent";
import { TableSwitch } from "../component/switch";
import { SearchWithFilter } from "../../../searchFilter";
import ResourceDefinitionCard from "../../../availabilitymanagement/createAppointmentTypes/resourceDefinition";

export const generateComponentVersion2 = ({
  data,
  handleChange = null,
  state,
  classes,
  parent_id,
  handleshow = null,
  yearSelect,
  setYearSelect,
  isAdd,
  ...props
}) => {
  const dropDownList = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  /**
   *
   * @param {*} data
   */
  const onSelectValue = (data) => {
    // setValues(data);
  };
  /**
   *
   * @param {*} e
   * @param {*} value
   */
  const handleDelete = (e, value) => {
    e.preventDefault();
    // setValues(values.filter((name) => name !== value));
  };
  switch (data.componet_type) {
    case "text":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            id={`${parent_id}_${
              data.state_name ? data.state_name : ""
            }__text_field`}
            value={state?.states?.[data?.state_name] ?? ""}
            onChange={(e) =>
              handleChange(
                data.state_name,
                e.target.value,
                data.isUpperCase,
                data?.textLength,
                data?.pattern
              )
            }
            variant="outlined"
            fullWidth
            required={data?.required}
            type={data?.type ?? "text"}
            size="small"
            name="route"
            disabled={data?.disabled}
            multiline={data?.multiline ?? false} //props for text area
            maxRows={data?.maxRows} //props for text area - maximum height
            minRows={data?.minRows} //props for text area - minimum height
            inputProps={{
              style: {
                fontFamily: "poppin",
                fontSize: "15px",
                backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
              },
              maxlength: data?.maxlength,
            }}
            InputLabelProps={{
              style: { fontFamily: "poppin", fontSize: "15px" },
            }}
            error={state?.error?.[data?.state_name] ?? false}
          />
        </Grid>
      );
    case "typography":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography>{data.value}</Typography>
        </Grid>
      );
    case "checkbox":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <FormControlLabel
            control={
              <Checkbox
                id={`${parent_id}_${
                  data.state_name ? data.state_name : ""
                }__checkbox`}
                checked={state?.states?.[data?.state_name] ?? ""}
                onChange={(e) =>
                  handleChange(data.state_name, e.target.checked)
                }
                name="checkedA"
              />
            }
            label={data.label}
          />
        </Grid>
      );
    case "select":
      let option = data?.options ?? [];
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <SelectBox
            parent_id={`${parent_id}_${
              data.state_name ? data.state_name : ""
            }__selectbox`}
            requireStart={data.required}
            top_title={data.label}
            list={Array.isArray(option) ? option : []}
            placeholder={data?.placeholder ?? "Search here..."}
            value={state?.states?.[data?.state_name] ?? null}
            onchange={(e, value) => handleChange(data.state_name, value)}
            error={state?.error?.[data?.state_name] ?? false}
            multi={data.multi ?? false}
            disabled={data.disabled}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <CustomInputDatePicker
            disabled={data?.disabled}
            placeholderText="dd / mm / yyyy"
            // minDate={
            //   state?.states?.[data?.minDateState]
            //     ? new Date(state?.states?.[data?.minDateState])
            //     : data?.minDateState
            //     ? data?.minDateState
            //     : new Date()
            // }
            // maxDate={new Date(2100, 0, 1)}
            // selectedDate={
            //   state?.states?.[data?.state_name]
            //     ? new Date(state?.states?.[data?.state_name])
            //     : null
            // }
            handleChange={(value) => handleChange(data.state_name, value)}
            inputField={
              <TextField
                id={`${parent_id}_${
                  data.state_name ? data.state_name : ""
                }__datepicker`}
                //  label = {data.label}
                fullWidth
                variant="outlined"
                required={data?.required}
                disabled={data?.disabled}
                value={
                  state?.states?.[data?.state_name]
                    ? moment(
                        new Date(state?.states?.[data?.state_name])
                      ).format("DD-MM-YYYY")
                    : moment(new Date()).format("DD-MM-YYYY")
                }
                inputProps={{
                  style: {
                    padding: "10.5px 14px",
                    fontFamily: "poppin",
                    fontSize: "15px",
                    backgroundColor: data?.disabled ? "#c8d1dc1f" : "#fff",
                  },
                }}
              />
            }
          />
        </Grid>
      );
    case "textField_select":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <TextFieldWithSelect
            id={`${parent_id}_${
              data.state_name ? data.state_name : ""
            }__text_field_with_select`}
            options={data?.options}
            label={data.label}
            value={state?.states?.[data?.state_name] ?? {}}
            onChange={(value) => handleChange(data.state_name, value)}
            required={data?.required}
            error={state?.error?.[data?.state_name] ?? false}
          />
        </Grid>
      );
    case "textCustom":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            id={`${parent_id}_${
              data.state_name ? data.state_name : ""
            }__text_field`}
            value={
              typeof state?.states?.[data?.state_name] === "object"
                ? state?.states?.[data?.state_name].label
                : state?.states?.[data?.state_name] ?? ""
            }
            onChange={(e) => {
              let value = e.target.value;
              if (value) {
                value = Number(value);
                if (value > data?.maxNum) {
                  value = data?.maxNum;
                }
                value += "";
              }
              handleChange(
                data.state_name,
                value,
                data.isUpperCase,
                data?.textLength
              );
            }}
            variant="outlined"
            fullWidth
            required={data?.required}
            disabled={data?.disabled}
            type={data?.type ?? "text"}
            size="small"
            name="route"
            multiline={data?.multiline ?? false} //props for text area
            maxRows={data?.maxRows} //props for text area - maximum height
            minRows={data?.minRows} //props for text area - minimum height
            InputProps={{
              style: {
                fontFamily: "poppin",
                fontSize: "12px",
                backgroundColor: data?.disabled ? "red" : "#fff",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    style={{
                      paddingRight: "10px",
                      fontFamily: "poppin",
                      fontSize: "12px",
                    }}
                  >
                    {data?.maxNum ?? ""}
                  </Typography>
                  <Typography
                    style={{
                      color: "#B6B6B6",
                      fontFamily: "poppin",
                      fontSize: "12px",
                    }}
                  >
                    {data?.uomValue ?? ""}
                  </Typography>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontFamily: "poppin", fontSize: "12px" },
            }}
            error={state?.error?.[data?.state_name] ?? false}
            helperText={"* Entered Value Should Be Between 0 -" + data?.maxNum}
          />
        </Grid>
      );
    case "multi_select":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <MultipleSelectChip
            dropDownList={dropDownList}
            onSelectValue={(data) => onSelectValue(data)}
            selectValue={""}
            onChipClose={(e, value) => handleDelete(e, value)}
            requiredField
            label="entry"
          />
        </Grid>
      );
    case "TreeView":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <TreeViewStatus />
        </Grid>
      );
    case "ResorceDefinition":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <ResourceDefinitionCard />
        </Grid>
      );
    case "Switch":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TableSwitch
            id={`table-value-TableSwitch`}
            checked={state?.states?.[data?.state_name] ?? ""}
            defaultChecked={data?.defaultChecked}
            onChange={(e) => handleChange(data.state_name, e.target.checked)}
            name="checkbox"
            disabled={data?.disabled}
          />
        </Grid>
      );
    case "SearchBox":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <SearchWithFilter
            id="Holiday"
            placeholder="Search Reason & Type!"
            hideSearchBar={false}
            hideFilter={true}
            searchGrid={classes.searchGrid}
          />
        </Grid>
      );
    case "Divider":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Divider className={classes.modalHeaderDivider} />
        </Grid>
      );
    case "TextField":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography>{data.Text}</Typography>
        </Grid>
      );
    case "EmptySpace":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}></Grid>
      );
    case "entity":
      return (
        <button
          id={`${parent_id}-entity-button`}
          style={{
            border: "none",
            color: "#0071F2",
            textDecoration: "underline",
            backgroundColor: "#ffffff",
            fontSize: "12px",
            fontFamily: "poppin",
            cursor: "pointer",
            paddingRight: 10,
          }}
          onClick={() => handleshow()}
        >
          <div
            id={`${parent_id}-View-holidays-entities-div`}
            style={{ display: "flex" }}
          >
            <div
              id={`${parent_id}-View-holidays-entities-sub-div`}
              style={{ fontFamily: "poppin", fontSize: "12px" }}
            >
              {data?.label}
            </div>
          </div>
        </button>
      );
    case "yearSelector":
      return (
        <Grid style={{ padding: "10px" }}>
          <DateTimePicker
            id={`${parent_id}-Select-Year-datetimepicker`}
            placeholder="YYYY"
            views="year"
            dateFormat="yyyy"
            label={data?.label}
            value={moment().set(
              "year",
              parseInt(moment(yearSelect).format("YYYY")) || data.value
            )}
            disabled={data?.disabled}
            className={data?.className}
            onChange={(dat) => {
              handleChange(data.state_name, dat);
              setYearSelect(dat);
            }}
            required={data?.required}
          />
        </Grid>
      );
    default:
      break;
  }
};
