import React, { useState } from "react";
import backarrow from "../../../assets";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "pc_semibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
    fontFamily: "pc_semibold",
    color: "#b2b2b2",
  },
  formParent: {
    paddingRight: 10,
  },
  inputField: {
    "&..MuiOutlinedInput-root": {
      borderRadius: "7px",
      border: "1px solid #b2b2b2",
    },
  },
}));
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
  outlinedDelButton: {
    borderColor: "#FF4D4A",
    color: "#FF4D4A",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  modalHeading: {
    fontSize: 16,
    color: "#2A60BC",
    // marginBottom: 10,
  },
  outlinedButton: {
    borderColor: "#DEDEDE",
    color: "#2A60BC",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
};
const { labelStyle, borderStyle, errorBorderStyle } = styles;
function CreateAppointmentTypes(props) {
  const classes = useStyles();
  const { parent_id, setFinalValue, finalValue, error,Ediitvalue} = props;
  return (
    <div>
      <Paper className={classes.root}>
        <Typography
          id={`${parent_id}-Appointment Type-title-typography`}
          className={classes.title}
          variant="h6"
        >
          APPOINTMENT TYPE
        </Typography>
        <Grid
          container
          item
          id={`${parent_id}-Appointment Type -parent-Grid`}
          className={classes.form}
          lg={12}
          md={12}
          xs={12}
          xl={12}
          direction="row"
          justifyContent="flex-start"
        >
          <Grid
            item
            id={`${parent_id}-Code title-grid`}
            md={2}
            lg={2}
            xs={2}
            xl={2}
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}-Code -title-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Code{" "}
              <span id={`${parent_id}-Code-star-span`} style={{ color: "red" }}>
                *
              </span>
            </Typography>
            <TextField
              id={`${parent_id}-Code-textField`}
              // disabled={Boolean(editData)}
              fullWidth
              variant="outlined"
              size="small"
              // onChange={(e) => handlechange("code", e.target.value)}
              onChange={(e) =>
                setFinalValue({
                  ...finalValue,
                  code: e.target.value,
                })
              }
              value={props?.finalValue?.code}
              labelStyle={labelStyle}
              style={
                error && !props?.finalValue?.code
                  ? errorBorderStyle
                  : borderStyle
              }
              // error={props.error?.title}
            />
          </Grid>
          <Grid
            item
            id={`${parent_id} Short description-title-grid`}
            md={4}
            lg={4}
            xl={4}
            xs={4}
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}-short description-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Short Description
              <span
                id={`${parent_id}-short-description-star-span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </Typography>
            <TextField
              id={`${parent_id}-short description-textField`}
              // disabled={Boolean(editData)}
              fullWidth
              variant="outlined"
              size="small"
              // onChange={(e) =>handlechange("shortdes", e.target.value)}
              onChange={(e) =>
                setFinalValue({
                  ...finalValue,
                  shortdesc: e.target.value,
                })
              }
              value={props.finalValue.shortdesc}
              labelStyle={labelStyle}
              style={
                error && !props?.finalValue?.shortdesc
                  ? errorBorderStyle
                  : borderStyle
              }
              // value={stateData?.title} onChange={e => updateState("title", e.target.value)}
              // error={props.error?.title}
            />
          </Grid>
          <Grid
            item
            id={`${parent_id} Long description-title-grid`}
            md={6}
            lg={6}
            xl={6}
            xs={6}
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}-Long description-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Long Description
              <span
                id={`${parent_id}-Long-description-star-span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </Typography>
            <TextField
              id={`${parent_id}-long description-textField`}
              // disabled={Boolean(editData)}
              fullWidth
              variant="outlined"
              size="small"
              // onChange={(e) => handlechange("longdes", e.target.value)}
              onChange={(e) =>
                setFinalValue({
                  ...finalValue,
                  longdesc: e.target.value,
                })
              }
              value={props.finalValue.longdesc}
              labelStyle={labelStyle}
              style={
                error && !props?.finalValue?.longdesc
                  ? errorBorderStyle
                  : borderStyle
              }

              // value={stateData?.title} onChange={e => updateState("title", e.target.value)}
              // error={props.error?.title}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default CreateAppointmentTypes;
