import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../font.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AxiosCall, extractIdAndValue, valitationFunc } from "../../utils";
import { withAllContexts } from "../../HOCs";
import { Grid, IconButton } from "@material-ui/core";
import { CreateRule } from "rulebuilder-v1";
import EditIcon from "@material-ui/icons/CreateOutlined";
import Config from "../../config";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    "&. .makeStyles-formControl-100": {
      margin: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    fontFamily: "poppin",
  },
  req_red: {
    color: "red",
  },
}));

const errorlist = ["ResourceRole", "ResourceType", "Duration"];

function AppointmentPopover(props) {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [state, setState] = React.useState({
    SeqNumber: null,
    ResourceType: "",
    ResourceRole: "",
    ResourceInclusionCriteria: "",
    Duration: null,
    DurnUOM: "",
    RequiredYN: true,
    error: {},
  });

  const [Alertstate, setAlertState] = React.useState(false);
  const [resource, setresource] = React.useState();
  const [RuleModalOpen,setRuleModalOpen] = React.useState(false);
  const [saveRuleResource,setsaveRuleResource] = React.useState(false)

  React.useEffect(() => {
    if (props.props?.editData?.length > 0 && !props?.isNew) {
      setsaveRuleResource(props?.props?.editData?.[0]?.ResourceInclusionCriteria)
      setState({
        ...state,
        ResourceInclusionCriteria: props?.props?.editData?.[0]?.ResourceInclusionCriteria,
        ResourceRole: props.props?.editData[0].ResourceRole,
        ResourceType: props.props?.editData[0].ResourceType,
        Duration: props.props?.editData[0].Duration,
      });
      getResourceRole(props?.props?.editData[0].ResourceType?._id)
    }
  }, [props?.props?.editData]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState(false);
  };
  const getResourceRole = async (val) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
          resourcetype: val,
        },
        queryid: Config.getResourceRoleQueryId,
      };
      let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
      if(Resp){
        let result = []
        Resp?.map((v,i)=>{
          let obj = {
            _id: v?._id,
            label: v?.display,
            value: v?.display
          }
          result.push(obj)
        })
        setresource({Resp:result,label:val});
      }
    } catch(e) {
        console.error(e)
    }
  }
  const resoucetype = (e, val, types, limit) => {
    if (limit) {
      debugger;
      if (e.target.value?.length > limit) {
        return false;
      }
    }

    if (state.error[types]) {
      state.error[types] = false;
    }
    if (types === "ResourceType") {
      getResourceRole(val?._id)
      setState({ ...state, [types]: val });
    } else if (types === "Duration") {
      setState({ ...state, [types]: e.target.value });
    } else {
      setState({ ...state, [types]: val });
    }
  };
  const appointmentFunClick = async () => {
    let { error, errorState } = await valitationFunc(state, errorlist);
    if (!error) {
      props.props.onChangeAll("", state, "TotNoOfResourcesRequired");
      props?.setisNew(false)
      setState({
        SeqNumber: null,
        ResourceType: "",
        ResourceRole: "",
        ResourceInclusionCriteria: "",
        Duration: null,
        DurnUOM: "",
        RequiredYN: true,
        error: {},
      })
      props.Close();
    } else {
      // setAlertState(true);
      props.alert.setSnack("mandatory");
      setState({
        ...state,
        error: errorState,
      });
    }
  };
  return (
    <div className={classes.main}>
      <Snackbar
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert severity="error">Please fill all Mandatory Fields</Alert>
      </Snackbar>
      <hr />
      <div style={{ padding: 16 }}>
        <div style={{ marginBottom: 14, marginTop: 20 }}>
          <span
            style={{
              color: "#101010",
              fontSize: 16,
              fontFamily: "poppinsemibold",
            }}
          >
            ADD NEW RESOURCE
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "2%" }}>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Resource Type<span className={classes.req_red}>*</span>
            </span>
            <Autocomplete
              id="combo-box-demo"
              options={
                extractIdAndValue("RESOURCETYPE",props?.props?.options)
              }
              getOptionLabel={(option) => option?.label}
              style={{ width: 300, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={state?.error?.ResourceType}
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) =>
                resoucetype(event, newValue, "ResourceType")
              }
              value={state.ResourceType}
            />
          </div>

          <div>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Resource Role <span className={classes.req_red}>*</span>
            </span>

            <Autocomplete
              id="combo-box-demo"
              options={
                resource?.Resp ?? []
              }
              getOptionLabel={(option) => option?.label}
              style={{ width: 300, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={state?.error?.ResourceRole}
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) =>
                resoucetype(event, newValue, "ResourceRole")
              }
              value={state.ResourceRole}
            />
          </div>
          <div style={{ marginLeft: "2%" }}>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Slot Duration <span className={classes.req_red}>*</span>
            </span>
            <div>
              <TextField
                size="small"
                variant="outlined"
                onChange={(event, newValue) =>
                  resoucetype(event, newValue, "Duration", 3)
                }
                value={state.Duration}
                // type={""}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                error={state?.error?.Duration}
              />
            </div>
          </div>
        </div>
        {saveRuleResource?._id && saveRuleResource?._id !== null ? (
              <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  marginTop: 12,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {1}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%"}}>
                      <div>{"RESOURCE INCLUSION CRITERIA"}</div>
                      <div>
                        {"Rule Name : "+saveRuleResource?.ruleName} <div className={classes.dot} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        style={{ color: "#0071F2", fontSize: 18 }}
                        onClick={() => {
                          setRuleModalOpen(true)
                        }}
                      />
                    </IconButton>
                    {/* <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <DeleteIcon
                        onClick={() =>
                          props.deleteFun("TotNoOfResourcesRequired", i)
                        }
                        style={{ color: "#FF4D4A", fontSize: 18 }}
                      />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>

        ) : (
          <Card
            style={{ padding: 16, paddingTop: 0, marginTop: "1%" }}
            elevation="2"
          >
            <p style={{ fontFamily: "poppinsemibold" }}>
              RESOURCE INCLUSION CRITERIA
            </p>
            <Button variant="outlined" color="secondary" onClick={() =>setRuleModalOpen(true)}>
              + Add New Rule
            </Button>
          </Card>
        )}
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            onClick={() => {
              props.Close();
            }}
            variant="outlined"
          >
            cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={appointmentFunClick}
          >
            Save
          </Button>
        </div>
      </div>
        {RuleModalOpen && (
          <div >
          <CreateRule 
              alert={props?.props?.alert}
              savedRuleData={(data) => {
                  setsaveRuleResource(data);
                  setState({ ...state, ResourceInclusionCriteria: {_id: data?._id, ruleName:data?.ruleName} });
                  setRuleModalOpen(false)
              }}
              handleClose={()=>setRuleModalOpen(false)} 
              open={RuleModalOpen} 
              dbName={process.env.REACT_APP_DATABASE_NAME}
              factId={"factsdefinition/10026"}
              metadataId={ "88b23ef1-7199-4503-b631-2de82ace6e03"}
              metadata_dbname={"ATP_Metadata_Dev"}
              ruleId={saveRuleResource?._id}
              rulesParams={{
                  tenantId: process.env.REACT_APP_TENANTID,
                  facilityId: process.env.REACT_APP_FACILITY_ID,
              }}
          />
          </div>
        )}
    </div>
  );
}

export default withAllContexts(AppointmentPopover);
