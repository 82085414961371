/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-09
 * @modify date 2021-02-03
 * @desc Collection of constants
 */
import React from 'react';
import  routes  from '../router/routes';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewStreamIcon from '@material-ui/icons/ViewStream';


/**
 * Object with role as key and value, which is used for 
 * comparison of role in different place.
 */
export const UserRoles = {
    role: "role"
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
    "role": "Role"
};

/**
 * Object which has the different themes used in 
 * the application.
 */
export let Themes = {
    default: "default",
    dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert) 
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
  message: {
    success: "Created Successfully",
    update: "Updated Successfully",
    delete: "Deleted Successfully",
    mandatory: "Please fill all the mandatory fields",
    minMax: "Field should be larger than the other",
    processing: "Processing",
    // unique: "Please add unique code value", 
    unique: "Entered code already exists. Code value must be unique.",
    error: "Something went Wrong",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js) 
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

/**
 * Object has the key and value pair of all the keys which 
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
    authToken: "auth_token",
    version: "version"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
    update: "UPDATE",
};


export const NavBarArr = [
    {
        name: "Forms",
        link: routes.forms,
        icon: <ViewStreamIcon />
    }
]
// Get the Entity details from ATP_Metadata_Dev for Event Confiuration
export const EventEntity = "event_catalog"

//Search View Query for event_catalog Table from ATP_Metadata_Dev for Event Confiuration
export const searchCatalog = "394d8d20-7adf-477c-8391-cf0256d0ad58"

//Get API Name Details Query from ATP_Metadata_Dev for Event Confiuration
export const getApiDetail = "57fa963b-e7a1-4c08-910c-47c061bbef44"

// Get the Entity for Using API Confiuration for ATP_Metadata_Dev
export const entity = "event_api"
export const codeablemasterentity = "CodeableConceptMaster"
export const codingmaster = "CodingMaster"

//Serach View Query for event_api Table
export const searchquery ="7385569d-d24f-4117-b279-7486d6d03a62"

//Dropdown query for Operator 
export const factoperatorlist  ="444cfcf9-a3a6-4b20-8b74-e357c7bf3333"

//Dropdown queary for factype
export const facttypelist = "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e"

//query for paratypes screens 

export const readparaTypes = "b3d6adf0-a355-47ff-83c7-080842342c5b"
export const paraMetadata = "88b23ef1-7199-4503-b631-2de82ace6e03"
// export const addparaTypes =  "88b23ef1-7199-4503-b631-2de82ace6e03"




// general master
export const gettabledata ="2ed6872e-7fe5-4e75-b26f-0e1e0484657c"
export const adminsearchqueryid = "a994f4e1-5b2b-406f-958a-0198cb3e0f6c"
export const updatequeryid = "5b91d818-f18b-4071-ab72-703c51e23648"

 
