import React, { useEffect, useState } from 'react';
import 'react-summernote/dist/react-summernote.css'; // Summernote CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // Minimal Bootstrap CSS// Bootstrap dropdown JS (required by Summernote)
import ReactSummernote from 'react-summernote';
import 'bootstrap/js/dist/modal'; // Required for Summernote modals
import 'bootstrap/js/dist/dropdown'; // Required for dropdowns
import 'bootstrap/js/dist/tooltip'; // Required for tooltips
import { Button, Grid, Typography } from '@material-ui/core';
import $ from 'jquery';

window.jQuery = $;
const SummernoteWithMUI = (props) => {
  const [content, setContent] = useState('');
  useEffect(()=>{
    if(props?.initialValue){
        setContent(props?.initialValue)
    }
  },[props?.initialValue])
  const handleContentChange = (value) => {
    setContent(value);
    props?.onChange(value);
  };

  const getButtonAction = (text) => {
    ReactSummernote.insertText(text);
  };
  const handleImageUpload = (files) => {
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        ReactSummernote.insertImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
    <div>
    <ReactSummernote
        value={content}
        options={{
          height: 400,
        //   dialogsInBody: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table','style']],
            ['insert', ['link', 'picture']],
            ['view', ['codeview', 'help']],
          ],
          buttons: {
      myTable: function (context) {
        const ui = $.summernote.ui;
        const button = ui.button({
          contents: '<i class="note-icon-table"/> Custom Table',
          tooltip: 'Insert Table with Background',
          click: function () {
            const tableHTML = `
              <table style="width: 100%; border-collapse: collapse;">
                <tr>
                  <td style="background-color: #f2f2f2; border: 1px solid #ccc;"></td>
                  <td style="background-color: #f2f2f2; border: 1px solid #ccc;"></td>
                </tr>
              </table>`;
            context.invoke('editor.pasteHTML', tableHTML);
          },
        });
        return button.render();
      },
    },
        //   callbacks: {
        //     onImageUpload: handleImageUpload, // Attach the image upload handler
        //   },
        }}
        onImageUpload={handleImageUpload}
        onChange={handleContentChange}
      />
    </div>
    <div
          style={{
            // display: "flex",
            border: "2px solid #d3d3d3",
            borderTop: "0px",
            padding: "8px",
            borderRadius: "8px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            rowGap: "5px"
            // justifyContent: this?.props?.schemaVal?.length > 0 ? "" : "center"

          }}
        >
          {props?.schemaVal?.length > 0 ? (
            props?.schemaVal?.map((v, i) => (
              <Button
                key={i} // Add a unique key for each button
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#DEE5EC",
                  color: "#2A3C50",
                  fontSize: "12px",
                  border: "none",
                  marginTop: "7px",
                }}
                onClick={() => props?.name === "Body" || props?.name === "Subject" ? getButtonAction(`#${v?.value}#`) : getButtonAction(`#${v?.value}#,`)}
              >
                {v?.label}
              </Button>
            ))
          ) : (
            <Grid >
              <Typography style={{
                color: "#6F6F6F",
                fontSize: "13px",
                marginBottom: "6px",
                fontFamily: `pc_regular !important`,
                textAlign: "center"
              }}>
                No Data Found!
              </Typography>
            </Grid>
          )}

          {/* <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
                border: "none",
              }}
              onClick={() => this.getButtonAction("<Frequency>")}
            >
              Frequency
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Route>")}
            >
              Route
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Order Dose>")}
            >
              Order Dose
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Admin Qty>")}
            >
              Admin Qty
            </Button> */}
        </div>
    </>
  );
};

export default SummernoteWithMUI;
