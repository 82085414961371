import React, { useEffect } from "react";
import { useState } from "react";
import { actions } from "primary_care_admin_binder";
import { CommonTable, OverlayCompt, VerticalList } from "../../../components";
import TimeTableManagementForm from "./TimeTableManagementForm";
import { withAllContexts } from "../../../HOCs";
import { useDispatch, useSelector } from "react-redux";
import { AlertProps, AxiosCall, epochToDate, getPractjwtToken } from "../../../utils";
import ConfirmationDialog from "./TimeTableManagementForm/confirmationDialog";
import OverRideTimeTable from "./OverrideTimeTable";
import Config from "../../../config";

const list = [
  "GET_APPLICABLE_APP_TYPES",
  "GET_SCHEDULE_MODE",
  "GET_DAY_TYPE",
  "GET_CONSULTATION_TYPE",
  "GET_RESOURCE_TYPE",
  "GET_RESOURCE_ROLE",
  "READ_GROUPS",
  "GET_ENTITY_TYPE",
];

const TimeTableManagement = (props) => {
  const [state, setState] = useState({
    openForm: false,
    isOverRide: false,
    editData: null,
    perPage: 10,
    page: 0,
    search: "",
    list: [],
    overRideData: {},
  });
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    data: "",
  });
  const [options, setOptions] = React.useState();
  const [TimeTableListView, setTimeTableListView ] = React.useState();
  const [TimeTableList, setTimeTableList ] = React.useState();
  const dispatch = useDispatch();
  const tableMaster = useSelector(
    (state) => state.timetableMasterSlice.resource_time_table_read?.data
  );
  // onLoad fnctions
  useEffect(() => {
    getOrgTypeAndSpeciality();
    getTimeTableRead();
    // Promise.all(
    //   list.map(async (val) => {
    //     return await dispatch(actions[val]());
    //   })
    // );
    // dispatch(actions.TIME_TABLE_MASTER(type));
    // dispatch(actions.APPLICABLE_APP_TYPE());
    // dispatch(actions.SCHEDULE_GENERATION_MODE());
    // dispatch(
    //   actions.RESOURCE_TIME_TABLE_READ({
    //     page: state.page,
    //     perPage: state.perPage,
    //   })
    // );
  }, []);


  const getOrgTypeAndSpeciality = async () => {
    try {
        let payload = {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            filter: {
            type: [
              "SCHEDULEMODE",
              "APPTYPE",
              "ORGTYPE",
              "PRACTROLE",
              "RESOURCETYPE",
              "CONSULTTYPE",
              "DAYTYPE",
              "SCHEDULEMODE",
              "Service"
            ],
            tenantid: process.env.REACT_APP_TENANTID,
            facilityid: process.env.REACT_APP_FACILITY_ID,
            lang: "en"
            },
            queryid: process.env.REACT_APP_QUERY_GENERAL_MASTER
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
            setOptions(Resp);
        }
    } catch(e) {
        console.error(e)
    }
  }

  const getTimeTableRead = async (search,page,perPage) => {
    try {
        let payload = {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          filter: {
            searchname: search ?? "",
            page: page ?? 0,
            perPage: perPage ?? 10
          },
          queryid: Config.getTimeTableReadQueryID
        }
        let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
        if(Resp){
          let result = []
          Resp?.map((v,i)=>{
            let obj = {
              totalCount:v?.TotalCount,
              entityName:v?.entitytype?.display,
              timetable_title:v?.timetable_title,
              resourcetype:v?.resourcetype?.display,
              resourcerole:v?.resourcerole?.display,
              effectivefrom:epochToDate(v?.effectivefrom,"dd/mm/yyyy"),
              effectiveto:epochToDate(v?.effectiveto,"dd/mm/yyyy")
            }
            result.push(obj);
          })
          setTimeTableListView(result);
          setTimeTableList(Resp);
        }
    } catch(e) {
        console.error(e)
    }
  }

  // handle Confirmation modal close
  const handleModalClose = () => {
    setModalOpen({
      open: false,
      data: "",
    });
  };

  // handle delete the data
  const handleDeleteData = async (data,index) => {
    setModalOpen({ open: false, data: "" });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Deleting...",
    });
    let jwt = await getPractjwtToken();
    const headers = {
      'Content-Type': 'application/json',
      jwtToken: jwt,
    };
    let _id = TimeTableList[index]?._id;
    let payload = {
      resourceTimeTableId: _id
    }
    let deleteData = await AxiosCall(payload,Config.__deleteSlot__,headers)
    // const deleteData = await dispatch(
    //   actions.RESOURCE_TIME_TABLE_DELETE({ id: id })
    // );
    const deletePayload = {
      error: deleteData?.error,
      msg: deleteData?.message,
    };
    props.alert.setSnack({
      open: true,
      severity: deletePayload?.error
        ? AlertProps.severity.error
        : AlertProps.severity.success,
      msg: deletePayload?.msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
    getTimeTableRead();
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    // await dispatch(
    //   actions.RESOURCE_TIME_TABLE_READ({
    //     page: state.page,
    //     perPage: state.perPage,
    //   })
    // );
  };

  // Handling the pagination of the table
  const handlepageChange = async (currentPage, PerPage) => {
    setState({ ...state, page: (currentPage - 1) * PerPage, perPage: PerPage, search: "" });
    getTimeTableRead("",(currentPage - 1) * PerPage,PerPage)
    // await dispatch(
    //   actions.RESOURCE_TIME_TABLE_READ({
    //     page: (currentPage - 1) * PerPage,
    //     perPage: PerPage,
    //   })
    // );
  };

  // Handle the search
  const handleSearch = async (value = "") => {
    if (value?.length > 0) {
      setState({
        ...state,
        search: value.trim(),
      });
      getTimeTableRead(value.trim())
    } else if (!value) {
      getTimeTableRead()
    }
    // this.setState({
    //   ...state,
    //   search: "",
    // });
  };

  // handle the time table form
  const handleForm = async (type) => {
    let { openForm } = state;
    setState({
      openForm: !openForm,
      editData: null,
    });
    getTimeTableRead()
    // await dispatch(
    //   actions.RESOURCE_TIME_TABLE_READ({
    //     page: 0,
    //     perPage: 10,
    //   })
    // );
  };

  // handle the delete and open the dialog
  const handleDelete = async (e, data, index) => {
    setModalOpen({ open: true, data: data,index:index });
  };

  // handle edit
  const handleEdit = (e, data, index) => {
    let timeTableObj = TimeTableList
    timeTableObj = timeTableObj[index]
    setState({
      ...state,
      openForm: true,
      editData: timeTableObj,
    });
  };

  // handle OverRide
  const handleOverRide = async (e, data, index) => {
    setState({
      ...state,
      isOverRide: true,
      editData: null,
      overRideData: data,
    });
  };

  // handle OverideForm
  const handleOverideForm = async () => {
    setState({
      ...state,
      isOverRide: false,
      editData: null,
    });
    getTimeTableRead()
    // await dispatch(
    //   actions.RESOURCE_TIME_TABLE_READ({
    //     page: state.page,
    //     perPage: state.perPage,
    //   })
    // );
  };

  return (
    <div>
      <React.Fragment>
        <VerticalList
          parent_id={"timetable"}
          addFunc={() => handleForm(1)}
          id={"timetable"}
          placeholder={"Search"}
          // loading={this.props?.searchLoading}
          onSearchChange={handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Resource Schedule Definition",
              component: (
                <CommonTable
                  parent_id={"timetable"}
                  rightAlign={["Status","Override"]}
                  handleEdit={handleEdit}
                  handleOverRide={handleOverRide}
                  //   handleCheckBox={this.handleCheckBox}
                  handleDelete={handleDelete}
                  Header={[
                    { label: "S.No", showSort: false, showFilter: false },
                    {
                      label: "Title",
                      showSort: false,
                      showFilter: false,
                    },
                    {
                      label: "Entity Name",
                      showSort: false,
                      showFilter: false,
                    },
                    {
                      label: "Resource Type",
                      showSort: false,
                      showFilter: false,
                    },
                    {
                      label: "Resource Role",
                      showSort: false,
                      showFilter: false,
                    },
                    // {
                    //   label: "Resource Name",
                    //   showSort: false,
                    //   showFilter: false,
                    // },
                    { label: "Start Date", showSort: false, showFilter: false },
                    { label: "End Date", showSort: false, showFilter: false },
                    // {
                    //   label: "Applicable App",
                    //   showSort: false,
                    //   showFilter: false,
                    // },
                    { label: "Action", showSort: false, showFilter: false },
                    { label: "Override", showSort: false, showFilter: false },
                  ]}
                  dataList={TimeTableListView}
                  tableData={[
                    // { type: ["WITHDRAFT"], name: "draft_" },
                    { type: ["INCRIMENT"], name: "1" },
                    {
                      type: ["TEXT"],
                      name: "timetable_title",
                    },
                    {
                      type: ["TEXT"],
                      name: "entityName",
                    },
                    { type: ["TEXT"], name: "resourcetype" },
                    { type: ["TEXT"], name: "resourcerole" },
                    // { type: ["ARRAY"], name: "resource_name" },
                    { type: ["TEXT"], name: "effectivefrom" },
                    { type: ["TEXT"], name: "effectiveto" },
                    // { type: ["ARRAY"], name: "applicable_app" },
                    { type: ["EDIT", "DELETE"], align: "left" },
                    { type: ["OVERRIDE"], align: "center" },
                  ]}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={TimeTableListView?.[0]?.totalCount}
                  incrementCount={state.page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"timetable"}
          open={state?.openForm}
          children={
            <React.Fragment>
              {state?.openForm && (
                <TimeTableManagementForm
                  parent_id={"timetableform"}
                  editData={state?.editData}
                  closeForm={handleForm}
                  options={options}
                />
              )}
            </React.Fragment>
          }
        />

        <OverlayCompt
          parent_id={"timetable_over_rde"}
          open={state?.isOverRide}
          children={
            <React.Fragment>
              <OverRideTimeTable
                closeOverRide={handleOverideForm}
                overRideData={state.overRideData}
                options={options}
              />
            </React.Fragment>
          }
        />
      </React.Fragment>
      <ConfirmationDialog
        modalOpen={modalOpen}
        parent_id={"delete_time_table"}
        handleModalClose={handleModalClose}
        handleDeleteData={handleDeleteData}
      />
    </div>
  );
};

export default withAllContexts(TimeTableManagement);
