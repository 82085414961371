import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { CommonTabList } from "../../common";
import { Button, Grid, Typography, TextField } from "@material-ui/core";
import { Col, Div, H6, Paper, Row, TextInput } from "qdm-component-library";
import { AlertProps, AxiosCall, entity } from "../../../utils";
import { AlertContext } from "../../../contexts";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Ajv from "ajv";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 56,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
}));
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};

const ApiConfig = (props) => {
  const alert = useContext(AlertContext);
  let parent_id;
  const classes = useStyles({ props });

  /** Drop Down List States */
  const [options, setOptions] = useState([]);
  const [facility, setFacility] = useState([]);
  const [practType, setPractType] = useState([]);
  const [pract, setPract] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  /** Fields States*/
  const [state, setState] = React.useState({
    apiname: "",
    apidescription: "",
    apiinputschema: "",
    apiurl: "",
  });
  const [isNew, setisNew] = useState(true);
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAlertOpen, setisAlertOpen] = useState(false);

  const getUserRoles = async (id) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        queryid: process.env.REACT_APP_GET_USER_ROLES,
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        let result = [];
        if (Array.isArray(Resp)) {
          Resp?.map((v, i) => {
            let obj = {
              _id: v?._id,
              label: v?.rolename,
              value: v?.rolename,
            };
            result.push(obj);
          });
          setUserRoles(result);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const [refEditData, setApiConfigData] = useState({
    resources: [],
  });
  const handleChange = async (v, type, index) => {
    setState({
      ...state,
      [type]: v,
    });
  };
  const isDuplicate = async (
    apiname,
    apidescription,
    apiinputschema,
    apiurl,
    mode,
    _id
  ) => {
    try {
      let checkDuplicatePayload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
          apiname: apiname?.apiname,
          apidescription: apidescription?.apidescription,
          apiinputschema: apiinputschema?.apiinputschema,
          apiurl: apiurl?.apiurl,
          apiconfig_id: _id?._id,
        },
        queryid: process.env.REACT_APP_CHECK_DUPLICATE,
      };
      let Resp = await AxiosCall(
        checkDuplicatePayload,
        process.env.REACT_APP_READ_QDM_QUERY
      );
      return Resp?.[0];
    } catch (e) {
      console.error(e);
    }
  };

  const handleSave = async () => {
    try {
      if ((state?.apiname && state?.apiurl) != (null || "")) {
        if (state?.apiinputschema == "" || state?.apiinputschema) {
          try {
            const ajv = new Ajv();
            const schema =
              state?.apiinputschema == ""
                ? ""
                : JSON.parse(state?.apiinputschema);
            const draftSchema = {
              $id: "http://json-schema.org/draft-04/schema#",
              type: "object",
              properties: {
                type: { type: "string" },
                properties: { type: "object" },
                required: { type: "array" },
              },
            };
            ajv.addSchema(
              draftSchema,
              "http://json-schema.org/draft-04/schema#"
            );

            const hasSchemaStructure =
              schema?.type === "object" &&
              schema?.hasOwnProperty("properties") &&
              Object.keys(schema?.properties).every(
                (key) => typeof schema.properties[key] === "object"
              );
            const isValidSchema =
              hasSchemaStructure && ajv.validateSchema(schema);

            if (
              !isValidSchema &&
              state?.apiinputschema != "" &&
              state?.apiinputschema != "{}"
            ) {
              alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Invalid Schema",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
              return;
            }
          } catch (e) {
            console.error(
              "Error parsing schema input or validating schema:",
              e.message
            );
            alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Invalid Schema",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            return;
          }
        }

        let filter = {};
        if (props?.apiConfig?._id) {
          filter = {
            _id: props?.apiConfig?._id,
          };
        }
        let payload = [
          {
            db_name: process.env.REACT_APP_METADATA_DB_NAME,
            entity: entity,
            is_metadata: false,
            filter: filter,
            doc: {
              apiname: state.apiname,
              apidescription: state.apidescription,
              apiinputschema:
                state.apiinputschema == ""
                  ? "{}"
                  : JSON.parse(state.apiinputschema),
              apiurl: state.apiurl,
            },
          },
        ];

        let Resp = await AxiosCall(payload, process.env.REACT_APP_UPSERT_DOC);
        if (Resp.Code === 201) {
          setIsSaving(false);
          setisNew(true);
          setState({
            apiname: "",
            apidescription: "",
            apiinputschema: "",
            apiurl: "",
          });
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: props?.apiConfig?._id
              ? "Update Successfully"
              : "Saved Successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          props?.setApiConfigList({
            isSavedNew: true,
          });
          props?.setisFormOpen({
            ...props?.isFormOpen,
            isForm: false,
            isView: false,
            isEdit: false,
          });
        } else {
          setIsSaving(false);
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Failed to Save!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      } else {
        setError(true);
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const condition = (value, type) => {
    if (type === "DELETE") {
      if (value._id) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const handleDelete = (e, data, index) => {
    let list = JSON.parse(JSON.stringify(state?.userpermission));
    list.splice(index, 1);
    setState({
      ...state,
      userpermission: list,
    });
  };
  const handleListEdit = (index) => {
    let getSingleUserPermission = state?.userpermission[index];
    setisNew(true);
  };
  const handleCancel = () => {
    props?.setisFormOpen({
      ...props?.isFormOpen,
      isForm: false,
      isView: false,
      isEdit: false,
    });
    setState({
      apiname: "",
      apidescription: "",
      apiinputschema: "",
      apiurl: "",
    });
    props?.setapiConfig({
      _id: "",
      apiname: "",
      apidescription: "",
      apiinputschema: "",
      apiurl: "",
    });
  };
  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    if (props?.apiConfig?._id) {
      if (props?.apiConfig?.userpermission?.length > 0) {
        setisNew(false);
      }
      setState({
        apiname: props?.apiConfig?.apiname,
        apidescription: props?.apiConfig?.apidescription,
        apiinputschema:
          props?.apiConfig?.apiinputschema == "{}"
            ? ""
            : JSON.stringify(props?.apiConfig?.apiinputschema),
        apiurl: props?.apiConfig?.apiurl,
      });
    }
  }, [props?.apiConfig]);

  const { labelStyle, borderStyle, errorBorderStyle } = styles;
  const axios = require("axios");
  return (
    <div>
      {/** Tab Heading */}
      <Div>
        <CommonTabList
          parent_id={"admintabpanel"}
          title="API CONFIGURATION"
          list={[]}
          backbtn={true}
          backFun={() => {
            props?.setisFormOpen({
              ...props?.isFormOpen,
              isForm: false,
              isView: false,
              isEdit: false,
            });
            props?.setapiConfig({
              _id: "",
              apiname: "",
              apidescription: "",
              apiinputschema: "",
              apiurl: "",
            });
          }}
        />
      </Div>
      <Div className="pi-paper">
        <Grid container spacing={2} direction="column">
          {/** API Configuration Fields */}
          <Grid item>
            <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
              <Div id={`${parent_id}-parent-sub-div`}>
                <H6 id={`${parent_id}-title-h6`} className="pi-title">
                  {"API CONFIGURATION"}
                </H6>
              </Div>
              <Row>
                {/** API Name */}
                <Col
                  md={2}
                  lg={6}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                      flex: 1,
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    API Name<span style={{ color: "red" }}> *</span>
                  </H6>
                  <TextInput
                    onChange={(e) => {
                      handleChange(e.target.value, "apiname");
                    }}
                    labelStyle={labelStyle}
                    error={error && !state?.apiname}
                    disabled={props?.isFormOpen?.isView}
                    required={true}
                    placeholder="Enter Name"
                    value={state?.apiname}
                  />
                </Col>
                {/** API Description */}
                <Col
                  md={2}
                  lg={6}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                      flex: 1,
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    API Description
                  </H6>
                  <TextInput
                    onChange={(e) => {
                      handleChange(e.target.value, "apidescription");
                    }}
                    labelStyle={labelStyle}
                    disabled={props?.isFormOpen?.isView}
                    placeholder="Enter Description"
                    value={state?.apidescription}
                  />
                </Col>
                {/** API Schema */}
                <Col
                  md={2}
                  lg={12}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                      flex: 1,
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    API Schema
                  </H6>
                  <TextField
                    onChange={(e) => {
                      handleChange(e.target.value, "apiinputschema");
                    }}
                    labelStyle={labelStyle}
                    disabled={props?.isFormOpen?.isView}
                    placeholder="Enter Schema"
                    value={
                      state?.apiinputschema == "{}"
                        ? ""
                        : Object.keys(state.apiinputschema)?.length > 0
                        ? state?.apiinputschema
                        : ""
                    }
                    multiline
                    minRows={4}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
                {/** URL */}
                <Col
                  md={2}
                  lg={6}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                      flex: 1,
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    URL <span style={{ color: "red" }}> *</span>
                  </H6>
                  <TextInput
                    onChange={(e) => {
                      handleChange(e.target.value, "apiurl");
                    }}
                    labelStyle={labelStyle}
                    error={error && !state?.apiurl}
                    disabled={props?.isFormOpen?.isView}
                    required={true}
                    placeholder="Enter URL"
                    value={state?.apiurl}
                  />
                </Col>
              </Row>
            </Paper>
          </Grid>

          {/**Save and cancel button */}
          {!props?.isFormOpen?.isView && (
            <Grid
              id={"agerange-form-rangedetails-grid"}
              style={{ padding: "20px" }}
            >
              <Button
                id={"agerange_form_rangedetails_Save_button"}
                onClick={isSaving ? null : handleSave}
                style={{ float: "right" }}
                variant="contained"
                color="primary"
              >
                {props?.apiConfig?._id
                  ? isSaving
                    ? "Updating..."
                    : "Update"
                  : isSaving
                  ? "Saving..."
                  : "Save"}
              </Button>
              <Button
                id={"agerange_form_rangedetails_Cancel_button"}
                onClick={handleCancel}
                style={{ float: "right", marginRight: "20px" }}
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Div>
      <Dialog
        open={isAlertOpen}
        keepMounted
        aria-labelledby="alert-title"
        aria-describedby="alert-description"
      >
        <DialogTitle id="alert-title">
          <Typography style={{ display: "flex" }}>
            <ErrorOutlineOutlinedIcon
              style={{
                width: 22,
                height: 32,
                marginRight: 7,
                color: "#F58B00",
              }}
            />
            <Typography
              variant="span"
              style={{ paddingTop: 5, fontWeight: "bold" }}
            >
              {"Warning"}
            </Typography>
          </Typography>
          <CloseIcon
            onClick={() => setisAlertOpen(false)}
            style={{ float: "right", marginTop: "-26px", cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-description"
            style={{ border: `1.5px solid "#F58B00"`, borderRadius: "8px" }}
          >
            <Typography
              style={{
                background: "#FEF3E5",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                padding: "8px 11px",
                color: "#F58B00",
                fontWeight: 800,
                minWidth: 500,
              }}
            >
              Message
            </Typography>
            <Typography style={{ padding: "10px 10px 15px 10px" }}>
              {
                "The entered data with the assigned rights already exists and has been saved. Duplicate entries are not allowed."
              }
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ padding: "5px 23px 17px", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            onClick={() => setisAlertOpen(false)}
            style={{ color: "white", background: "#F58B00" }}
          >
            {"I Understand"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApiConfig;
