/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Grid } from "@material-ui/core";
// import TreeView from '@mui/lab/TreeView';
import TreeView from "@material-ui/lab/TreeView";
import { TreeItem } from "@material-ui/lab";
import mockData from "./mockData.json";
import "./tree.css";
// import CustomTypography from "../../atoms/Typography/Typography";
import Building from "../Images/buildings.svg";
import Organization from "../../../../assets/organization.svg";
// import CustomCheckbox from "../../atoms/CustomCheckbox/checkbox";
// import CustomTooltip from "../../atoms/ToolTip/Tooltip";
import TooltipContent from "../ToolTip/TooltipContent/TooltipContent";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
// import StatusComponent from "../../../common/smartForm/component/StatusComponent/statusComponent";
import CustomTooltip from "../ToolTip/Tooltip";
import { DateTimePicker } from "../../../common/smartForm/component";
import StatusComponent from "../StatusComponent/statusComponent";
import BasicPopover from "../ToolTip/Tooltip";
import { treeData } from "../../../../utils";

/**
 *
 * @returns
 */
const TableHeader = (props) => {
  const { screeen, Thdata } = props;

  return (
    <Grid
      className="statusHeader"
      container
      direction="row"
      alignItems="center"
      // width={100}
    >
      {Thdata?.map((item) => {
        return (
          <Grid item xs={item.Size}>
            <Typography variant="caption" className="treeTableHead">
              {item.Title}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
/**
 *
 * @param props
 * @returns
 */
const TableRowItem = (props) => {
  const { data, parent_id, steperSave, setValues, values,setData,indexdata,gridConfig} = props;
  const [date, setDate] = React.useState("");
  /**
   *
   * @param {string} value -- entry key names
   * @returns {string} CLASSNAMES
   */
  const getRespectiveDiv = (value) => {
    switch (value) {
      case "entity":
        return "entityDiv";
      case "effectiveFrom":
        return "effectiveFromDiv";
      case "effectiveTo":
        return "effectiveToDiv";
      case "reason":
        return "reasonDiv";
      case "status":
        return "statusDiv";
      case "children":
        return "childrenDiv";
      default:
        return null;
    }
  };

  return (
    <>
      {Object.entries(data).map(([k, v], index) => {
        return (
          <Grid width="348px" overFlow="auto">
            {k !== "id" &&
              k !== "status" &&
              k !== "reason" &&
              k !== "_key" &&
              k !== "key" &&
              k !== "OrgType" && k !=="holidayDtlsId" &&(
                <div className={getRespectiveDiv(k)}>
                  {k !== "id" &&
                  k !== "reason" &&
                  k !== "status" &&
                  k !== "OrgType" &&
                  k !== "key" &&
                  Array.isArray(v) ? null : k === "effectiveFrom" ||
                    k === "effectiveTo" ? (
                    steperSave === false ? (
                      <DateTimePicker
                        value={date}
                        onChange={(e) => setDate(e)}
                      />
                    ) : (
                      v
                    )
                  ) : (
                    v
                  )}
                </div>
              )}
            {/* <div style={{ width: "100px" }}></div> */}
            {k === "status" && parent_id !== "appointmenttype" && (
              <div
                className={
                  data.OrgType === "Organization" || data.OrgType === "Facility"
                    ? "orgToolDiv"
                    : "toolDiv"
                }
              >
                {/* <StatusComponent
                  enable={v.status ? "Active" : "InActive"}
                  // overAllStatus={overAllStatus}
                  // cancel={cancel}
                /> */}
                <BasicPopover
                  data={data}
                  setValues={setValues}
                  values={values}
                  enable={data.status._id  === "CodingMaster/11302" ? true : false}
                  setData={setData}
                  index={indexdata}
                  gridConfig={gridConfig}
                />
              </div>
            )}
          </Grid>
        );
      })}
    </>
  );
};

/**
 *
 * @param {*} data
 * @param {*} clicked
 * @returns
 */
const getRenderTreeItem1 = (
  data,
  clicked,
  setValues,
  values,
  parent_id,
  steperSave
) => {
  return (
    <TreeItem
      sx={{ maxWidth: "100%", paddingLeft: "30px" }}
      key={data.id}
      nodeId={data.id}
      className={data.OrgType === "Facility" && "itemFacility"}
      label={
        <>
          {data.OrgType === "Enterprise" && (
            <img src={Building} alt="enterprice" />
          )}
          {data.OrgType === "Organization" && (
            <img src={Organization} alt="Organization" />
          )}
          <span style={{ display: "flex" }}>
            {/* <Checkbox
            // checked={antoine}
            // onChange={handleChange}
            name="antoine"
            color="primary"
          /> */}

            <TableRowItem
              data={data}
              style={{ alignItems: "center" }}
              // clicked={clicked}
              setValues={setValues}
              values={values}
              parent_id={parent_id}
              steperSave={steperSave}
            />
          </span>
        </>
      }
    >
      {Array.isArray(data.children)
        ? data.children.map((node) => {
            return (
              <>
                {/* <img src={Building} alt="jj" /> */}
                <span className="tableTreeRow">{getRenderTreeItem1(node)}</span>
              </>
            );
          })
        : null}
    </TreeItem>
  );
};
/**
 * @param {*} props -all props
 * @name TreeViewTable
 * @returns {React.ReactElement} --Custom Functional component
 */
const TreeViewRow = (props) => {
  const {
    view,
    treeCheckhandle,
    gridConfig,
    setData,
    index,
    data,
    clicked,
    setValues,
    values,
    isEdit,
    refresh,
    screeen,
    parent_id,
    steperSave,
  } = props;
  let arr = [];
  let arr1 = [];
  const checkData = [];
  values?.map((item) => {
    checkData.push(item?.orgid);
  });
  let result = checkData.includes(data?.id);

  const removeTreeUncheckedValues=(val)=>{
    const indexToRemove = values.findIndex(item => item.orgid === val.id);
    values.splice(indexToRemove,1)
    setValues(values)
    return values
  };


  const FacilityupdateChecked = (checked, list) => {
    return list?.map(item => {
      if (item?.OrgType === "Facility") {
        return { ...item, 
          checked,
          status:checked ? {
            "_id":"CodingMaster/11302",
            "display":"Active"
        }:
        {
          "_id": "CodingMaster/11303", 
            "display": "Inactive" 
        }
         };
      }
    });
  };

  const updateChecked = (id, checked, list) => {
    return list.map(item => {
      if (item?.id === id) {
        const updatedChildren = item.children
          ? item?.children?.map(child => ({
              ...child,
              checked,
              status:checked ? {
                "_id":"CodingMaster/11302",
                "display":"Active"
            }:
            {
              "_id": "CodingMaster/11303", 
                "display": "Inactive" 
            },
              children:child?.children? FacilityupdateChecked(checked, child.children):[] 
            }))
          : [];
        return { ...item, checked ,children: updatedChildren,
          status: checked ? { 
            "_id": "CodingMaster/11302", 
            "display": "Active" 
        }:{
          "_id": "CodingMaster/11303", 
            "display": "Inactive" 
        }
    };
      }
      if (item?.children && item?.children?.length > 0) {
        return { ...item, children: updateChecked(id, checked, item.children)};
      }

      return item;
    });
  };


  const handleChange = (data, event,ind) => {
    setValues([])
    const checked =event.target.checked
    const updatedData = updateChecked(data.id,checked ,treeCheckhandle);
    setData(updatedData); // Set the updated state with the new checked value   
  };
  

  /**
   * @param {*} data -all data
   * @name TreeViewTable
   * @returns {React.ReactElement} --Custom Functional component
   */
  const getRenderTreeItem = (data, val, parent_id, steperSave,save,edit,index) => {
    
    return (
      <TreeItem
        sx={{ maxWidth: "100%" }}
        key={data.id}
        nodeId={data.id}
        className={data.OrgType === "Facility" && "itemFacility"}
        label={
          <>
            {data.OrgType === "Enterprise" && (
              <img src={Building} alt="enterprice" />
            )}
            {data.OrgType === "Organization" && (
              <img src={Organization} alt="Organization" />
            )}


            <span style={{ display: "flex" }}>
               <Checkbox
                checked={data.checked}
                onChange={(event) => handleChange(data, event,index)}
                name={data.entity}
                defaultChecked={result || data.check}
                color="primary"
                disabled={view ? true : false}
             />
              <TableRowItem
                data={data}
                style={{ alignItems: "center" }}
                parent_id={parent_id}
                steperSave={steperSave}
                setData={setData}
                indexdata={index}
                gridConfig={gridConfig}

              />
            </span>
          </>
          // <span style={{ display: "flex" }}>
          //   <Checkbox
          //     // checked={data.check && data.check}
          //     // checked={result}
          //     onChange={(event) => handleChange(data, event)}
          //     name={data.entity}
          //     defaultChecked={result || data.check}
          //     color="primary"
          //     disabled={data.check ? true : false}
          //   />
          //   <TableRowItem
          //     data={data}
          //     style={{ alignItems: "center" }}
          //     parent_id={parent_id}
          //     steperSave={steperSave}
          //   />
          // </span>
        }
      >
        {
        Array.isArray(data.children)
          ? data.children.map((node,i) => {
              return (
                <span className="tableTreeRow" span={{ paddingLeft: "28px" }}>
                  {/* <Checkbox
                  // checked={antoine}
                  // onChange={handleChange}
                  name="antoine"
                  color="primary" 
                /> */}
                  {getRenderTreeItem(node,i)}
                </span>
              );
            })
          : null}
      </TreeItem>
    );
  };
  return (
    <TreeView
      aria-label="customized"
      defaultExpanded={["1", "1.1", "1.2"]}
      // defaultCollapseIcon={<img src={Building} alt="img" />}
      // defaultExpandIcon={<img src={Building} alt="img" />}
      className="treeViewData"
      // defaultExpandIcon={<PlusSquare />}
      // defaultEndIcon={<CloseSquare />}
      // sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {clicked
        ? getRenderTreeItem(
            data,
            clicked,
            parent_id,
            steperSave,
            setValues,
            values,
            index
          )
        : getRenderTreeItem1(
            data,
            clicked,
            parent_id,
            steperSave,
            setValues,
            values,
            index,
          )}
    </TreeView>
  );
};

/**
 *
 * @param props
 * @returns
 */
const TableBodySection = (props) => {
  const {
    view,
    gridConfig,
    setgridConfig,
    clicked,
    setValues,
    values,
    isEdit,
    refresh,
    screeen,
    parent_id,
    steperSave,
  } = props;


  return (
    <div className="treetableBodyScroll">
      {gridConfig?.map((row,i) => (
        <TreeViewRow
          index={i}
          data={row}
          gridConfig={gridConfig}
          setData={setgridConfig}
          clicked={clicked ? clicked : ""}
          setValues={setValues}
          values={values}
          isEdit={isEdit}
          refresh={refresh}
          screeen={screeen}
          parent_id={parent_id}
          steperSave={steperSave}
          treeCheckhandle={gridConfig}
          view={view}
          // handleChange={() => alert("test")}
        />
      ))}
    </div>
  );
};

/**
 * @param {*} props -all props
 * @name TreeViewTable
 * @returns {React.ReactElement} --Custom Functional component
 */
const TreeViewTable = (props) => {
  const {
    view,
    data,
    clicked,
    treeListData,
    setTreeListData,
    setValues,
    values,
    EditMaster,
    isEdit,
    refresh,
    screeen,
    Thdata,
    parent_id,
    steperSave,
  } = props;

  /**
   *
   * @param {*} val
   */
  const callRec = (val) => {
    const finalArr = [];
    if (val && val.length > 0) {
      val.map((item, index) => {
        const hasChildArr = Array.isArray(item.children);
        hasChildArr ? item.children.map((node) => callRec(node)) : null;
      });
    }
    return finalArr;
  };




  return (
    <div>
      <TableHeader screeen={screeen} Thdata={Thdata} />
      <Divider />
      <TableBodySection
        gridConfig={treeListData}
        setgridConfig={setTreeListData}           
        clicked={clicked}
        setValues={setValues}
        values={values}
        EditMaster={EditMaster}
        isEdit={isEdit}
        refresh={refresh}
        screeen={screeen}
        parent_id={parent_id}
        steperSave={steperSave}
        view={view}
      />
    </div>
  );
};

TreeViewTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf()),
};

TreeViewTable.defaultProps = {
  data: [],
};

export default TreeViewTable;
