import React, { useContext, useState } from "react";
import { CommonTable, GenerateForm, SearchWithFilter } from "../../components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Col, Div, H6, Paper, Row, TextInput } from "qdm-component-library";
import { Grid, Typography, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  AlertProps,
  AxiosCall,
  editparaTypes,
  paraMetadata,
  readparaTypes,
} from "../../utils";
import { AlertContext } from "../../contexts";
import Config from "../../config";

const ParatypeTabelist = (props) => {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
    outlinedDelButton: {
      borderColor: "#FF4D4A",
      color: "#FF4D4A",
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace: "nowrap",
    },
    modalHeading: {
      fontSize: 16,
      color: "#2A60BC",
      // marginBottom: 10,
    },
    outlinedButton: {
      borderColor: "#DEDEDE",
      color: "#2A60BC",
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace: "nowrap",
    },
  };
  const { labelStyle, borderStyle, errorBorderStyle } = styles;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { parent_id } = props;
  const alert = useContext(AlertContext);
  const [error, setError] = React.useState(false);
  const [formopen, setFormopen] = React.useState(false);
  const [showTable, setShowTable] = React.useState([]);
  const [isEditMode, setIsEditMode] = useState();
  const [isViewMode, setIsViewMode] = useState(false);
  const [state, setState] = React.useState();
  const [openAlert, setOpenAlert] = React.useState({
    open: false,
    index: null,
  });
  const [paraTypevalue, setParaTypevalue] = React.useState({
    paraCode: "",
    paraVal: "",
    paraDef: "",
    paraobj: "",
  });

  const onSearchChange = (searchvalue) => {
    getParatypes("", searchvalue);
  };

  const handlePageChange = async (currentPage, PerPage) => {
    if (PerPage) {
      setState({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      });
      await getParatypes({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      });
    }
  };

  const handleChange = (value, key) => {
    setParaTypevalue({
      ...paraTypevalue,
      [key]: value,
    });
  };

  const handleDrawerOpen = () => {
    setFormopen(true);
    setIsEditMode("");
  };

  const closeFuntion = () => {
    setFormopen(false);
    clearFacts();
    setIsViewMode(false);
  };

  const clearFacts = () => {
    setError(false);
    setParaTypevalue({
      paraCode: "",
      paraVal: "",
      paraDef: "",
      paraobj: "",
    });
  };

  //////////////READ/////////////

  const getParatypes = async (pageDetails, searchVal = "") => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        queryid: readparaTypes,
        filter: {
          searchvalue: searchVal ?? "",
          page: pageDetails?.page ?? 0,
          perpage: pageDetails?.perPage ?? 10,
        },
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        setShowTable(Resp);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const Saveparatypes = () => {
    setError(true);
    if (!paraTypevalue.paraCode) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    if (paraTypevalue?.paraobj) {
      let stringToObj;
      try {
        stringToObj = JSON.parse(paraTypevalue?.paraobj);
        if (typeof stringToObj === "object" && !Array.isArray(stringToObj)) {
          if (Object.keys(stringToObj).length < 1) {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Object can't be empty!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            return;
          }
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please enter a valid object!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        }
      } catch (e) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter a valid object!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
    }
    if (isEditMode) {
      const axios = require("axios");
      const convertObj = JSON.parse(paraTypevalue?.paraobj);
      let convertarray = [];
      convertarray.push(convertObj);
      let data = JSON.stringify([
        {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          entity: Config.paratypesentity,
          is_metadata: true,
          metadataId: paraMetadata,
          metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
          filter: {
            _key: isEditMode._key,
          },
          doc: {
            paraCode: paraTypevalue?.paraCode || "",
            paraVal: parseInt(paraTypevalue?.paraVal || ""),
            paraDef: paraTypevalue?.paraDef || "",
            paraobj: convertarray || [],
          },
        },
      ]);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_UPSERT_DOC,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify("responsedata", response.data));
          setFormopen(false);
          clearFacts();
          getParatypes();
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Update Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError(false);
      setFormopen(false);
      const axios = require("axios");

      let paraVal = paraTypevalue?.paraVal;
      if (paraVal === "" || paraVal === undefined || paraVal === null) {
        paraVal = 0;
      } else {
        paraVal = parseInt(paraVal, 10);
        if (isNaN(paraVal)) {
          paraVal = 0;
        }
      }
      let convertarray = [];
      if (paraTypevalue?.paraobj) {
        const convertObj = JSON.parse(paraTypevalue?.paraobj);
        convertarray.push(convertObj);
      }

      let data = JSON.stringify([
        {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          entity: Config.paratypesentity,
          is_metadata: true,
          metadataId: paraMetadata,
          metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
          doc: {
            paraCode: paraTypevalue?.paraCode,
            paraVal: paraVal,
            paraDef: paraTypevalue?.paraDef,
            paraobj: convertarray && convertarray.length ? convertarray : [],
          },
        },
      ]);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_UPSERT_DOC,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setFormopen(false);
          clearFacts();
          getParatypes();
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Added Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleView = (index) => {
    const selectedRow = showTable[index];
    const objectTojson = JSON.stringify(selectedRow.paraobj?.[0]);

    setParaTypevalue({
      paraCode: selectedRow.paraCode || "",
      paraVal: selectedRow.paraVal || "",
      paraDef: selectedRow.paraDef || "",
      paraobj: objectTojson || "",
    });
    setFormopen(true);
    setIsViewMode(true);
  };

  const handleListEdit = (index) => {
    const selectedRow = showTable[index];

    setIsEditMode(selectedRow);

    const objectTojson = JSON.stringify(selectedRow.paraobj?.[0]);

    setParaTypevalue({
      paraCode: selectedRow.paraCode || "",
      paraVal: selectedRow.paraVal || "",
      paraDef: selectedRow.paraDef || "",
      paraobj: objectTojson || "",
    });

    setFormopen(true);
  };

  const handleDelete = (del) => {
    if (del) {
      const deletevalue = showTable[openAlert.index];
      const axios = require("axios");
      let data = JSON.stringify([
        {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          entity: Config.paratypesentity,
          is_metadata: true,
          metadataId: paraMetadata,
          metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
          filter: {
            _key: deletevalue?._key,
          },
          doc: {
            activestatus: false,
          },
        },
      ]);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_UPSERT_DOC,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          console.log("response", response);

          setOpenAlert({
            ...openAlert,
            open: false,
            index: null,
          });
          clearFacts();

          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Delete Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          getParatypes();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    getParatypes();
  }, []);
  return (
    <>
      <div>
        <div
          id={`${parent_id}-List-of-paratype-div`}
          style={{ display: "inline-flex" }}
        >
          <p
            id={`${parent_id}-List-of-paratype-title-p`}
            style={{
              fontFamily: "poppinsemibold",
              fontSize: "16px",
              marginBottom: "0px",
            }}
          >
            Para Types
          </p>
        </div>
        <div
          id={`${parent_id}-entity-div`}
          style={{
            marginTop: "10px",
            float: "right",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SearchWithFilter
            id="paratype"
            placeholder="Search code and Description!"
            //  loading={data?.length > 0 ? loading : false}
            onSearchChange={onSearchChange}
            hideSearchBar={false}
            hideFilter={true}
            //  onFilterClicked={onFilterClicked}
            //  isFilterApplied={props?.isFilterApplied}
          />
          <Button
            id={`${parent_id}-Add-New-button`}
            disabled={false}
            variant={"contained"}
            color={"primary"}
            onClick={handleDrawerOpen}
          >
            +Add New
          </Button>
          <Dialog
            open={formopen}
            maxWidth="lg"
            fullWidth
            // onClose={() => setAddNew(false)}
            // disableBackdropClick
          >
            <Grid item>
              <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                <Grid container justifyContent="space-between">
                  <Grid item style={{ paddingLeft: "10px" }}>
                    <Typography variant="h6">Add Para Types </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={closeFuntion}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Row>
                  {/** API Name */}
                  <Col
                    md={2}
                    lg={4}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    <H6
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Para Code<span style={{ color: "red" }}> *</span>
                    </H6>
                    <TextInput
                      onChange={(e) => {
                        handleChange(e.target.value, "paraCode");
                      }}
                      labelStyle={labelStyle}
                      style={
                        error && !paraTypevalue?.paraCode
                          ? errorBorderStyle
                          : borderStyle
                      }
                      disabled={isViewMode}
                      required={true}
                      placeholder="Enter ParaCode"
                      value={paraTypevalue?.paraCode}
                    />
                  </Col>
                  <Col
                    md={2}
                    lg={4}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    <H6
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Para Value
                    </H6>
                    <TextInput
                      onChange={(e) => {
                        handleChange(e.target.value, "paraVal");
                      }}
                      type="number"
                      labelStyle={labelStyle}
                      disabled={isViewMode}
                      placeholder="Enter ParaValue"
                      value={paraTypevalue?.paraVal}
                    />
                  </Col>

                  <Col
                    md={2}
                    lg={4}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    <H6
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Para Definition
                    </H6>
                    <TextInput
                      onChange={(e) => {
                        handleChange(e.target.value, "paraDef");
                      }}
                      labelStyle={labelStyle}
                      //   error={error && !state?.apiurl}
                      disabled={isViewMode}
                      required={true}
                      placeholder="Enter  Para Definition"
                      value={paraTypevalue?.paraDef}
                    />
                  </Col>
                  <Col
                    md={2}
                    lg={12}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    <H6
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Para Object
                    </H6>
                    <TextField
                      onChange={(e) => {
                        handleChange(e.target.value, "paraobj");
                      }}
                      labelStyle={labelStyle}
                      disabled={isViewMode}
                      placeholder="Enter Para Object"
                      //   value={
                      //     state?.apiinputschema == "{}"
                      //       ? ""
                      //       : Object.keys(state.apiinputschema)?.length > 0
                      //       ? state?.apiinputschema
                      //       : ""
                      //   }
                      value={paraTypevalue?.paraobj}
                      multiline
                      minRows={4}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button disabled={isViewMode} onClick={clearFacts}>
                      {" "}
                      Clear
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={Saveparatypes}
                      disabled={isViewMode}
                    >
                      {isEditMode ? "Update" : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Dialog>
        </div>
        <CommonTable
          style={{ marginTop: "50px" }}
          parent_id={"api_manamement"}
          rightAlign={["View", "Action"]}
          handleEdit={(e, data, index) => {
            handleListEdit(index);
          }}
          handleView={(e, data, index) => {
            handleView(index);
          }}
          handleDelete={(e, v, i) =>
            setOpenAlert({
              open: true,
              index: i,
            })
          }
          Header={[
            { label: "S.No", showSort: false, showFilter: false },
            { label: "Para Code", showSort: false, showFilter: false },
            { label: "Action", showSort: false, showFilter: false },
            { label: "View", showSort: false, showFilter: false },
          ]}
          dataList={showTable ?? []}
          tableData={[
            { type: ["INCRIMENT"], name: "1" },
            { type: ["TEXT"], name: "paraCode" },
            // { type: ["TEXT"], name: "apidescription" },
            // { type: ["TEXT"], name: "apiurl" },
            { type: ["EDIT", "DELETE"], name: "", align: "right" },
            { type: ["VIEW"], name: "", align: "right" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            handlePageChange(currentPage, PerPage)
          }
          TableCount={showTable?.[0]?.TotalCount}
          incrementCount={state?.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        {openAlert?.open && (
          <Dialog
            fullScreen={fullScreen}
            open={openAlert?.open}
            onClose={() =>
              setOpenAlert({
                ...openAlert,
                open: false,
              })
            }
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle style={styles.modalHeading} id="alert-dialog-title">
              {"Are you Sure Want to Delete ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this record? This action cannot
                be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: "15px" }}>
              <Button
                onClick={() =>
                  setOpenAlert({
                    ...openAlert,
                    open: false,
                  })
                }
                color="primary"
                style={styles.outlinedButton}
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={() => handleDelete(openAlert)}
                color="primary"
                style={styles.outlinedDelButton}
                variant="contained"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default ParatypeTabelist;
